import Select from 'react-select'
import React from 'react';

import {MultiValue, ValueContainer, Option, MenuList} from "./selectCustom";
import './selectWrapper.scss'

export const SingleSelectLargeWrapper = (props) => {
    const render = () => {
        return (
            <Select
                // styles={customStyle}
                className="react-select-container--large"
                classNamePrefix="react-select"
                components={{Option, MultiValue, ValueContainer, MenuList}}
                isMulti={false}
                value={props.selectedValue}
                name={props.name}
                id={props.id}
                isSearchable={true}
                options={props.options}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={true}
                onChange={props.onChange}
            />);
    }

    return render();
}

SingleSelectLargeWrapper.defaultProps = {
    options: [],
    name: "",
    id: "",
    onChange: () => {
    },
    selectedValue: []
}
