import Select from 'react-select'
import React from 'react';

import {MultiValue, ValueContainer, Option} from "./selectCustom";
import './selectWrapper.scss'

export const SingleSelectWrapper = (props) => {
    const render = () => {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                components={{Option, MultiValue, ValueContainer}}
                isMulti={false}
                value={props.selectedValue}
                name={props.name}
                id={props.id}
                isSearchable={false}
                options={props.options}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={props.onChange}
            />);
    }

    return render();
}

SingleSelectWrapper.defaultProps = {
    options: [],
    name: "",
    id: "",
    onChange: () => {
    },
    selectedValue: []
}
