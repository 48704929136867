import React, {Component} from "react";
import {connect} from "react-redux";
import * as Constants from "../../../utilities/constants";
import ItemStrangePrefixSelectButton from "../../atoms/buttons/itemEdit/itemStrangePrefixSelectButton"
import {breakpoints} from "../../../utilities/breakpoints";

class ItemStrangePrefixesSection extends Component {

    static defaultProps = {
        selectedAction: "",
        strangePrefixes: [],
        item: {},
        isLoading: false
    }

    render() {
        if (this.props.selectedAction !== Constants.itemEditActions.strangePrefixes)
            return null;

        const isMobile = breakpoints.isLessThan750();

        return (
            <div className={`${isMobile ? '' : 'mt-3'} animated fade-in-move-down row row-item row-section`}>
                {
                    this.props.strangePrefixes.map((strangePrefix) =>
                        <ItemStrangePrefixSelectButton
                            key={strangePrefix.id}
                            strangePrefix={strangePrefix}
                            item={this.props.item}
                            isLoading={this.props.isLoading}
                        />
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        strangePrefixes: state.general.strangePrefixes
    };
};

export default connect(mapStateToProps, null)(ItemStrangePrefixesSection);
