import React, {Component} from "react";
import ItemSelectButton from "../../atoms/buttons/itemSelectButton";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../atoms/loading-spinner/loadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";

class ItemSection extends Component {
    constructor(props) {
        super(props);

        this.itemsContainer = React.createRef();
    }

    static defaultProps = {
        items: [],
        handlePagination: () => {
        },
        hasMore: false,
        postId: null
    }

    getContainerKey = () => {
        if (this.props.items.length > 0) {
            return this.props.items[0].id;
        } else {
            return 0;
        }
    }


    isOverflowing = () => {
        if (this.itemsContainer && this.itemsContainer.current && this.props.hasMore) {
            const bounding = this.itemsContainer.current.getBoundingClientRect();
            if (bounding.bottom < (window.innerHeight || document.documentElement.clientHeight)) {
                this.props.handlePagination();
            }
        }
    }

    render() {
        if (this.props.items.length < 0)
            return null;

        setTimeout(() => this.isOverflowing(), 100)

        return (
            <>
                {
                    <div
                        className="pt-3 animated fade-in-move-down"
                        key={this.getContainerKey()}
                        ref={this.itemsContainer}
                    >
                        <InfiniteScroll
                            next={this.props.handlePagination}
                            hasMore={this.props.hasMore}
                            loader={<LoadingSpinner size={LoadingSpinnerSizes.large}/>}
                            dataLength={this.props.items.length}
                            className="row row-item"
                            scrollThreshold={0.1}
                            style={{overflow: 'hidden'}}
                        >
                            {
                                this.props.items.map((item) =>
                                    <ItemSelectButton
                                        key={item.id}
                                        item={item}
                                        postId={this.props.postId}
                                    />
                                )
                            }
                        </InfiniteScroll>
                    </div>
                }
            </>
        )
    }
}

export default ItemSection;
