import React, {Component} from "react";
import PostButton from "../../../../atoms/buttons/itemEdit/postButton";
import BottomNavButton from "../../../../atoms/buttons/itemEdit/bottomNavButton";
import ItemCounter from "../../../../atoms/itemCounter";
import * as constants from "../../../../../utilities/constants";
import BottomBar from "../../../../molecules/bottomBar/bottomBar";
import {connect} from "react-redux";
import VerticalSeparator from "../../../../atoms/verticalSeparator/verticalSeparator";
import Menu from 'react-burger-menu/lib/menus/slide'
import {breakpoints} from "../../../../../utilities/breakpoints";
import HorizontalSeparator from "../../../../atoms/horizontalSeparator/horizontalSeparator";

class CreatePostPageBottomBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false
        }
    }

    static defaultProps = {
        postItemCount: 0,
        toggleReorder: () => {
        },
        toggleLoginModal: () => {
        },
        toggleConfirmationModal: () => {
        },
        handleSavePost: () => {
        },
        isLoading: false,
        me: null,
        postId: null
    }

    renderDesktopMenu = () => {
        return (
            <>
                <ItemCounter itemCount={this.props.postItemCount}/>
                <div className="bottom-bar-options">
                    <BottomNavButton
                        linkUrl={constants.pagePaths.createBrowse}
                        currentlyToSet={constants.currently.addingAnItem}
                        isDisabled={this.props.isLoading !== 0}
                    >
                        Add Item
                    </BottomNavButton>
                    {this.props.postItemCount > 1 &&
                    <PostButton
                        onClick={this.internalHandleReorder}
                        isLoading={false}
                        isDisabled={this.props.isLoading !== 0}
                    >
                        {this.props.isReordering ? 'Stop Reordering' : 'Reorder'}
                    </PostButton>}
                    <VerticalSeparator/>

                    <PostButton
                        onClick={this.props.handleSavePost}
                        isLoading={this.props.isLoading === 4}
                        isDisabled={this.props.isLoading !== 4 && this.props.isLoading !== 0}
                    >
                        Save
                    </PostButton>
                    <PostButton
                        onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to submit this post? It will no longer be editable.', 1)}
                        isLoading={this.props.isLoading === 1}
                        isDisabled={this.props.isLoading !== 1 && this.props.isLoading !== 0}
                    >
                        Submit
                    </PostButton>
                    <VerticalSeparator/>

                    <PostButton
                        onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to create a new post? This will discard any unsaved changes.', 2)}
                        isLoading={this.props.isLoading === 2}
                        isDisabled={this.props.isLoading !== 2 && this.props.isLoading !== 0}
                    >
                        Create New Post
                    </PostButton>
                    {this.props.postId && this.props.postId > 0 ?
                        <PostButton
                            onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to delete this post? This action is irreversible.', 3)}
                            isLoading={this.props.isLoading === 3}
                            isDisabled={this.props.isLoading !== 3 && this.props.isLoading !== 0}
                        >
                            Delete
                        </PostButton> : null}
                </div>
            </>
        );
    }

    burgerMenuButtonPosition = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            right: '10px',
            bottom: '10px',
            top: 'initial'
        }
    }

    internalHandleReorder = () => {
        this.closeMenu();
        this.props.toggleReorder();
    }

    handleStateChange = (state) => {
        this.setState({isMenuOpen: state.isOpen})
    }

    closeMenu = () => {
        this.setState({isMenuOpen: false})
    }

    openMenu = () => {
        this.setState({isMenuOpen: true})
    }

    renderMobileMenu = () => {
        return (
            <>
                <Menu
                    left
                    width={'80%'}
                    disableAutoFocus
                    styles={this.burgerMenuButtonPosition}
                    isOpen={this.state.isMenuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                    Pass customBurgerIcon={false}
                >
                    <BottomNavButton
                        linkUrl={constants.pagePaths.createBrowse}
                        currentlyToSet={constants.currently.addingAnItem}
                        isDisabled={this.props.isLoading !== 0}
                    >
                        Add Item
                    </BottomNavButton>
                    {this.props.postItemCount > 1 &&
                    <PostButton
                        onClick={this.internalHandleReorder}
                        isLoading={false}
                        isDisabled={this.props.isLoading !== 0}
                    >
                        {this.props.isReordering ? 'Stop Reordering' : 'Reorder'}
                    </PostButton>}
                    <HorizontalSeparator/>

                    <PostButton
                        onClick={this.props.handleSavePost}
                        isLoading={this.props.isLoading === 4}
                        isDisabled={this.props.isLoading !== 4 && this.props.isLoading !== 0}
                    >
                        Save
                    </PostButton>
                    <PostButton
                        onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to submit this post? It will no longer be editable.', 1)}
                        isLoading={this.props.isLoading === 1}
                        isDisabled={this.props.isLoading !== 1 && this.props.isLoading !== 0}
                    >
                        Submit
                    </PostButton>
                    <HorizontalSeparator/>

                    <PostButton
                        onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to create a new post? This will discard any unsaved changes.', 2)}
                        isLoading={this.props.isLoading === 2}
                        isDisabled={this.props.isLoading !== 2 && this.props.isLoading !== 0}
                    >
                        Create New Post
                    </PostButton>
                    {this.props.postId && this.props.postId > 0 ?
                        <PostButton
                            onClick={() => this.props.toggleConfirmationModal('Are you sure you wish to delete this post? This action is irreversible.', 3)}
                            isLoading={this.props.isLoading === 3}
                            isDisabled={this.props.isLoading !== 3 && this.props.isLoading !== 0}
                        >
                            Delete
                        </PostButton> : null}
                </Menu>
                <ItemCounter itemCount={this.props.postItemCount}/>
                <div className='bottom-bar__menu-button' onClick={this.openMenu}><i className="fas fa-bars"></i>&nbsp;Edit Menu</div>
            </>
        );
    }

    render() {
        const isMobile = breakpoints.isLessThan1200();
        return (
            <BottomBar>
                {isMobile ? this.renderMobileMenu() : this.renderDesktopMenu()}
            </BottomBar>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(CreatePostPageBottomBar);

