import { combineReducers } from "redux";
import generalReducer from "./reducers/generalReducer";
import postReducer from "./reducers/postReducer";
import authenticationReducer from "./reducers/authenticationReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import searchReducer from "./reducers/searchReducer";

const appReducers = combineReducers({
	general: generalReducer,
	post: postReducer,
	authentication: authenticationReducer,
	notifications: notificationsReducer,
	search: searchReducer
});

export default appReducers;
