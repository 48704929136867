import React from "react";
import './truncate.scss';

export const Truncate = (props) => {

    const width = isNaN(props.width) && props.width.includes('%') ? props.width : `${props.width}px`;
    return (
        <div title={props.text} className='truncate' style={{width: width}}>
            {props.text}
        </div>
    )
}

Truncate.defaultProps = {
    text: '',
    width: 50
}
