import * as generalAction from "../actions/generalAction"

export default function (
	state = {
		classes: [],
		itemQualities: [],
		strangePrefixes: [],
		unusualEffects: []
	},
	action) {
	switch (action.type) {
		case generalAction.SET_TF2CLASSES: {
			return {
				...state,
				classes: action.payload
			}
		}
		case generalAction.SET_ALL_TF2_ITEM_QUALITIES: {
			return {
				...state,
				itemQualities: action.payload
			}
		}
		case generalAction.SET_ALL_TF2_ITEM_STRANGE_PREFIXES: {
			return {
				...state,
				strangePrefixes: action.payload
			}
		}
		case generalAction.SET_ALL_TF2_ITEM_UNUSUAL_EFFECTS: {
			return {
				...state,
				unusualEffects: action.payload
			}
		}
		default: { }

	}
	return state;
}
