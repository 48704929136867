import React, {useEffect, useState} from 'react';

import "../searchPage.scss";
import DatePicker from "react-datepicker";
import {SingleSelectWrapper} from "../../../atoms/select/singleSelectWrapper";

export const UserSearch = (props) => {
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [username, setUsername] = useState("");
    const [tagsId, setTagsId] = useState("");
    const [steamId, setSteamId] = useState("");

    const [postMaxCount, setPostMaxCount] = useState("");
    const [postMinCount, setPostMinCount] = useState("");
    const [commentMaxCount, setCommentMaxCount] = useState("");
    const [commentMinCount, setCommentMinCount] = useState("");
    const [scoreMax, setScoreMax] = useState("");
    const [scoreMin, setScoreMin] = useState("");

    const [orderBy, setOrderBy] = useState({value: "joinedDateTime", label: "Joined Time"});
    const [orderDirection, setOrderDirection] = useState({value: "descending", label: "Descending"});

    const [joinedAfter, setJoinedAfter] = useState(null);
    const [joinedBefore, setJoinedBefore] = useState(null);

    const [orderByOptions, setOrderByOptions] = useState([]);
    const [orderDirectionOptions, setOrderDirectionOptions] = useState([]);

    useEffect(() => {
        setOrderDirectionOptionsInternal();
        setOrderByOptionsInternal();
    }, []);

    useEffect(() => {
        const searchRequest = {
            author: {
                username: username !== "" ? username : null,
                tagsTfId: tagsId !== "" ? tagsId : null,
                steamId: steamId !== "" ? steamId : null
            },
            postsMadeThreshold: {
                min: postMinCount === "" ? null : parseInt(postMinCount),
                max: postMaxCount === "" ? null : parseInt(postMaxCount)
            },
            totalScoreThreshold: {
                min: scoreMin === "" ? null : parseInt(scoreMin),
                max: scoreMax === "" ? null : parseInt(scoreMax)
            },
            commentsMadeThreshold: {
                min: commentMinCount === "" ? null : parseInt(commentMinCount),
                max: commentMaxCount === "" ? null : parseInt(commentMaxCount)
            },
            joinedThreshold: {
                min: joinedAfter && joinedAfter.toJSON(),
                max: joinedBefore && joinedBefore.toJSON()
            },
            order: {
                orderBy: orderBy.value,
                direction: orderDirection.value
            }
        }

        props.onChange(searchRequest);

    }, [username, tagsId, steamId,
        postMaxCount, postMinCount, commentMaxCount, commentMinCount, scoreMax, scoreMin,
        orderBy, orderDirection,
        joinedAfter, joinedBefore]);

    const setOrderDirectionOptionsInternal = () => {
        setOrderDirectionOptions(
            [
                {value: "descending", label: "Descending"},
                {value: "ascending", label: "Ascending"}
            ]
        );
    }

    const setOrderByOptionsInternal = () => {
        const defaultOption = {value: "joinedDateTime", label: "Joined Time"};
        const result = [defaultOption];

        result.push({value: "totalScore", label: "Total Score"});
        result.push({value: "postsMade", label: "Posts Made"});
        result.push({value: "commentsMade", label: "Comments Made"});
        result.push({value: "username", label: "Author's Username"});
        result.push({value: "tagsTfId", label: "Author's Tags.Tf ID"});
        result.push({value: "steamId", label: "Author's Steam ID"});

        setOrderByOptions(result);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.search();
    }

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const setNumericValue = (value, setMethod) => {
        if (isNumeric(value))
            setMethod(value);
        else
            setMethod("");
    }

    const handleAdvancedOptionsToggle = () => {
        const searchArea = document.getElementsByClassName("search__area--user");
        for (let i = 0; i < searchArea.length; i++) {
            if (showAdvancedOptions) {
                searchArea[0].classList.add("search__area--collapsed");
                searchArea[0].classList.remove("search__area--expanded");
            } else {
                searchArea[0].classList.remove("search__area--collapsed");
                searchArea[0].classList.add("search__area--expanded");
            }
        }

        setShowAdvancedOptions(!showAdvancedOptions);
    }

    const render = () => {
        return (
            <form name="user-search" id="user-search" autoComplete="off" onSubmit={handleSubmit}>
                <div className="search__hero-input-container">
                    <label htmlFor="tagsUsername">Username</label>
                    <input
                        name="tagsUsername"
                        id="tagsUsername"
                        className="search__text-input--hero"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className='search__advanced-button-container'>
                    <div className='search__advanced-button' onClick={handleAdvancedOptionsToggle}>
                        {showAdvancedOptions ? "Hide" : "Show"} Advanced Options
                    </div>
                </div>
                <div className='search__advanced' hidden={!showAdvancedOptions}>
                    <div className='search'>
                        <div className="search__column">
                            <label htmlFor="tags-id">Tags.Tf ID</label> <input id="tags-id" autoComplete="%^&*" className="search__text-input" value={tagsId} onChange={(e) => setNumericValue(e.target.value, setTagsId)}/>
                            <label htmlFor="tags-steam-id">Steam ID</label> <input id="tags-steam-id" autoComplete="%^&*" className="search__text-input" value={steamId} onChange={(e) => setNumericValue(e.target.value, setSteamId)}/>
                        </div>
                        <div className="search__column">
                            <label htmlFor="post-count-min">Posts Made</label>
                            <div>
                                <input id='post-count-min' placeholder="min" maxLength={6} className="search__text-input--small" value={postMinCount} onChange={(e) => setNumericValue(e.target.value, setPostMinCount)}/>
                                &nbsp;
                                <input placeholder="max" className="search__text-input--small" maxLength={6} value={postMaxCount} onChange={(e) => setNumericValue(e.target.value, setPostMaxCount)}/>
                            </div>

                            <label htmlFor="comment-count-min">Comments Made</label>
                            <div>
                                <input id='comment-count-min' placeholder="min" className="search__text-input--small" maxLength={6} value={commentMinCount} onChange={(e) => setNumericValue(e.target.value, setCommentMinCount)}/>
                                &nbsp;
                                <input placeholder="max" className="search__text-input--small" maxLength={6} value={commentMaxCount} onChange={(e) => setNumericValue(e.target.value, setCommentMaxCount)}/>
                            </div>

                            <label htmlFor="score-min">Total Score</label>
                            <div>
                                <input id='score-min' placeholder="min" className="search__text-input--small" maxLength={6} value={scoreMin} onChange={(e) => setNumericValue(e.target.value, setScoreMin)}/>
                                &nbsp;
                                <input placeholder="max" className="search__text-input--small" maxLength={6} value={scoreMax} onChange={(e) => setNumericValue(e.target.value, setScoreMax)}/>
                            </div>
                        </div>
                        <div className="search__column">
                            <label htmlFor="joined-after">Joined Before</label>
                            <DatePicker
                                id="joined-after"
                                selected={joinedBefore}
                                onChange={setJoinedBefore}
                                isClearable={true}
                                closeOnScroll={true}
                                maxDate={new Date()}
                                minDate={joinedAfter || new Date("2020-05-31T00:00:00.00Z")}
                                showMonthDropdown={true}
                            />
                            
                            <label htmlFor="joined-before">Joined After</label>
                            <DatePicker
                                id="joined-before"
                                selected={joinedAfter}
                                onChange={setJoinedAfter}
                                isClearable={true}
                                closeOnScroll={true}
                                maxDate={joinedBefore || new Date()}
                                minDate={new Date("2020-05-31T00:00:00.00Z")}
                                showMonthDropdown={true}
                            />
                        </div>
                        <div className="search__column">
                            <label htmlFor="order-by">Order By</label>
                            <SingleSelectWrapper
                                name="order-by"
                                id="order-by"
                                options={orderByOptions}
                                onChange={setOrderBy}
                                selectedValue={orderBy}
                            />

                            <label htmlFor="order-direction">Order Direction</label>
                            <SingleSelectWrapper
                                name="order-direction"
                                id="order-direction"
                                options={orderDirectionOptions}
                                onChange={setOrderDirection}
                                selectedValue={orderDirection}
                            />
                        </div>

                        {/*This hidden button allows us to handle Enter to submit*/}
                        <button type="submit" hidden={true}/>
                    </div>
                </div>

            </form>
        )
    }

    return render();
}

UserSearch.defaultProps = {
    handleSubmit: () => {
    }
}
