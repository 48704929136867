import React, {Component} from "react";
import {connect} from "react-redux";
import ItemHoverButton from "./itemHoverButton"
import {postActions} from "../../../../redux/actions/postAction";
import {constants} from "../../../../utilities/constants";
import {toast} from "react-toastify";

class itemStrangePrefixSelectButton extends Component {

    static defaultProps = {
        strangePrefix: null,
        item: {}
    }

    getClassName = () => {
        return `item-quality strange ${this.props.isLoading ? 'item-quality--disabled' : ''}`
    }

    setEditedItemTempStrangePrefix = (newStrangePrefix, isMouseEnter) => {
        if (this.props.isLoading)
            return;

        const {quality} = this.props.item;
        let tempQuality = quality;
        if (isMouseEnter &&
            (quality !== constants.qualities.strange)) {
            tempQuality = constants.qualities.strange
        }

        let tempItem = {
            ...this.props.item,
            tempQuality: tempQuality,
            tempStrangePrefix: newStrangePrefix,
            displayTempStrangePrefix: isMouseEnter,
            tempUnusualEffectUrl: '',
            displayTempUnusualEffect: isMouseEnter
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    setEditedItemStrangePrefix = (newStrangePrefix) => {
        if (this.props.isLoading)
            return;

        const {quality} = this.props.item;
        let tempQuality = quality;
        if (quality !== constants.qualities.strange) {
            tempQuality = constants.qualities.strange
            toast.info('Only Strange quality items can have a Strange prefix');
        }
        
        let tempItem = {
            ...this.props.item,
            strangePrefix: newStrangePrefix,
            tempQuality: '',
            quality: tempQuality,
            displayTempStrangePrefix: false,
            unusualEffectId: null,
            unusualEffectUrl: null,
            displayTempUnusualEffect: false
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    render() {
        return (
            <ItemHoverButton
                onMouseEnterParam={this.props.strangePrefix.name}
                onMouseEnter={(newStrangePrefix) => this.setEditedItemTempStrangePrefix(newStrangePrefix, true)}
                onMouseLeaveParam=""
                onMouseLeave={(newStrangePrefix) => this.setEditedItemTempStrangePrefix(newStrangePrefix, false)}
                onMouseClickParam={this.props.strangePrefix.name}
                onClick={this.setEditedItemStrangePrefix}
                getClassName={this.getClassName}
            >
                {this.props.strangePrefix.name}
            </ItemHoverButton>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(itemStrangePrefixSelectButton);
