import React, { Component } from "react";

import BaseTemplate from "../templates/_baseTemplate"
import {TopBar} from "../molecules/topBar/topBar";

class WithTopMenuTemplate extends Component {

	static defaultProps = {
		children: []
	}

	render() {
		return (
			<React.Fragment>
				<TopBar />
				<BaseTemplate>{this.props.children}</BaseTemplate>
			</React.Fragment>
		)
	}
}

export default WithTopMenuTemplate;
