import React, {Component} from "react"
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate"
import {Redirect} from "react-router";
import ItemDetails from "../../atoms/itemDetails";

import './postPage.scss';
import PostInfoComponent from "./components/postInfo/postInfoComponent";
import VotePanel from "./components/vote-panel/votePanel";
import CommentSection from "./components/comments/comment-section/commentSection";
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";
import {constants} from "../../../utilities/constants";
import {connect} from "react-redux";
import LoginModal from "../../molecules/modals/loginModal";
import ConfirmationModal from "../../molecules/modals/confirmationModal";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../atoms/loading-spinner/loadingSpinner";
import {VoteLog} from "./components/voteLog/voteLog";
import ItemEditOptionButton from "../../atoms/buttons/itemEdit/itemEditOptionButton";
import {QualityHelper} from "../../../utilities/qualityHelper";

class PostPage extends Component {
    constructor(props) {
        super(props);

        const commentAnchor = this.getCommentAnchor(props);

        this.state = {
            post: null,
            showModal: false,
            commentAnchor: commentAnchor,
            isLoadingPost: false,
            showDeleteModal: false,
            isLoadingMoreItems: false,
            selectedAction: constants.profileActions.comments
        }
    }

    getCommentAnchor = (props) => {
        let commentAnchor = props.location.hash.replace('#', '');
        if (commentAnchor && !isNaN(commentAnchor))
            commentAnchor = parseInt(commentAnchor);
        else
            commentAnchor = null;

        return commentAnchor;
    }

    componentDidMount() {
        const validUrl = !this.hasInvalidPostId();
        if (validUrl)
            this.fetchPost(0, 10);
    }

    fetchPost = (skip, take) => {
        this.setState({isLoadingPost: true});
        const postId = this.props.match.params.postId;
        const skipQuery = skip ? `&skip=${skip}` : '';
        const takeQuery = take ? `&take=${take}` : '';

        api.get(`${ENDPOINTS.posts.get}/${postId}?submitted=true${skipQuery}${takeQuery}`)
            .then(resp => {
                if (this.state.post && skip > 0) {
                    const post = this.state.post;
                    post.items = post.items.concat(resp.data.items);
                    this.setState({
                        post: post
                    });
                } else {
                    this.setState({
                        post: resp.data
                    })
                }
            })
            .catch(error => {
                toast.error("Failed to get the Post");
                console.error("Error getting items: ", error);
                this.props.history.push(constants.pagePaths.homePage);
            })
            .finally(() => {
                this.setState({isLoadingPost: false});
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const commentAnchor = this.getCommentAnchor(this.props);
        if (commentAnchor !== null && commentAnchor !== prevState.commentAnchor) {
            this.setState({
                commentAnchor: commentAnchor
            })
        }

        if (this.state.isLoadingPost === false && prevState.post && prevState.post.id !== parseInt(this.props.match.params.postId)) {
            const validUrl = !this.hasInvalidPostId();
            if (validUrl) {
                this.fetchPost(0, 10);
            }
        }
    }

    hasInvalidPostId = () => {
        return isNaN(this.props.match.params.postId);
    };

    updatePostScore = (newScore, postId) => {
        const post = this.state.post;
        post.score = newScore;

        this.setState({
            post: post
        });
    }

    toggleModalState = (state) => {
        if (typeof state !== 'boolean')
            state = false;

        this.setState((prevState) => {
            return {
                showModal: state || !prevState.showModal
            }
        })
    }

    toggleDeleteModalState = (state) => {
        if (typeof state !== 'boolean')
            state = false;

        this.setState((prevState) => {
            return {
                showDeleteModal: state || !prevState.showDeleteModal
            }
        })
    }

    deletePost = (isConfirmed) => {
        if (!isConfirmed) {
            this.toggleDeleteModalState();
            return;
        }

        api.delete(`${ENDPOINTS.posts.delete}/${this.state.post.id}`)
            .then(resp => {
                toast.success('Successfully deleted the post');
                this.props.history.push(constants.pagePaths.homePage);
            })
            .catch(ex => {
                toast.error('Failed to delete the post');
                console.error('Failed to delete the post: ', ex);
            })
    }

    getMaxToFetch = () => {
        const maxToFetch = this.state.post.totalItemCount - this.state.post.items.length;
        const take = maxToFetch > 10 ? 10 : maxToFetch;
        return take;
    }

    setIsLoadingMoreItems = (newValue) => {
        this.setState({
            isLoadingMoreItems: newValue
        });
    }

    fetchPostItems = (skip, take) => {
        this.setIsLoadingMoreItems(true);
        const postId = this.props.match.params.postId;
        const baseUrl = ENDPOINTS.posts.getItems.replace('{postId}', postId);
        api.get(`${baseUrl}?skip=${skip}&take=${take}`)
            .then(resp => {
                const post = this.state.post;
                post.items = post.items.concat(resp.data);
                this.setState({
                    post: post
                });
            })
            .catch(error => {
                toast.error("Failed to get more Items");
                console.error("Failed to get more Items: ", error);
            })
            .finally(() => {
                this.setIsLoadingMoreItems(false)
            });
    }

    fetchMorePosts = () => {
        const skip = this.state.post.items.length;
        const take = this.getMaxToFetch();
        this.fetchPostItems(skip, take);
    }

    render() {
        if (this.hasInvalidPostId()) {
            toast.error("Invalid Post Id, redirecting to homepage");
            return (<Redirect to="/"/>);
        }

        return (
            <>
                <WithTopMenuTemplate>
                    {
                        this.state.post ?
                            <div className="post-page__layout-top">
                                <PostInfoComponent
                                    post={this.state.post}
                                    onDeleteClick={this.toggleDeleteModalState}
                                    showDeleteButton={this.props.me && this.state.post.createdById === this.props.me.id}
                                />
                                {this.renderMiddleColumn()}
                                <VotePanel
                                    post={this.state.post}
                                    updatePostScore={this.updatePostScore}
                                    me={this.props.me}
                                    toggleLoginModal={this.toggleModalState}
                                />
                            </div>
                            : null
                    }
                </WithTopMenuTemplate>
                <LoginModal
                    modalIsOpen={this.state.showModal}
                    message='You can vote on posts and leave comments to help everyone find the best content by logging in.'
                    onClose={this.toggleModalState}
                />
                <ConfirmationModal
                    modalIsOpen={this.state.showDeleteModal}
                    question='Are you sure you want to delete this post? This action is irreversible.'
                    onClose={this.deletePost}
                />
            </>
        );
    }

    setSelectedAction = (newAction) => {
        this.setState({
            selectedAction: newAction
        })
    }

    renderMiddleColumn = () => {
        const ofText = this.state.post.totalItemCount - this.state.post.items.length;
        const loadMoreCount = this.getMaxToFetch();

        return (
            <div>
                {this.renderItems()}
                {
                    loadMoreCount > 0 &&
                    <div className='centered'>
                        <div className={`post-page__button-load-more ${this.state.isLoadingMoreItems ? 'post-page__button--disabled' : ''}`} onClick={this.fetchMorePosts}>
                            {
                                this.state.isLoadingMoreItems ?
                                    <LoadingSpinner size={LoadingSpinnerSizes.medium} show={true}/>
                                    : <span>Load {loadMoreCount} more (of {ofText})</span>
                            }
                        </div>
                    </div>
                }

                {((this.props.me && this.props.me.isAdmin) || (this.props.me && this.state.post && this.props.me.id === this.state.post.createdById)) &&
                <div className='post-page__options-row'>
                    <ItemEditOptionButton
                        title='Posts'
                        selectedAction={this.state.selectedAction}
                        actionName={constants.profileActions.comments}
                        setAction={this.setSelectedAction}
                        isProfileIcon={true}
                    />
                    <ItemEditOptionButton
                        title='Votes'
                        selectedAction={this.state.selectedAction}
                        actionName={constants.profileActions.votes}
                        setAction={this.setSelectedAction}
                        isProfileIcon={true}
                    />
                </div>
                }

                <div className="post-page__layout-comments">
                    {
                        ((this.props.me && this.props.me.isAdmin) || (this.props.me && this.state.post && this.props.me.id === this.state.post.createdById)) &&
                        <VoteLog
                            postId={this.state.post.id}
                            show={this.state.selectedAction === constants.profileActions.votes}
                        />
                    }
                    <CommentSection
                        postId={this.state.post.id}
                        me={this.props.me}
                        toggleLoginModal={this.toggleModalState}
                        commentAnchor={this.state.commentAnchor}
                        show={this.state.selectedAction === constants.profileActions.comments}
                    />
                </div>
            </div>
        );
    };

    renderItems = () => {
        return (
            <div>
                {this.state.post.items != null ?
                    (this.state.post.items.map((postItem, index) =>
                        <div key={index} className={"item-details-edit-container post-page__post-item " +  QualityHelper.sanitizeQuality(postItem.quality)}>
                            <ItemDetails item={postItem} post={postItem}/>
                        </div>))
                    : null
                }
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        me: state.authentication.me
    };
};

export default connect(mapStateToProps, null)(PostPage);
