import React, {Component} from "react";
import CookieConsent from "react-cookie-consent";

class CookieConsentWrapper extends Component {

    static defaultProps = {
        children: []
    }

    render() {
        const iAgreeText = 'I agree';
        return (
            <CookieConsent
                cookieName='cookie-consent'
                cookieValue={1}
                acceptOnScroll={false}
                buttonText={iAgreeText}
                style={{backgroundColor: "gray", color: "white", fontFamily: "Tf2 Secondary"}}
                buttonStyle={{minWidth: "200px", fontFamily: "Tf2 Secondary"}}
                onAccept={() => {
                    window.location.reload();
                }}
            >
                <span>By clicking the "{iAgreeText}" button, you acknowledge that you have read and understand our&nbsp;</span>
                <a
                    href='/policy/cookie'
                    target='_blank'
                    style={{color: "white", textDecoration: "underline"}}
                >Cookie Policy</a>.
            </CookieConsent>
        )
    }
}

export default CookieConsentWrapper;
