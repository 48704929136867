import React, {Component} from "react";
import Comment from "./comment";
import './comment.scss'
import {Username} from "../../../../../atoms/username/username";
import {TimeAgoWrapper} from "../../../../../atoms/timeAgoWrapper/timeAgoWrapper";

class CommentContainer extends Component {
    constructor(props) {
        super(props);

        let parentContainsChildToScrollTo = false;
        if (props.commentAnchor)
            parentContainsChildToScrollTo = props.comment.childComments.some(x => x.id === props.commentAnchor);

        this.state = {
            showReplies: parentContainsChildToScrollTo
        }
    };

    static defaultProps = {
        postId: null,
        comment: null,
        isChildComment: false,
        fetchComments: () => {
        },
        saveReply: () => {
        },
        updateComment: () => {
        },
        topLevelCommentId: null,
        isTopLevelComment: null,
        checkIfCanEditOrReply: () => {
        },
        deleteComment: () => {
        },
        me: {},
        toggleLoginModal: () => {
        },
        showGoToPostButton: false,
        commentAnchor: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.commentAnchor !== prevProps.commentAnchor) {

            let parentContainsChildToScrollTo = false;
            if (this.props.commentAnchor)
                parentContainsChildToScrollTo = this.props.comment.childComments.some(x => x.id === this.props.commentAnchor);

            this.setState({
                showReplies: parentContainsChildToScrollTo
            });
        }
    }

    renderCommentDetails = (comment) => {
        const username = comment.baseUsername ? comment.baseUsername : 'N/A';
        return (
            <div className="comment__details">
                <div title={username} className='comment__details-username'>
                    <i className="fas fa-pencil-alt comment__details-icon"></i>
                    <Username width={250} username={username} userId={comment.userId} avatarUrl={comment.userAvatar}/>
                </div>

                {comment.lastUpdatedDateTime &&
                <div>
                    <i className="fas fa-user-edit comment__details-icon"></i>
                    <TimeAgoWrapper
                        date={comment.lastUpdatedDateTime}
                        title={`Updated on `}
                    />
                </div>}

                <div>
                    <i className="fas fa-calendar-day comment__details-icon"></i>
                    <TimeAgoWrapper
                        minPeriod={60}
                        date={comment.dateTimeCreated}
                        title={`Created on `}
                    />
                </div>
            </div>
        );
    };

    handleViewRepliesClick = () => {
        this.setState((prevState) => {
            return {
                showReplies: !prevState.showReplies
            };
        });
    };

    renderViewReplies = () => {
        if (!this.props.comment.childComments || this.props.comment.childComments.length < 1)
            return null;

        const repliesText = this.props.comment.childComments.length === 1 ? 'Reply' : 'Replies';

        return (
            <div className='comment__controls-section'>
                <button className='comment__controls-stripped-button comment__controls-view-replies' onClick={this.handleViewRepliesClick}>
                    {
                        this.state.showReplies ?
                            <span><i className="fas fa-angle-up"></i> Hide {repliesText}</span> :
                            <span><i className="fas fa-angle-down"></i> Show {this.props.comment.childComments.length} {repliesText}</span>
                    }
                </button>
            </div>
        )
    };

    handleSave = (jsonContent) => {
        return this.props.saveReply(jsonContent, this.props.topLevelCommentId)
            .then(() => {
                this.setState({showReplies: true})
            });
    };

    render() {
        if (!this.props.comment)
            return null;

        let containerClassNames = 'comment__container ';
        if (this.props.isChildComment)
            containerClassNames += 'comment__container--child';

        return (
            <>
                <div className={containerClassNames} id={`comment-${this.props.comment.id}`} name={`comment-${this.props.comment.id}`}>
                    {this.renderCommentDetails(this.props.comment)}
                    <Comment
                        postId={this.props.postId}
                        comment={this.props.comment}
                        saveReply={this.handleSave}
                        updateComment={this.props.updateComment}
                        checkIfCanEditOrReply={this.props.checkIfCanEditOrReply}
                        deleteComment={this.props.deleteComment}
                        me={this.props.me}
                        toggleLoginModal={this.props.toggleLoginModal}
                        canEdit={this.props.comment.canEdit}
                        isChildComment={this.props.isChildComment}
                        showGoToPostButton={this.props.showGoToPostButton}
                    />
                    {this.renderViewReplies()}
                </div>
                {
                    this.state.showReplies && this.props.comment.childComments ?
                        (
                            this.props.comment.childComments.map((childComment) =>
                                <CommentContainer
                                    key={childComment.id}
                                    postId={this.props.postId}
                                    comment={childComment}
                                    isChildComment={true}
                                    saveReply={this.props.saveReply}
                                    topLevelCommentId={this.props.topLevelCommentId}
                                    checkIfCanEditOrReply={this.props.checkIfCanEditOrReply}
                                    updateComment={this.props.updateComment}
                                    deleteComment={this.props.deleteComment}
                                    me={this.props.me}
                                    toggleLoginModal={this.props.toggleLoginModal}
                                />
                            )
                        )
                        : null
                }
            </>
        )
    }
}

export default CommentContainer;
