const isLessThan750 = () => {
    return window.screen.width <= 750;
}

const isLessThan900 = () => {
    return window.screen.width <= 900;
}

const isLessThan1200 = () => {
    return window.screen.width <= 1200;
}

export const breakpoints = {
    isLessThan750,
    isLessThan900,
    isLessThan1200
}

