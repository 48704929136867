import * as postActions from "../actions/postAction"

export default function (
	state = {
		items: [],
		id: 0,
		reordering: false,
	},
	action) {
	switch (action.type) {
		case postActions.CREATE_POST_WITH_ITEM: {
			return {
				...state,
			}
		}
		case postActions.CLEAR_POST: {
			return {
				...state,
				id: 0,
				items: []
			}
		}
		case postActions.ADD_POST_ITEM: {
			return {
				...state,
					items: [...state.items, action.payload]
			}
		}
		case postActions.UPDATE_POST_ITEM: {
			return {
				...state,
				items: state.items.map(x => x.tempId === action.payload.tempId ? action.payload : x)
			}
		}
		case postActions.REMOVE_POST_ITEM: {
			return {
				...state,
				items: state.items.filter(x => x.tempId !== action.payload.tempId)
			}
		}
		case postActions.SET_POST: {
			return {
				...action.payload
			}
		}
		case postActions.GET_POST: {
			return {
				...state
			}
		}
		case postActions.SUBMIT_POST: {
			return {
				...state
			}
		}
		case postActions.UPDATE_ALL_POST_ITEMS: {
			return {
				...state,
				items: action.payload
			}
		}case postActions.TOGGLE_REORDER: {
			return {
				...state,
				reordering: !state.reordering
			}
		}
		default: { }

	}
	return state;
}
