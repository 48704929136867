import * as constants from "../../utilities/constants";
import newId from "../../utilities/newId";
import {api, ENDPOINTS} from "../../api/api";
import {toast} from "react-toastify";
import {getAllTf2ItemStrangePrefixes} from "./generalAction";

// API
export const CREATE_POST_WITH_ITEM = "CREATE_POST_WITH_ITEM";
export const GET_POST = "GET_POST";
export const DELETE_POST = "DELETE_POST"
export const SUBMIT_POST = "SUBMIT_POST"

// Store
export const CLEAR_POST = "CLEAR_POST";
export const ADD_POST_ITEM = "ADD_POST_ITEM";
export const UPDATE_POST_ITEM = "UPDATE_POST_ITEM";
export const REMOVE_POST_ITEM = "REMOVE_POST_ITEM";
export const SET_POST = "SET_POST";
export const UPDATE_ALL_POST_ITEMS = "UPDATE_ALL_POST_ITEMS";
export const TOGGLE_REORDER = "TOGGLE_REORDER";

export function updateAllPostItems(payload) {
    return {
        type: UPDATE_ALL_POST_ITEMS,
        payload: payload
    };
}

export function submitPost(payload) {
    return {
        type: SUBMIT_POST,
        payload: payload
    };
}

export const getPost = (postId, addQuotes) => (dispatch, getState) => {
    return api.get(`${ENDPOINTS.posts.get}/${postId}`)
        .then(resp => {
            const state = getState();
            resp.data.items.map(x => setItemDefaults(state, x, null, true));
            dispatch(setPost(resp.data));

            return getAllTf2ItemStrangePrefixes();
        }).catch(error => {
            console.error("Error getting items: ", error);
        });
}

export function setPost(payload) {
    return {
        type: SET_POST,
        payload: payload
    };
}

export const createPost = (isDraft, addQuotes) => (dispatch, getState) => {
    const state = getState();

    let post = state.post;

    let request = {
        id: post.id,
        isDraft: isDraft,
        items: []
    };

    const {itemQualities, strangePrefixes} = state.general;

    for (let i = 0; i < post.items.length; i++) {
        const item = post.items[i];
        const matchingStrangePrefix = strangePrefixes.find(({name}) => name === item.strangePrefix);

        let requestItem = {
            strangePrefixId: matchingStrangePrefix && matchingStrangePrefix.id,
            qualityId: itemQualities.find(({name}) => name === item.quality).id,
            itemId: item.itemId,
            id: item.id,
            index: item.index,
            unusualEffectId: item.unusualEffectId
        };

        if (item.name && item.name[0] === '"' && item.name[item.name.length - 1] === '"')
            requestItem.name = item.name.substring(1, item.name.length - 1);

        if (item.description && item.description[0] === '"' && item.description[item.description.length - 1] === '"')
            requestItem.description = item.description.substring(1, item.description.length - 1);

        request.items.push(requestItem);
    }

    let url = ENDPOINTS.posts.upsert;
    if (!request.isDraft)
        url = ENDPOINTS.posts.submit;

    return api.put(url, request)
        .then(resp => {
            const verb = isDraft ? 'saved' : 'submitted';
            resp.data.items.map(x => setItemDefaults(state, x, request, addQuotes));
            toast.success(`Successfully ${verb} the post`)
            dispatch(setPost(resp.data));
            return resp.data;
        }).catch(error => {
            if (!post.isDraft && error.response.status === 429)
                toast.error(`You have recently created a post. Please try again in a few minutes.`);
            else if (post.isDraft && error.response.status === 429)
                toast.error(`You have recently saved a post. Please try again in a few minutes.`);
            else {
                const verb = isDraft ? 'save' : 'submit';
                toast.error(`Failed to ${verb} the post`);
            }

            console.error("Error saving post: ", error);
        });
}

export function clearPost(payload) {
    return {
        type: CLEAR_POST,
        payload: payload
    };
}

export const addPostItem = (payload) => (dispatch, getState) => {
    const state = getState();
    if (state.post != null && state.post.items != null && (state.post.items.length + 1) > 1) {
        let allIndexes = state.post.items.map(x => x.index);
        let maxIndex = Math.max(...allIndexes)
        payload.index = maxIndex + 1;
    } else if (state.post.items.length === 0) {
        payload.index = 0;
    }

    payload.qualityId = state.general.itemQualities.find(x => x.name === constants.various.defaultItemQualityName).id;

    dispatch({
        type: ADD_POST_ITEM,
        payload: payload
    });
}

export function updatePostItem(payload) {
    return {
        type: UPDATE_POST_ITEM,
        payload: payload
    };
}

export function removePostItem(payload) {
    return {
        type: REMOVE_POST_ITEM,
        payload: payload
    };
}

function setItemDefaults(state, item, postSnapshot, addQuotes) {
    let preUpdateItem = postSnapshot ? postSnapshot.items.find(x => x.index === item.index) : null;
    item.tempId = preUpdateItem != null && typeof preUpdateItem.tempId != "undefined" ? preUpdateItem.tempId : newId();
    item.name = item.name == null ? "" : item.name;
    item.description = item.description == null ? "" : item.description;

    if (addQuotes && item.name)
        item.name = `"${item.name}"`

    if (addQuotes && item.description)
        item.description = `"${item.description}"`

    return item;
}

export const deletePost = (postId) => (dispatch, getState) => {
    const state = getState();
    return api.delete(`${ENDPOINTS.posts.delete}/${postId}`)
        .then(resp => {
            toast.success('Successfully deleted the post')
            if (state.post.id === postId)
                return dispatch(clearPost());
        }).catch(error => {
            toast.error('Failed to delete the post');
            console.error('Error deleting the post: ', error);
        });
}

export const postActions = {
    removePostItem,
    updatePostItem,
    addPostItem,
    clearPost,
    createPost,
    setPost,
    updateAllPostItems,
    deletePost
}
