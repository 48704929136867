import * as notificationActions from "../actions/notificationActions";


export default function (
    state = {
        notifications: null
    },
    action) {
    switch (action.type) {
        case notificationActions.SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload
            }
        }
        default: {
        }

    }
    return state;
}
