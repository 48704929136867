import React, {Component} from "react";
import {connect} from "react-redux";

import ItemSection from "../../molecules/sections/itemSection";
import CharacterRow from "../../molecules/rows/characterRow";
import SearchRow from "../../molecules/rows/searchRow";
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";
import * as generalAction from "../../../redux/actions/generalAction";
import ItemCategoriesRow from "../../molecules/rows/itemCategoriesRow";
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import CreatePageBottomBar from "./components/createPageBottomBar/createPageBottomBar";

class CreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedClass: {},
            tf2Classes: [],
            searchValue: '',
            selectedCategoryName: '',
            itemsToDisplay: [],
            page: 1,
            hasMoreItems: false
        }
    }

    static defaultProps = {
        postInProgressItemCount: 0,
        tf2Classes: [],
        post: {}
    }


    componentDidMount() {
        if (this.props.tf2Classes.length < 1)
            this.props.dispatch(generalAction.getAllTf2Classes());
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tf2Classes && props.tf2Classes.length > 0)
            return {
                tf2Classes: props.tf2Classes
            };

        return {};
    }

    handleCharacterSelect = (className) => {
        const selectedClass = this.state.tf2Classes.find(tf2Class => tf2Class.name === className);

        if (this.state.searchValue)
            this.handleItemSearch(this.state.searchValue, selectedClass);
        else
            this.setState({
                selectedClass: selectedClass,
                selectedCategoryName: '',
                itemsToDisplay: [],
                page: 1
            });
    }

    handleItemSearch = (searchValue, selectedClass) => {
        if (this.state.searchValue !== searchValue)
            this.setState({
                searchValue: searchValue
            });

        if (searchValue.length > 0 && searchValue.length < 3)
            return;

        const classToUse = selectedClass || this.state.selectedClass;
        return api.get(`${ENDPOINTS.tf2.getFilteredItemCategories}?classId=${classToUse.id}&search=${searchValue}`)
            .then(resp => {
                this.setState({
                    selectedClass: {
                        ...classToUse,
                        availableItemSlots: resp.data
                    },
                    selectedCategoryName: '',
                    itemsToDisplay: [],
                    page: 1
                });

                if (resp.data.length === 1)
                    this.handleCategoryClick(resp.data[0]);

            }).catch((error) => {
                toast.error('Failed to filter items');
                console.error('Failed to filter items: ', error);
            });
    }

    handleNextPage = () => {
        this.handleCategoryClick(this.state.selectedCategoryName, this.state.page + 1);
    }

    handleCategoryClick = (categoryName, page) => {
        page = page || 1;
        categoryName = categoryName || this.state.selectedCategoryName;
        return api.get(`${ENDPOINTS.items.getSteamItemsByClassAndCategory}?className=${this.state.selectedClass.name}` +
            `&categoryName=${categoryName}&search=${this.state.searchValue}&page=${page}`)
            .then(resp => {
                this.setState({
                    itemsToDisplay: page === 1 ? resp.data.items : this.state.itemsToDisplay.concat(resp.data.items),
                    selectedCategoryName: categoryName,
                    page: page,
                    hasMoreItems: resp.data.hasMore
                });
            }).catch(error => {
                toast.error('Failed to get items for category');
                console.error('Failed to get items for category: ', error);
            });
    }

    render() {
        return (
            <>
                <WithTopMenuTemplate>
                    <CharacterRow
                        handleCharacterSelect={this.handleCharacterSelect}
                        selectedClassName={this.state.selectedClass.name}
                        tf2Classes={this.state.tf2Classes}
                    />
                    <SearchRow
                        isVisible={!!this.state.selectedClass.id}
                        handleItemSearch={this.handleItemSearch}
                    />
                    <ItemCategoriesRow
                        tf2Class={this.state.selectedClass}
                        handleCategoryClick={this.handleCategoryClick}
                        selectedCategoryName={this.state.selectedCategoryName}
                    />
                    {this.state.selectedCategoryName &&
                    <ItemSection
                        handlePagination={this.handleNextPage}
                        items={this.state.itemsToDisplay}
                        hasMore={this.state.hasMoreItems}
                        postId={this.props.post.id}
                    />}
                </WithTopMenuTemplate>

                {this.props.postInProgressItemCount > 0 &&
                <CreatePageBottomBar
                    postItemCount={this.props.postInProgressItemCount}
                />}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tf2Classes: state.general.classes,
        postInProgressItemCount: state.post.items.length,
        post: state.post
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);
