export const itemEditActions = {
    qualities: "qualities",
    strangePrefixes: "strange-prefixes",
    unusualEffects: "unusual-effects",
    remove: "remove",
    expand: 'expand'
};

const profileActions = {
    posts: 'posts',
    comments: 'comments',
    admin: 'admin',
    postsInProgress: 'posts-in-progress',
    logout: 'logout',
    votes: 'votes'
};

export const currently = {
    browsing: "browsing",
    creating: "creating",
    addingAnItem: "addingAnItem"
};

export const various = {
    defaultItemQualityName: "unique"
};

export const pagePaths = {
    homePage: `/`,
    createPost: `/create/post`,
    createBrowse: `/create/browse`,
    viewPost: `/view/post`,
    policyPage: '/policy',
    policyCookie: `/policy/cookie`,
    policyTermsAndConditions: `/policy/terms-and-conditions`,
    policyPrivacy: `/policy/privacy`,
    profile: '/profile',
    bannedPage: `/banned`,
    aboutUs: '/about-us',
    leaderboards: '/leaderboards',
    search: '/search',
}

const externalPagePaths = {
    wiki: 'https://tf2tags-lore.fandom.com/',
    twitter: 'https://twitter.com/TF2Tags',
    discord: 'https://discord.gg/9swmEYS',
    patreon: 'patreon',
    steamGroup: 'https://steamcommunity.com/groups/tf2tags'
}

const qualities = {
    normal: 'normal',
    unique: 'unique',
    strange: 'strange',
    community: 'community',
    unusual: 'unusual',
    valve: 'valve',
    selfMade: 'self-made'
}

const strangePrefixes = {
    strange: 'strange'
}

const leaderboardActions = {
    posts: 'Posts',
    users: 'by Users',
    items: 'Items',
    time: 'Time',
    go: 'Go',
    userLeaderboards: {
        mostCommentsMade: 'Most Comments Made',
        mostPostsMade: 'Most Posts Made',
        mostScoreEarned: 'Most Score Earned',
        mostScoreLost: 'Most Score Lost',
        tagsTfSupporters: 'Tags.Tf Supporters'
    }
}

const searchActions = {
    posts: 'posts',
    users: 'users',
    go: 'go'
}

export const constants = {
    pagePaths,
    qualities,
    strangePrefixes,
    profileActions,
    externalPagePaths,
    itemEditActions,
    leaderboardActions,
    searchActions
}
