import {api, ENDPOINTS} from "../api";
import {toast} from "react-toastify";

const fetchPostsForHomepage = (pageNumber, startTime, userId, fetchOnlyDraft) => {
    const userParam = userId ? `&userId=${userId}` : '';
    const fetchOnlyDraftParam = fetchOnlyDraft ? `&draft=${fetchOnlyDraft}` : '';
    return api.get(`${ENDPOINTS.posts.get}?page=${pageNumber}&startTime=${startTime.toJSON()}${userParam}${fetchOnlyDraftParam}`)
        .then(resp => {
            return resp;
        }).catch(error => {
            toast.error('Failed to get Posts');
            console.error("Error getting items: ", error);
        });
}

export const postsService = {
    fetchPostsForHomepage
}
