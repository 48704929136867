import React from 'react';
import {EditorState, RichUtils, convertToRaw, ContentState, convertFromRaw, KeyBindingUtil, SelectionState, genKey} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import {LoadingSpinner} from "../../../../../../atoms/loading-spinner/loadingSpinner";
import {debounce} from "debounce";

import '../../commentShared.scss';
import 'draft-js/dist/Draft.css';
import './WYSIWYG.scss';
import {toast} from "react-toastify";
import {api, ENDPOINTS} from "../../../../../../../api/api";

import 'draft-js-mention-plugin/lib/plugin.css';
import MentionEntry from "./mentions/mentionEntry";
import MentionHighlight from "./mentions/mentionHighlight";
import * as mentionStyles from './mentions/mentions.module.scss'
const styleMap = {
    'STRIKETHROUGH': {
        textDecoration: 'line-through',
    },
};

const MAX_CONTENT_LENGTH = 500;
const KEYBINDINGS = {
    save: 'KEYBINDING_SAVE'
};

class WYSIWYG extends React.Component {
    constructor(props) {
        super(props);

        this.mentionPlugin = createMentionPlugin({
            mentionPrefix: '@',
            supportWhitespace: true,
            theme: mentionStyles,
            mentionComponent: MentionHighlight
        });

        let editorState = this.getInitialEditorState(props);

        this.state = {
            isLoading: false,
            editorState: editorState,
            readonlyBlockCount: 0,
            hasContentChanged: false,
            forceContentUpdateHandled: () => {
            },
            suggestions: [],
            parent: {},
            isChild: false
        };
    }

    static defaultProps = {
        readonly: false,
        content: null,
        saveContent: () => {
        },
        handleCancelClick: null,
        placeholder: 'Leave a comment',
        autoFocus: false,
        mentions: []
    };

    static getDerivedStateFromProps(props, state) {
        if ((!props.forceContentUpdate || !props.forceContentUpdateHandled) && (!props.content || !props.readonly))
            return {};

        if (props.forceContentUpdate && props.forceContentUpdateHandled)
            props.forceContentUpdateHandled();

        let content = ContentState.createFromText('');
        let editorState = EditorState.push(state.editorState, content);
        let readonlyBlockCount = 0;
        if (props.content) {
            if (typeof props.content === 'string') {
                const rawContent = ContentState.createFromText(props.content);
                editorState = EditorState.push(state.editorState, rawContent);
            } else {
                for (let property in props.content.entityMap) {
                    let entity = props.content.entityMap[property];
                    if (!entity || !entity.data || !entity.data.mention)
                        continue;

                    if (props.mentions) {
                        const updatedMention = props.mentions.find(x => x.userId === entity.data.mention.id);
                        if (updatedMention && updatedMention.username)
                            entity.data.mention.name = updatedMention.username;
                    }
                }
                const rawContent = convertFromRaw(props.content);
                editorState = EditorState.push(state.editorState, rawContent);
                readonlyBlockCount = props.content.blocks.length;
            }
        }

        if (props.content.blocks && props.content.blocks.length !== readonlyBlockCount) {
            const rawContent = convertFromRaw(props.content);
            let editorState = EditorState.push(state.editorState, rawContent);

            return {
                editorState: editorState,
                readonlyBlockCount: props.content.blocks.length
            };
        }

        return {
            editorState: editorState,
            readonlyBlockCount: readonlyBlockCount
        };
    };

    componentDidMount() {
        if (this.props.autoFocus)
            this.focusEditor();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.readonly && !this.props.readonly) {
            this.focusEditor();

            const content = this.state.editorState.getCurrentContent();
            const blockMap = content.getBlockMap();

            const key = blockMap.last().getKey();
            const length = blockMap.last().getLength();

            const selection = new SelectionState({
                anchorKey: key,
                anchorOffset: length,
                focusKey: key,
                focusOffset: length,
            });

            this.onChange(EditorState.forceSelection(this.state.editorState, selection));
        }
    }

    getInitialEditorState = (props) => {
        if (props.isChild && props.parent) {
            const username = props.parent.baseUsername;
            let stateWithMention = {
                blocks: [
                    {
                        key: genKey(), //Use the genKey function from draft
                        text: `${username} `,
                        type: 'unstyled',
                        inlineStyleRanges: [],
                        data: {},
                        depth: 0,
                        entityRanges: [
                            {offset: 0, length: username.length, key: 0}
                        ]
                    }
                ],
                entityMap: [{
                    type: "mention",
                    mutability: "SEGMENTED",
                    data: {
                        mention: {
                            name: username,
                            id: props.parent.userId
                        }
                    }
                }]
            };

            return EditorState.createWithContent(convertFromRaw(stateWithMention));
        }


        return EditorState.createEmpty();
    }

    onSearchChange = debounce(({value}) => {
        if (!value)
            return;

        api.get(`${ENDPOINTS.user.getMentions}?username=${value}`)
            .then(resp => {
                let parsedResp = [];
                for (let i = 0; i < resp.data.length; i++) {
                    parsedResp.push({
                        id: resp.data[i].userId,
                        name: resp.data[i].baseUsername
                    })
                }

                this.setState({
                    suggestions: parsedResp
                });
            })
            .catch(ex => {
                toast.error('Failed to get mentions');
                console.error('Failed to get mentions: ', ex);
            })
    }, 200);

    wysiwygKeyBindings = (event) => {
        if (event.keyCode === 13 && KeyBindingUtil.hasCommandModifier(event))
            return KEYBINDINGS.save;
    };

    onChange = (newState) => {
        const currentContentState = this.state.editorState.getCurrentContent();
        const newContentState = newState.getCurrentContent();

        let hasContentChanged = this.state.hasContentChanged;
        if (currentContentState !== newContentState) {
            hasContentChanged = true;
        }

        this.setState({
            editorState: newState,
            hasContentChanged: hasContentChanged
        })
    };

    setEditor = (editor) => {
        this.editor = editor;
    };

    focusEditor = () => {
        if (this.editor) {
            setTimeout(() => {
                if (this.editor) // if we're navigating to a different page we need to check again
                    this.editor.focus();
            }, 0); // If we do it immediately it breaks the mentions plugin
        }
    };

    handleKeyCommand = (command, editorState) => {
        if (command === KEYBINDINGS.save) {
            this.onSaveClick();
            return 'handled';
        }

        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    onInlineStyleToggleClick = (event, styleName) => {
        event.preventDefault();
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, styleName));
    };

    onBoldClick = (event) => {
        this.onInlineStyleToggleClick(event, 'BOLD');
    };

    onItalicClick = (event) => {
        this.onInlineStyleToggleClick(event, 'ITALIC');
    };

    onUnderlineClick = (event) => {
        this.onInlineStyleToggleClick(event, 'UNDERLINE');
    };

    onStrikeThroughClick = (event) => {
        this.onInlineStyleToggleClick(event, 'STRIKETHROUGH');
    };

    onUndoClick = (event) => {
        event.preventDefault();
        this.onChange(EditorState.undo(this.state.editorState));
    };

    onRedoClick = (event) => {
        event.preventDefault();
        this.onChange(EditorState.redo(this.state.editorState));
    };

    isStringNullOrWhitespace = (string) => {
        if (!string)
            return true;

        return !/\S/.test(string);
    };

    onSaveClick = (forceSave) => {
        if (!forceSave) {
            const currentContent = this.state.editorState.getCurrentContent();
            const currentContentLength = currentContent.getPlainText().length;
            const exceedsMaxLength = currentContentLength > MAX_CONTENT_LENGTH;
            const invalidContent = exceedsMaxLength || this.props.isLoading || currentContentLength === 0 || !this.state.hasContentChanged;
            if (invalidContent)
                return
        }

        const content = this.state.editorState.getCurrentContent();
        const rawContent = convertToRaw(content);

        let contentBlocks = [];
        let previousLineIsBlank = false;
        let commentIsBlank = true;
        for (let i = 0; i < rawContent.blocks.length; i++) {
            if (!this.isStringNullOrWhitespace(rawContent.blocks[i].text)) {
                contentBlocks.push(rawContent.blocks[i]);
                previousLineIsBlank = false;
                commentIsBlank = false;
            }

            if (this.isStringNullOrWhitespace(rawContent.blocks[i].text) && !previousLineIsBlank) {
                contentBlocks.push(rawContent.blocks[i]);
                previousLineIsBlank = true;
            }
        }

        if (commentIsBlank) {
            toast.info("Please add content to your comment");
            return;
        }

        rawContent.blocks = contentBlocks;
        const jsonContent = JSON.stringify(rawContent);

        const mentions = [];
        for (let property in rawContent.entityMap) {
            if (!rawContent.entityMap[property] || !rawContent.entityMap[property].data || !rawContent.entityMap[property].data.mention)
                continue;

            mentions.push(rawContent.entityMap[property].data.mention.id);
        }

        const comment = {
            mentionUserIds: mentions,
            content: jsonContent
        }

        this.props.saveContent(comment).then(resp => {
            if (resp) {
                if (this.props.handleCancelClick)
                    this.props.handleCancelClick();

                const editorState = EditorState.createEmpty();
                this.setState({
                    editorState: editorState
                });
            }
        });
    };

    renderSaveAndCharacterCount = () => {
        const currentContent = this.state.editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText().length;
        const exceedsMaxLength = currentContentLength > MAX_CONTENT_LENGTH;
        const disableButton = exceedsMaxLength || this.props.isLoading || currentContentLength === 0 || !this.state.hasContentChanged;
        const nearExceeded = (currentContentLength / MAX_CONTENT_LENGTH) > 0.9;

        let counterClassNames = "wysiwyg__controls-counter ";
        if (exceedsMaxLength)
            counterClassNames += "wysiwyg__controls-counter--exceeded";
        else if (nearExceeded)
            counterClassNames += "wysiwyg__controls-counter--near-exceeded";

        let buttonClassNames = "comment__controls-button wysiwyg__controls-button-save " + (disableButton ? "comment__controls-button--disabled" : "");
        return (
            <div className="wysiwyg__controls-save">
                <span className={counterClassNames}>{currentContentLength}/{MAX_CONTENT_LENGTH}</span>

                <button className={buttonClassNames} disabled={disableButton} onClick={() => this.onSaveClick(true)}>
                    {this.props.isLoading ?
                        <LoadingSpinner show={this.props.isLoading} size='sm'/> :
                        <span>Save</span>}
                </button>

                {this.props.handleCancelClick ?
                    <button className='comment__controls-button' onClick={() => this.props.handleCancelClick()}>Cancel</button> :
                    null}
            </div>
        )
    };

    renderControls = () => {
        const buttonClassNames = `comment__controls-button ${this.props.isLoading ? 'comment__controls-button--disabled' : ''}`;

        return (
            <div className="wysiwyg__controls">
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onBoldClick : () => {
                }} title="Bold"><b>B</b></button>
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onItalicClick : () => {
                }} title="Italic"><i>I</i></button>
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onStrikeThroughClick : () => {
                }} title="Strikethrough"><span style={{textDecoration: "line-through"}}>S</span></button>
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onUnderlineClick : () => {
                }} title="Underline"><span style={{textDecoration: "underline"}}>U</span></button>
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onUndoClick : () => {
                }} title="Undo"><i className="fas fa-undo"></i></button>
                <button className={buttonClassNames} onMouseDown={!this.props.isLoading ? this.onRedoClick : () => {
                }} title="Redo"><i className="fas fa-redo"></i></button>
                {this.renderSaveAndCharacterCount()}
            </div>
        );
    };

    render() {
        const {MentionSuggestions} = this.mentionPlugin;
        const plugins = [this.mentionPlugin];

        return (
            <>
                {
                    (<div className={`wysiwyg ${this.props.isLoading ? 'wysiwyg--is-loading' : ''}`} onClick={this.focusEditor}>
                        <Editor
                            ref={this.setEditor}
                            editorState={this.state.editorState}
                            onChange={this.onChange}
                            spellCheck={true}
                            stripPastedStyles={true}
                            customStyleMap={styleMap}
                            placeholder={this.props.placeholder}
                            readOnly={this.props.isLoading || this.props.readonly}
                            keyBindingFn={this.wysiwygKeyBindings}
                            handleKeyCommand={this.handleKeyCommand}
                            plugins={plugins}
                        />
                        <MentionSuggestions
                            onSearchChange={this.onSearchChange}
                            suggestions={this.state.suggestions}
                            entryComponent={MentionEntry}
                        />
                    </div>)

                }
                {this.props.readonly ? null : (this.renderControls())}
            </>
        );
    }
}

export default WYSIWYG;
