const updateLocalPostScore = (newScore, postId, existingPosts) => {
    for (let i = 0; i < existingPosts.length; i++) {
        if (existingPosts[i].id === postId) {
            existingPosts[i].score = newScore
            break;
        }
    }

    return existingPosts;
}

export const scoreHelpers = {
    updateLocalPostScore
}
