import React, { Component } from "react";

class ItemHoverButton extends Component {

	static defaultProps = {
		onMouseEnterParam: null,
		onMouseLeaveParam: null,
		onMouseClickParam: null,
		onMouseEnter: () => { },
		onMouseLeave: () => { },
		onClick: () => { },
		getClassName: () => { }
	}

	render() {
		return (
			<div
				className={`centered ${this.props.getClassName()}`}
				onMouseEnter={() => this.props.onMouseEnter(this.props.onMouseEnterParam)}
				onMouseLeave={() => this.props.onMouseLeave(this.props.onMouseLeaveParam)}
				onClick={() => this.props.onClick(this.props.onMouseEnterParam)}
			>
				{this.props.children}
			</div>
		)
	}
}

export default ItemHoverButton;