import {api, ENDPOINTS} from "../../api/api";
import {toast} from "react-toastify";

export const SET_SELECTED_TF2_CLASS = "SET_SELECTED_TF2_CLASS";
export const RESET_SELECTED_TF2_CLASS = "RESET_SELECTED_TF2_CLASS";

export const SET_SELECTED_TF2_ITEM_CATEGORY = "SET_SELECTED_TF2_ITEM_CATEGORY";
export const RESET_SELECTED_TF2_ITEM_CATEGORY = "RESET_SELECTED_TF2_ITEM_CATEGORY";

export const SET_TF2_ITEMS_FOR_SELECTED_CLASS_AND_CATEGORY = "SET_TF2_ITEMS_FOR_SELECTED_CLASS_AND_CATEGORY";
export const RESET_TF2_ITEMS_FOR_SELECTED_CLASS_AND_CATEGORY = "RESET_TF2_ITEMS_FOR_SELECTED_CLASS_AND_CATEGORY";

export const SET_TF2CLASSES = "SET_TF2CLASSES";
export const SET_FILTERED_TF2_ITEM_CATEGORIES = "SET_FILTERED_TF2_ITEM_CATEGORIES";
export const SET_ALL_TF2_ITEM_QUALITIES = "SET_ALL_TF2_ITEM_QUALITIES";
export const SET_ALL_TF2_ITEM_STRANGE_PREFIXES = "SET_ALL_TF2_ITEM_STRANGE_PREFIXES";
export const SET_ALL_TF2_ITEM_UNUSUAL_EFFECTS = "SET_ALL_TF2_ITEM_UNUSUAL_EFFECTS";

export const getAllTf2Classes = () => (dispatch) => {
    return api.get(ENDPOINTS.tf2.getAllClasses).then(response => {
        response.data.forEach(tf2Class => {
            tf2Class.name = tf2Class.name.toLowerCase();
        });

        dispatch({
            type: SET_TF2CLASSES,
            payload: response.data
        });
    }).catch(error => {
        console.error("Error getting Tf2 Item: ", error);
    });
}

export const getAllTf2ItemQualities = () => (dispatch) => {
    return api.get(ENDPOINTS.tf2.getQualities)
        .then(response => {
            response.data.forEach(quality => {
                quality.name = quality.name.toLowerCase();
            });
            dispatch(setAllTf2ItemQualities(response.data));
        }).catch(error => {
            console.error("Error getting Tf2 Item Qualities: ", error);
        });
}

export const getAllTf2ItemStrangePrefixes = () => (dispatch, getState) => {
    const state = getState();

    if (state.general.strangePrefixes.length > 0)
        return;

    return api.get(ENDPOINTS.tf2.getStrangePrefixes)
        .then(response => {
            response.data.forEach(prefix => {
                prefix.name = prefix.name.toLowerCase();
            });
            dispatch(setAllItemStrangePrefixes(response.data));
        }).catch(error => {
            toast.error('Failed to get Strange Prefixes')
            console.error("Error getting Tf2 Item Strange Prefixes: ", error);
        });
}

export const getAllTf2ItemUnusualEffects = () => (dispatch, getState) => {
    const state = getState();

    if (state.general.unusualEffects.length > 0)
        return;

    return api.get(ENDPOINTS.tf2.getUnusualEffects)
        .then(response => {
            response.data.forEach(prefix => {
                prefix.name = prefix.name.toLowerCase();
            });
            dispatch(setAllItemUnusualEffects(response.data));
        }).catch(error => {
            toast.error('Failed to get Unusual Effects')
            console.error("Error getting Tf2 Unusual Effects: ", error);
        });
}

export function setAllTf2ItemQualities(payload) {
    return {
        type: SET_ALL_TF2_ITEM_QUALITIES,
        payload: payload
    };
}

export function setAllItemStrangePrefixes(payload) {
    return {
        type: SET_ALL_TF2_ITEM_STRANGE_PREFIXES,
        payload: payload
    };
}

export function setAllItemUnusualEffects(payload) {
    return {
        type: SET_ALL_TF2_ITEM_UNUSUAL_EFFECTS,
        payload: payload
    };
}

export const generalActions = {
    getAllTf2Classes,
    getAllTf2ItemQualities,
    getAllTf2ItemStrangePrefixes,
    getAllTf2ItemUnusualEffects
}
