import React, {Component} from "react";
import {Link} from "react-router-dom";
import newId from "../../../utilities/newId";
import * as constants from "../../../utilities/constants";
import * as postActions from "../../../redux/actions/postAction";
import {connect} from "react-redux";

class ItemSelectButton extends Component {
    static defaultProps = {
        item: {},
        postId: null
    }

    setSelectedItem = () => {
        let tempItem = {
            ...this.props.item,
            itemId: this.props.item.id,
            quality: constants.various.defaultItemQualityName,
            tempId: newId(),
            id: 0
        }
        this.props.dispatch(postActions.addPostItem(tempItem));
    }

    getUrl = () => {
        const postId = this.props.postId ? `/${this.props.postId}` : '';
        return `/create/post${postId}`;
    }

    render() {
        return (
            <Link to={this.getUrl()} className="item-image-container" onClick={this.setSelectedItem}>
                <img className="item-image" src={this.props.item.imageUrl} alt={this.props.item.translatedName} title={this.props.item.translatedName}/>
                <div>{this.props.item.translatedName}</div>
            </Link>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};


export default connect(null, mapDispatchToProps)(ItemSelectButton);
