import React, {Component} from "react";
import {constants} from "../../../../utilities/constants";

class ItemEditOptionButton extends Component {

    static defaultProps = {
        selectedAction: "",
        actionName: "",
        setAction: () => {
        },
        onClick: () => {
        },
        title: '',
        isLoading: false,
        isProfileIcon: false
    }

    getIconClass = () => {
        let extension = "";

        if (this.props.selectedAction === "") {
            extension = this.props.actionName === constants.itemEditActions.expand ? '--rotated-left' : "--grey";
        } else {
            extension = (this.props.selectedAction !== "" && this.props.selectedAction === this.props.actionName ? '' : (this.props.actionName === constants.itemEditActions.expand ? '--rotated-left' : "--grey"));
        }

        const iconName = this.props.isProfileIcon ? `profile-icon` : `item-edit-icon`

        return `${iconName} ${iconName + extension} ${this.props.actionName.toLowerCase()} ${this.props.isLoading ? `${iconName}--disabled` : ''}`;
    }

    handleOnClick = () => {
        if (this.props.isLoading)
            return;

        this.props.setAction(this.props.actionName);
        this.props.onClick();
    }

    render() {
        return (
            <div title={this.props.title} className={this.getIconClass()} onClick={this.handleOnClick}/>
        )
    }
}

export default ItemEditOptionButton;
