import React, {Component} from "react";
import ItemEditOptionButton from "./itemEditOptionButton";
import * as Constants from "../../../../utilities/constants";
import ConfirmationModal from "../../../molecules/modals/confirmationModal";
import * as postActions from "../../../../redux/actions/postAction";
import {nameDescriptionHelper} from "../../../../utilities/nameDescriptionHelper";
import {connect} from "react-redux";
import {toast} from "react-toastify";

class ItemRemoveButton extends Component {
    static defaultProps = {
        selectedAction: "",
        actionName: "",
        setAction: () => {
        },
        item: {},
        title: '',
        isLoading: false,
        postItemCount: 0,
    };

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        }
    }

    toggleModal = () => {
        this.setState((prevState) => {
            return {
                modalIsOpen: !prevState.modalIsOpen
            }
        });
    };

    onModalClose = (result) => {
        this.toggleModal();

        if (result === true)
            this.props.dispatch(postActions.removePostItem(this.props.item));
    };

    getQuestion = () => {
        let name = ''

        if (this.props.item.name)
            name = this.props.item.name.toUpperCase();
        else
            name = nameDescriptionHelper.getPlaceholder(this.props.item.quality, this.props.item.strangePrefix, this.props.item.translatedName);

        return `Are you sure you wish to remove ${name}?`;
    };

    openModalIfApplicable = () => {
        if (this.props.postItemCount < 2) {
            toast.info('A post needs to have at least 1 item');
            return;
        }

        this.toggleModal();
    }

    render() {
        return (
            <>
                <ItemEditOptionButton
                    selectedAction={this.props.selectedAction}
                    actionName={Constants.itemEditActions.remove}
                    setAction={this.props.setAction}
                    onClick={this.openModalIfApplicable}
                    title={this.props.title}
                    isLoading={this.props.isLoading}
                />

                <ConfirmationModal onClose={this.onModalClose} modalIsOpen={this.state.modalIsOpen} question={this.getQuestion()}/>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(ItemRemoveButton);
