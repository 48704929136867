import React, {useEffect, useState} from 'react';
import {api, ENDPOINTS} from "../../../../../api/api";
import {toast} from "react-toastify";
import './voteLog.scss'
import {Username} from "../../../../atoms/username/username";
import {TimeAgoWrapper} from "../../../../atoms/timeAgoWrapper/timeAgoWrapper";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../../../atoms/loading-spinner/loadingSpinner";
import {Link} from "react-router-dom";
import {constants} from "../../../../../utilities/constants";

export const VoteLog = (props) => {
    const [voteLogs, setVoteLogs] = useState([]);
    const [isLoadingInitial, setIsLoadingInitial] = useState(false);
    const [sortedField, setSortedField] = useState('date')
    const [sortDirection, setSortDirection] = useState(1)
    const [totalVotes, setTotalVotes] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const currentTime = (new Date()).toJSON();
    const takeAmount = 25;

    useEffect(() => {
        if (!props.postId && !props.userId)
            return;

        if (props.isPostPage)
            fetchVoteLog(0, takeAmount)
        else
            fetchVoteLogProfile(0, takeAmount);
    }, [])

    const fetchVoteLogProfile = (skip, take, isLoadingMore) => {
        const skipQuery = `skip=${skip}`;
        const takeQuery = `take=${take}`;
        const baseUrl = ENDPOINTS.user.getVoteLogs.replace('{userId}', props.userId)

        if (isLoadingMore)
            setIsLoadingMore(true)
        else
            setIsLoadingInitial(true);

        api.get(`${baseUrl}?${skipQuery}&${takeQuery}&endDate=${currentTime}`)
            .then(resp => {
                if (!isLoadingMore) {
                    setIsLoadingInitial(false);
                    setVoteLogs(resp.data.votes);
                    setTotalVotes(resp.data.totalVotes);
                } else {
                    let newVotes = [...voteLogs];
                    newVotes = newVotes.concat(resp.data.votes);
                    setVoteLogs(newVotes);
                    setIsLoadingMore(false);
                }
            })
            .catch(ex => {
                toast.error('Failed to get the vote log');
                console.error('Failed to get the vote log: ', ex)
            })
    }

    const fetchVoteLog = (skip, take, isLoadingMore) => {
        const skipQuery = `skip=${skip}`;
        const takeQuery = `take=${take}`;
        const baseUrl = ENDPOINTS.posts.scores.getLogsForPost.replace('{postId}', props.postId)

        if (isLoadingMore)
            setIsLoadingMore(true)
        else
            setIsLoadingInitial(true);

        api.get(`${baseUrl}?${skipQuery}&${takeQuery}&endDate=${currentTime}`)
            .then(resp => {
                if (!isLoadingMore) {
                    setIsLoadingInitial(false);
                    setVoteLogs(resp.data.votes);
                    setTotalVotes(resp.data.totalVotes);
                } else {
                    let newVotes = [...voteLogs];
                    newVotes = newVotes.concat(resp.data.votes);
                    setVoteLogs(newVotes);
                    setIsLoadingMore(false);
                }
            })
            .catch(ex => {
                toast.error('Failed to get the vote log');
                console.error('Failed to get the vote log: ', ex)
            })
    }

    const sortByProperty = (property) => {
        let sortedData = [...voteLogs];
        const sortAsc = setTheSortDirection(property);

        setSortedField(property);
        sortedData.sort((a, b) => {
            let aProp = null;
            let bProp = null;

            if (property === 'date') {
                aProp = a.lastUpdatedDateTime || a.createdDateTime;
                bProp = b.lastUpdatedDateTime || b.createdDateTime;
            } else {
                aProp = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
                bProp = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];
            }

            if (aProp < bProp)
                return sortAsc === 1 ? 1 : -1;
            if (aProp > bProp)
                return sortAsc === 1 ? -1 : 1;

            return 0;
        })

        setVoteLogs(sortedData);
    }

    const setTheSortDirection = (property) => {
        if (sortedField !== property) {
            setSortDirection(-1)
            return -1;
        } else if (sortedField === property && sortDirection === 1) {
            setSortDirection(-1)
            return -1;
        }
        setSortDirection(1)

        return 1;
    }

    const getChevronIcon = (property) => {
        if (property !== sortedField)
            return (<i className="fas fa-chevron-up vote-log__chevron-left"></i>) //left

        if (sortDirection === 1)
            return <i className="fas fa-chevron-up"></i> //up

        if (sortDirection === -1)
            return <i className="fas fa-chevron-up vote-log__chevron-down"></i> //down

    }

    const renderLoadMore = () => {
        const remainingCount = totalVotes - voteLogs.length;

        if (remainingCount <= 0)
            return null;

        const loadXMore = remainingCount > takeAmount ? takeAmount : remainingCount;

        return (
            <div className='vote-log__options'>
                <div
                    className='vote-log__table-button vote-log__options-load-more'
                    onClick={() => props.isPostPage ? fetchVoteLog(voteLogs.length, loadXMore, true) : fetchVoteLogProfile(voteLogs.length, loadXMore, true)}
                >
                    {isLoadingMore ?
                        <LoadingSpinner
                            show={true}
                            size={LoadingSpinnerSizes.small}
                        /> :
                        <span>Load {loadXMore} more (of {remainingCount})</span>
                    }
                </div>
            </div>
        )
    }

    const render = () => {
        if (!props.show)
            return null;

        if (isLoadingInitial) {
            return <LoadingSpinner show={true} size={LoadingSpinnerSizes.large}/>
        }

        if (voteLogs.length < 1) {
            let text = "No one has voted on this post. Why not share it with your friends?";
            if (!props.isPostPage)
                text = "This user has not voted on any posts yet. Why not tag them in some posts to draw their attention using @?"

            return <div className='vote-log__info'>{text}</div>
        }

        return (
            <div className='vote-log__container'>
                <table className='vote-log__table' cellSpacing={0}>
                    <thead>
                    <tr>
                        <th className='vote-log__table-header'>
                            <button onClick={() => sortByProperty('score')} className='vote-log__table-button'>
                                Vote {getChevronIcon('score')}
                            </button>
                        </th>
                        {props.isPostPage ?
                            <th className='vote-log__table-header'>
                                <button onClick={() => sortByProperty('baseUsername')} className='vote-log__table-button'>
                                    Username {getChevronIcon('baseUsername')}
                                </button>
                            </th>
                            : <th className='vote-log__table-header'>
                                <button onClick={() => sortByProperty('postName')} className='vote-log__table-button'>
                                    Post {getChevronIcon('postName')}
                                </button>
                            </th>
                        }
                        <th className='vote-log__table-header'>
                            <button onClick={() => sortByProperty('date')} className='vote-log__table-button'>
                                Voted {getChevronIcon('date')}
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        voteLogs.map((voteLog, index) =>
                            <tr key={index} className='vote-log__table-row'>
                                <td>{voteLog.score === 1 ? <span className='vote-log__crit'>Crit</span> : <span className='vote-log__miss'>Miss</span>}</td>
                                {
                                    props.isPostPage ?
                                        <td style={{overflow: 'hidden'}}>
                                            <Username
                                                userId={voteLog.userId}
                                                username={voteLog.baseUsername}
                                                avatarUrl={voteLog.userAvatarUrl}
                                            />
                                        </td>
                                        :
                                        <td style={{overflow: 'hidden', width: '50%'}}>
                                            <Link className='vote-log__link' to={`${constants.pagePaths.viewPost}/${voteLog.postId}`}>
                                                <img src={voteLog.postThumbnailUrl} className='vote-log__post-image'/>
                                                &nbsp;{voteLog.postName}
                                            </Link>
                                        </td>
                                }
                                <td>
                                    {
                                        voteLog.lastUpdatedDateTime ?
                                            <TimeAgoWrapper
                                                titlePrefix={'Voted on '}
                                                date={voteLog.lastUpdatedDateTime}
                                            /> :
                                            <TimeAgoWrapper
                                                titlePrefix={'Voted on '}
                                                date={voteLog.createdDateTime}
                                            />
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>

                {renderLoadMore()}

            </div>)
    }

    return render();
}

VoteLog.defaultProps = {
    postId: null,
    show: false,
    isPostPage: true
}
