import React, {useEffect} from 'react';
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import './bannedPage.scss'
import {authenticationService} from "../../../api/services/authenticationService";
import {useSelector} from "react-redux";

export const BannedPage = (props) => {
    const me = useSelector(state => state.authentication.me);

    useEffect(() => {
        if (me && me.isBanned)
            authenticationService.logout();
    }, [me])

    return (
        <WithTopMenuTemplate>
            <div className='banned-page'>
                <div>Unfortunately, your account has been suspended.</div>
                <div>If you believe the ban is unjustified, please reach out to <a className='banned-page__email' href="mailto:bans@tags.tf">bans@tags.tf</a> with your account details.</div>
                <img alt='ban-hammer' className='banned-page__ban-hammer' src='http://media.steampowered.com/apps/440/icons/c_carnival_mallet_large.02200bc9ef41a46179e35486567953f75a3a4dbf.png'/>
            </div>
        </WithTopMenuTemplate>
    )
}
