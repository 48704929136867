import React, {Component} from "react";
import WYSIWYG from "../WYSIWYG/editor/WYSIWYG";
import ConfirmationModal from "../../../../../molecules/modals/confirmationModal";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../../../../atoms/loading-spinner/loadingSpinner";
import '../commentShared.scss';
import {Link} from "react-router-dom";
import {constants} from "../../../../../../utilities/constants";

const DEFAULT_BLOCK_COUNT = 5;

class Comment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allBlocks: null,
            trimmedComment: null,
            showFullComment: false,
            modalIsOpen: false,
            isReplying: false,
            isReadonly: true,
            isLoadingCommentUpdate: false,
            forceContentUpdate: false,
            textContent: null
        }
    }

    static defaultProps = {
        comment: null,
        postId: null,
        updateComment: () => {
        },
        checkIfCanEditOrReply: () => {
        },
        deleteComment: () => {
        },
        isChildComment: false,
        showGoToPostButton: false
    };

    static getDerivedStateFromProps(props, state) {
        if (props.comment && props.comment.content && !props.comment.isDeleted && props.comment.userId &&
            (!state.allBlocks || state.allBlocks !== props.comment.content.blocks)) {

            return {
                allBlocks: [...props.comment.content.blocks]
            };
        }

        return {};
    }

    getCommentBlocks = (comment) => {
        if (comment.isDeleted || !comment.userId)
            return comment.content;

        if (this.state.allBlocks.length <= DEFAULT_BLOCK_COUNT)
            return comment.content.blocks;

        let blocks = [];
        if (this.state.allBlocks) {
            if (this.state.showFullComment)
                blocks = this.state.allBlocks.slice(0, this.state.allBlocks.length);
            else
                blocks = this.state.allBlocks.slice(0, DEFAULT_BLOCK_COUNT);
        }

        return blocks;
    };

    handleShowMoreClick = () => {
        this.setState((prevState, props) => ({
            showFullComment: !prevState.showFullComment
        }));
    };

    checkIfLoggedIn = () => {
        if (!this.props.me || !this.props.me.isLoggedIn) {
            this.props.toggleLoginModal();
            return false;
        }

        return true;
    }

    handleDeleteClick = () => {
        if (!this.checkIfLoggedIn())
            return;

        this.setState({
            modalIsOpen: true
        });
    };

    handleModalCloseDelete = (isConfirmed) => {
        if (isConfirmed && this.props.comment.id) {
            this.setState({isLoadingCommentUpdate: true});

            this.props.deleteComment(this.props.comment.id)
                .then((resp) => {
                    this.setState({isLoadingCommentUpdate: false});
                    return resp;
                });
        }

        this.setState({
            modalIsOpen: false
        });
    };

    handleReplyClick = (isReplying) => {
        if (this.props.checkIfCanEditOrReply(isReplying)) {
            this.setState({
                isReplying: isReplying
            });
        }
    };

    handleEditClick = () => {
        if (!this.checkIfLoggedIn())
            return;

        if (this.props.checkIfCanEditOrReply(true)) {
            this.setState({
                isReadonly: false,
                showFullComment: true,
                forceContentUpdate: true
            });
        }
    };

    setForceContentUpdate = (newValue) => {
        this.setState({
            forceContentUpdate: !newValue
        })
    };

    renderControls = (content, isDeleted) => {
        const canEdit = this.props.canEdit;
        const buttonText = this.state.showFullComment ? "Show less" : "Show more";
        const buttonClassNames = `comment__controls-button ${this.state.isLoadingCommentUpdate ? 'comment__controls-button--disabled' : ''}`;
        return (
            <div className='comment__controls'>
                <div>
                    {
                        !isDeleted && content && this.state.allBlocks.length > DEFAULT_BLOCK_COUNT && this.state.allBlocks ?
                            <button className="comment__controls-stripped-button comment__controls-read-more" onClick={this.handleShowMoreClick}>{buttonText}</button>
                            : null
                    }
                </div>
                <div className='comment__controls-section'>
                    {this.renderCommentControlsSection(buttonClassNames, canEdit, isDeleted)}
                </div>
            </div>)
    };

    renderCommentControlsSection = (buttonClassNames, canEdit, isDeleted) => {
        if (this.props.showGoToPostButton) {
            return (
                <Link className={`${buttonClassNames} comment__controls-button-link comment__controls-button--fixed`} title='Go to Post' to={`${constants.pagePaths.viewPost}/${this.props.comment.postId}#${this.props.comment.id}`}>
                    <i className="fas fa-folder"></i>
                </Link>
            )
        }

        return (
            <>
                <button className={buttonClassNames} title={this.state.isReplying ? 'Close Reply' : 'Reply'} onClick={!this.state.isLoadingCommentUpdate ? () => this.handleReplyClick(!this.state.isReplying) : () => {
                }}>
                    {this.state.isReplying ? <i className="fas fa-times"></i> : <i className="fas fa-reply"></i>}</button>
                {
                    isDeleted ? null :
                        <>
                            {canEdit && <button className={buttonClassNames} title='Edit' onClick={!this.state.isLoadingCommentUpdate ? this.handleEditClick : () => {
                            }}><i className="far fa-edit"></i></button>}

                            {canEdit && <button className={buttonClassNames} title='Delete' onClick={!this.state.isLoadingCommentUpdate ? this.handleDeleteClick : () => {
                            }}>
                                {this.state.isLoadingCommentUpdate ?
                                    <LoadingSpinner size={LoadingSpinnerSizes.extraSmall} show={this.state.isLoadingCommentUpdate}/> :
                                    <i className="far fa-trash-alt"></i>
                                }
                            </button>}
                        </>
                }
            </>
        )
    }

    handleSave = (jsonContent) => {
        if (!this.checkIfLoggedIn())
            return;

        this.props.checkIfCanEditOrReply(false);
        return this.props.saveReply(jsonContent)
            .then(() => {
                this.setState({isReplying: false});
            })
    };

    handleUpdate = (jsonContent) => {
        if (!this.checkIfLoggedIn())
            return;

        this.setState({isLoadingCommentUpdate: true});
        this.props.checkIfCanEditOrReply(false);
        return this.props.updateComment(jsonContent, this.props.comment.id)
            .then(resp => {
                this.setState({
                    isLoadingCommentUpdate: false
                });

                return resp;
            });
    };

    setReadonly = (readonly) => {
        if (this.props.checkIfCanEditOrReply(!readonly))
            this.setState({isReadonly: readonly});
    };

    render() {
        let content = '';
        const textOrBlockContent = (this.state.allBlocks || this.props.comment.isDeleted || !this.props.comment.userId) && this.getCommentBlocks(this.props.comment);

        if (!this.props.comment.isDeleted && this.props.comment.userId)
            content = {
                blocks: textOrBlockContent,
                entityMap: this.props.comment.content.entityMap
            };
        else
            content = textOrBlockContent;

        return (
            <>
                {
                    (this.state.allBlocks || this.props.comment.isDeleted || !this.props.comment.userId) ? (
                        <>
                            <WYSIWYG
                                isLoading={this.state.isLoadingCommentUpdate}
                                readonly={this.state.isReadonly}
                                content={content}
                                mentions={this.props.comment.mentionUsers}
                                saveContent={this.props.comment.id ? ((jsonContent) => this.handleUpdate(jsonContent)) : this.props.saveReply}
                                handleCancelClick={() => this.setReadonly(true)}
                                forceContentUpdate={this.state.forceContentUpdate}
                                forceContentUpdateHandled={() => this.setForceContentUpdate(true)}
                                me={this.props.me}
                                toggleLoginModal={this.props.toggleLoginModal}
                            />

                            {this.state.isReadonly ? this.renderControls(content, this.props.comment.isDeleted || !this.props.comment.userId) : null}

                            {
                                this.state.isReplying ?
                                    <div className={'wysiwyg--indented'}>
                                        <WYSIWYG
                                            readonly={false}
                                            placeholder='Reply to the above comment'
                                            saveContent={this.handleSave}
                                            autoFocus={true}
                                            handleCancelClick={() => this.handleReplyClick(false)}
                                            isLoading={this.state.isLoadingCommentUpdate}
                                            parent={this.props.comment}
                                            isChild={this.props.isChildComment}
                                        />
                                    </div> :
                                    null
                            }
                        </>
                    ) : null
                }
                <ConfirmationModal
                    question="Are you sure you wish to delete this comment?"
                    modalIsOpen={this.state.modalIsOpen}
                    onClose={this.handleModalCloseDelete}
                />
            </>
        )
    }
}

export default Comment;
