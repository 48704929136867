import * as searchActions from "../actions/searchActions"


export default function (
    state = {
        prerequisites:{
            classes: [],
            qualities: [],
            slots: [],
            items: [],
            unusualEffects: []
        }
    },
    action) {
    switch (action.type) {
        case searchActions.SET_SEARCH_PREREQUISITES: {
            return {
                ...state,
                prerequisites: action.payload
            }
        }
        default: {
        }
    }
    return state;
}
