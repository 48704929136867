export const postsApi = {
    upsert: "post",
    submit: "post/submit",
    get: "post",
    getItems: "post/{postId}/items",
    delete: "post",
    scores: {
        get: "post/scores",
        put: "post/{postId}/score/{score}",
        getLogsForPost: "post/{postId}/scores"
    }
};
