import React from 'react';
import {Link} from "react-router-dom";
import {constants} from "../../../utilities/constants";
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import './aboutUs.scss'
import {BecomeAPatronButton} from "../../atoms/buttons/becomeAPatronButton/becomeAPatronButton";


export const AboutUsPage = (props) => {

    const renderSeparator = () => {
        return (<div className='about-us__separator'/>)
    }

    const renderExternalLink = (url, text) => {
        return (<a className='about-us__link' target='_blank' rel='noopener noreferrer' href={url}>{text}</a>)
    }

    const render = () => {
        return (
            <WithTopMenuTemplate>
                <div className='about-us__layout'>
                    <div className='about-us__container'>
                        <h2>About Us</h2>
                        <p>
                            In 2011, a website called TF2Tags was launched.
                            The site allowed its users to create and share their creative Team Fortress 2 (TF2) item names and descriptions.
                            However, in 2018, the original team stopped maintaining and hosting the website.
                        </p>

                        <p>
                            We are a group of people who loved the website and who decided that if it no longer exists, we just have to make a new one!
                        </p>
                        <p>
                            We have been developing TF2Tags on and off for close to a year now during our free time. After all this time and hard work, we are thrilled to present you with this website!
                            At this point, we are very close to making the website available to everyone! We hope you enjoy your stay and join us on this journey.
                            We sincerely hope you enjoy your time using this website and being part of this community, as much as we enjoyed developing it and bringing everyone back together.
                            If you have any suggestions on how we can improve either the website or our social channels, please reach out to us on any of the channels!
                        </p>
                        <p>
                            You can read all about the history of TF2Tags and more on our {renderExternalLink(constants.externalPagePaths.wiki, 'Wiki')}.
                        </p>

                        {renderSeparator()}
                        <h2>Supporting TF2Tags</h2>
                        <p>
                            As with most things in life, developing and hosting a website isn't free.
                            There are several ways for us to cover these costs and the most obvious one is to incorporate advertisements into TF2Tags.
                            However, all of us dislike ads, and we would love to be able to avoid them entirely.
                        </p>

                        <p>
                            This means that we need to find a different method of covering these fees. Because of this, we decided that we could turn to our community for support.
                        </p>

                        <p>
                            That being said, we don't want to just ask you for donations. Instead, we have decided to use Patreon, which allows us to give everyone who supports us various perks!
                            Therefore, if you care about the website or enjoy using it, please consider supporting us either through monthly or a one-off contribution.
                        </p>

                        <BecomeAPatronButton/>

                        <p/>

                        {renderSeparator()}
                        <h2>Stay in Touch</h2>
                        <p>
                            TF2Tags is a community-driven website, and as a result we want to be easy to reach, so here's a list of all the platforms we're on:
                        </p>

                        <div className='about-us__link-container'>{renderExternalLink(constants.externalPagePaths.discord, 'Discord')}, where you can stay in touch with the community, as well as the team behind TF2Tags.</div>
                        <div className='about-us__link-container'>{renderExternalLink(constants.externalPagePaths.twitter, 'Twitter')}, where you can see all the latest news</div>
                        <div className='about-us__link-container'>{renderExternalLink(constants.externalPagePaths.patreon, 'Patreon')}, where you can support us and get sneak-peaks into new features and the development process, as well as get various perks.</div>
                        <div className='about-us__link-container'>{renderExternalLink(constants.externalPagePaths.steamGroup, 'Steam Group')}, where you can find out more about the TF2Tags history and community, as well as contribute to it.</div>
                        <div className='about-us__link-container'>{renderExternalLink(constants.externalPagePaths.wiki, 'Wiki')}, where you can find out more about the TF2Tags history and community, as well as contribute to it.</div>

                        <p/> {/* for spacing */}

                        {renderSeparator()}
                        <h2>Policies</h2>
                        <p>
                            Make sure to read through our various policies: <Link className='about-us__link' to={constants.pagePaths.policyPage}>Policies</Link>
                        </p>
                    </div>
                </div>
            </WithTopMenuTemplate>
        )
    }

    return render();
}
