import React from 'react';
import './postSkeleton.scss'

export const PostSkeleton = (props) => {
    return (
        <div className='post-skeleton__container' style={{marginTop: props.marginTop}}>
            <div className='post-skeleton__item'>
                <div className='post-skeleton__image-container'>
                    <div className='post-skeleton__image'/>
                </div>
                <div className='post-skeleton__text'>
                    <div className='post-skeleton__name'/>
                    <div className='post-skeleton__description'/>
                </div>
            </div>
            <div className='post-skeleton__details'>
                <div className='post-skeleton__details-text'/>
                <div className='post-skeleton__details-text'/>
                <div className='post-skeleton__details-text'/>
            </div>
        </div>
    )
}
