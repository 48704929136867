import React, {Component} from "react";
import {connect} from "react-redux";
import * as authenticationActions from "../../../redux/actions/authenticationActions";
import * as generalActions from "../../../redux/actions/generalAction";
import {constants} from "../../../utilities/constants";
import {notificationActions} from "../../../redux/actions/notificationActions";
import * as Sentry from '@sentry/browser';

class Startup extends Component {
    componentDidMount() {
        this.props.dispatch(authenticationActions.getMe())
            .then(() => {
                const me = this.props.me;
                if (me) {
                    Sentry.configureScope(function (scope) {
                        scope.setUser({"id": me.id, "username": me.username});
                    });
                }
                this.props.dispatch(notificationActions.getNotifications());
            });
        this.props.dispatch(generalActions.getAllTf2ItemQualities());
    }

    render() {
        const isOnBannedPage = window.location.href.includes(constants.pagePaths.bannedPage);
        if (this.props.me && this.props.me.isBanned && !isOnBannedPage) {
            window.location.href = `${constants.pagePaths.bannedPage}`;
        }

        return (
            <>
                {this.props.children}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

const mapStateToProps = (state) => {
    return {
        me: state.authentication.me
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Startup);
