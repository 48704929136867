import React, {Component} from "react";
import {BasicModal, ModalSizes} from "./basicModal";
import {authenticationService} from "../../../api/services/authenticationService";
import '../../../style/other/loginWithSteamIcons/loginWithSteamIcons.scss';

class LoginModal extends Component {

    static defaultProps = {
        modalIsOpen: false,
        handleLoginButtonClick: () => {
        },
        message: ""
    };


    redirectToSteam = () => {
        this.props.handleLoginButtonClick();
        authenticationService.redirectToLoginWithSteam();
    }

    render() {
        return (
            <BasicModal
                modalIsOpen={this.props.modalIsOpen}
                onClose={this.props.onClose}
                modalSize={ModalSizes.medium}
            >
                <>
                    <div className="modal__header">
                        <button type="button" onClick={this.props.onClose} className="modal__button-close">&times;</button>
                    </div>
                    <div>{this.props.message}</div>
                    <div className="modal__button-container">
                        <div onClick={this.redirectToSteam} title="Sign in through Steam" className="steam-login steam-login__tall--border"/>
                    </div>
                </>
            </BasicModal>
        )
    }
}

export default LoginModal;
