import {api, ENDPOINTS} from "../../api/api";
import {toast} from "react-toastify";

export const SET_ME = "SET_ME";


export const getMe = () => (dispatch) => {
    return api.get(`${ENDPOINTS.user.me}`)
        .then(resp => {
            // eslint-disable-next-line no-undef
            if (window.heap) {
                // eslint-disable-next-line no-undef
                window.heap.identify(resp.id); //Identity the user for Heap
            }

            return dispatch({
                type: SET_ME,
                payload: resp.data
            });
        }).catch(error => {
            toast.error(`Failed to get the user`)
            console.error("Error getting items: ", error);
        })
};
