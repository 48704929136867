import React, {Component} from "react";

import "./commentSection.scss"
import WYSIWYG from "../WYSIWYG/editor/WYSIWYG";
import {commentService} from "../../../../../../api/services/commentService";
import CommentContainer from "../comment/commentContainer";
import {toast} from "react-toastify";
import {scroller} from "react-scroll";

class CommentSection extends Component {
    constructor(props) {
        super(props);

        this.topWysiwygRef = React.createRef();

        this.state = {
            comments: [],
            savingComment: false,
            isEditingOrReplying: false
        };
    }

    static defaultProps = {
        postId: null,
        me: {},
        toggleLoginModal: () => {
        },
        commentAnchor: '',
        show: true
    };

    componentDidMount() {
        if (this.props.postId > 0)
            this.fetchComments(this.props.postId, true);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.postId !== prevProps.postId) {
            this.fetchComments(this.props.postId);
        }

        if (prevProps.commentAnchor !== this.props.commentAnchor) {
            scroller.scrollTo(`comment-${this.props.commentAnchor}`, {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -80,
            });
        }
    };

    fetchComments = (postId, scrollToComment) => {
        if (postId > 0) {
            commentService.getCommentsForPost(this.props.postId)
                .then(resp => {
                    for (let i = 0; i < resp.length; i++) {
                        if (this.props.me && this.props.me.isLoggedIn)
                            resp[i].canEdit = resp[i].userId === this.props.me.id;
                        else
                            resp[i].canEdit = false;

                        for (let j = 0; j < resp[i].childComments.length; j++) {
                            if (this.props.me && this.props.me.isLoggedIn)
                                resp[i].childComments[j].canEdit = resp[i].childComments[j].userId === this.props.me.id;
                            else
                                resp[i].childComments[j].canEdit = false;
                        }
                    }

                    this.setState({
                        comments: resp
                    })

                    if (scrollToComment) {
                        scroller.scrollTo(`comment-${this.props.commentAnchor}`, {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: -80,
                        });
                    }
                })
        }
    };

    checkIfLoggedIn = () => {
        if (!this.props.me || !this.props.me.isLoggedIn) {
            this.props.toggleLoginModal();
            return false;
        }

        return true;
    }

    handleWysiwygClick = (event) => {
        if (!this.props.me || !this.props.me.isLoggedIn) {
            event.preventDefault();
            this.topWysiwygRef.current.editor.blur();
            this.props.toggleLoginModal(true);
        }
    }

    saveComment = (jsonContent, parentCommentId) => {
        if (!this.checkIfLoggedIn()) {
            return new Promise((resolve, reject) => resolve(false));
        }

        this.setState({savingComment: true});
        return commentService.createComment({
            postId: this.props.postId,
            content: jsonContent.content,
            mentionUserIds: jsonContent.mentionUserIds,
            parentCommentId: parentCommentId
        }).then(resp => {
            if (!resp) {
                this.setState({
                    savingComment: false
                });
                return false;
            }

            this.setState({savingComment: false});
            this.fetchComments(this.props.postId);

            return true;
        });
    };

    deleteComment = (commentId) => {
        return commentService.deleteComment(commentId)
            .then((resp) => {
                if (!resp)
                    return false;

                const currentComments = [...this.state.comments];
                const commentToUpdate = this.getCommentToUpdate(currentComments, commentId);

                commentToUpdate.isDeleted = true;
                commentToUpdate.content = resp.content;
                commentToUpdate.userId = resp.userId;

                this.setState({
                    comments: currentComments
                });

                return true;
            });
    };

    updateComment = (jsonContent, commentId) => {
        return commentService.updateComment({
            commentId: commentId,
            postId: this.props.postId,
            content: jsonContent.content,
            mentionUserIds: jsonContent.mentionUserIds,
        }).then(resp => {
            if (!resp)
                return false;

            const commentContent = JSON.parse(jsonContent.content);
            const currentComments = [...this.state.comments];
            const commentToUpdate = this.getCommentToUpdate(currentComments, commentId);

            commentToUpdate.content = commentContent;

            this.setState({
                comments: currentComments
            });

            return true;
        });
    };

    getCommentToUpdate = (currentComments, commentId) => {
        let commentToUpdate = null;
        for (let i = 0; i < currentComments.length; i++) {
            if (currentComments[i].id === commentId) {
                commentToUpdate = currentComments[i];
                break;
            }

            for (let j = 0; j < currentComments[i].childComments.length; j++) {
                if (currentComments[i].childComments[j].id === commentId) {
                    commentToUpdate = currentComments[i].childComments[j];
                    break;
                }
            }
        }

        return commentToUpdate;
    };

    checkIfCanEditOrReply = (IsOpeningEditOrReply) => {
        if (!this.checkIfLoggedIn())
            return false;

        if (this.state.isEditingOrReplying && IsOpeningEditOrReply) {
            toast.info("You can only edit or reply to 1 comment at a time");
            return false;
        }

        if (!this.state.isEditingOrReplying && IsOpeningEditOrReply) {
            this.setState({isEditingOrReplying: true});
            return true;
        }

        this.setState({isEditingOrReplying: false});
        return true;
    };

    render() {
        if(!this.props.show)
            return null;

        return (
            <div className="comment-section__container">
                <div className="comment-section__wysiwyg-container" onClick={this.handleWysiwygClick}>
                    <WYSIWYG
                        ref={this.topWysiwygRef}
                        isLoading={this.state.savingComment}
                        saveContent={this.saveComment}
                        me={this.props.me}
                    />
                </div>
                {this.state.comments.map((comment) =>
                    <CommentContainer
                        key={comment.id}
                        comment={comment}
                        postId={this.props.postId}
                        saveReply={this.saveComment}
                        updateComment={this.updateComment}
                        topLevelCommentId={comment.id}
                        checkIfCanEditOrReply={this.checkIfCanEditOrReply}
                        deleteComment={this.deleteComment}
                        me={this.props.me}
                        toggleLoginModal={this.props.toggleLoginModal}
                        commentAnchor={this.props.commentAnchor}
                    />
                )}
            </div>
        )
    }
}

export default CommentSection;
