import React, {Component} from "react";
import BottomNavButton from "../../../../atoms/buttons/itemEdit/bottomNavButton";
import ItemCounter from "../../../../atoms/itemCounter";
import BottomBar from "../../../../molecules/bottomBar/bottomBar";
import {Link} from "react-router-dom";
import {breakpoints} from "../../../../../utilities/breakpoints";

class CreatePageBottomBar extends Component {
    static defaultProps = {
        postItemCount: 0,
    }

    render() {
        const isMobile = breakpoints.isLessThan1200();
        return (
            <BottomBar>
                <ItemCounter itemCount={this.props.postItemCount}/>
                {
                    isMobile ?
                        <Link className='bottom-bar__menu-button' to='/create/post' onClick={this.checkIfDisabled}>Back</Link>
                        :
                        <div className="bottom-bar-options">
                            <BottomNavButton linkUrl="/create/post">Back</BottomNavButton>
                        </div>
                }
            </BottomBar>
        )
    }
}

export default CreatePageBottomBar;
