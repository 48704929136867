import React, {Component} from "react"
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate"

import './homepage.scss';
import {connect} from "react-redux";
import {PostsInfiniteScroll} from "../../organisms/postsInfiniteScroll/postsInfiniteScroll";
import {scoreHelpers} from "../../../utilities/scoreHelpers";
import {postsService} from "../../../api/services/postsService";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            pageCounter: 1,
            pageLoadDateTime: new Date(),
            showModal: false,
            hasMore: true
        }
    }

    componentDidMount() {
        this.fetchPosts(1);
    }

    fetchPosts = (pageNumber) => {
        postsService.fetchPostsForHomepage(pageNumber, this.state.pageLoadDateTime)
            .then((resp) => {
                this.setState({
                    posts: this.state.posts.concat(resp.data.posts),
                    hasMore: resp.data.hasMore,
                    pageCounter: pageNumber
                })
            })
    };

    fetchNextPosts = () => {
        this.fetchPosts(this.state.pageCounter + 1);
    }

    updateLocalPostScore = (newScore, postId) => {
        const existingPosts = [...this.state.posts];
        const posts = scoreHelpers.updateLocalPostScore(newScore, postId, existingPosts);

        this.setState({
            posts: posts
        });
    }

    render() {
        return (
            <>
                <WithTopMenuTemplate>
                    {
                        this.state.posts.length === 0 &&
                        <div className='post-skeleton__layout'>
                            <PostSkeleton/>
                            <PostSkeleton marginTop='20px'/>
                            <PostSkeleton marginTop='20px'/>
                            <PostSkeleton marginTop='20px'/>
                            <PostSkeleton marginTop='20px'/>
                        </div>
                    }
                    {
                        this.state.posts.length > 0 &&
                        <PostsInfiniteScroll
                            posts={this.state.posts}
                            updateLocalPostScore={this.updateLocalPostScore}
                            fetchNextPosts={this.fetchNextPosts}
                            hasMore={this.state.hasMore}
                            me={this.props.me}
                            endOfPostsMessage="Congratulations, you've seen every single post! Help us grow our collection by creating more"
                        />
                    }
                </WithTopMenuTemplate>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        me: state.authentication.me
    };
};

export default connect(mapStateToProps, null)(HomePage);

