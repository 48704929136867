import React from 'react';

import './mentions.module.scss';

const MentionEntry = (props) => {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    return (
        <div {...parentProps}>
            <div className={theme.mentionSuggestionsEntryContainer}>
                <div className={theme.mentionSuggestionsEntryText}>
                    {mention.name}
                </div>
            </div>
        </div>
    );
};

export default MentionEntry;
