import React, {Component} from "react";
import ItemDetailsEdit from "../atoms/itemDetailsEdit";
import ItemQualitiesSection from "../molecules/sections/itemQualitiesSection";
import ItemStrangePrefixesSection from "../molecules/sections/itemStrangePrefixesSection";
import ItemEditOptionsRow from "../molecules/rows/itemEditOptionsRow";
import ItemUnusualEffectsSection from "../molecules/sections/itemUnusualEffectSection";
import {breakpoints} from "../../utilities/breakpoints";

class CreateItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedAction: "",
            displayEditingArea: false,
        }
    }

    static defaultProps = {
        isLoading: false,
        item: {},
        isReordering: false,
        postItemCount: 0
    }

    setSelectedAction = (action) => {
        if (action === this.state.selectedAction)
            action = "";

        this.setState({
            selectedAction: action
        });
    }

    toggleDisplayEditingArea = () => {
        this.setState((prevState) => {
            return {
                displayEditingArea: !prevState.displayEditingArea
            }
        });
    }

    render() {
        const isMobile = breakpoints.isLessThan750();

        return (
            <>
                <ItemDetailsEdit
                    item={this.props.item}
                    toggleDisplayState={this.toggleDisplayEditingArea}
                    displayState={this.state.displayEditingArea}
                    isReordering={this.props.isReordering}
                    isLoading={this.props.isLoading}
                />
                {
                    (this.state.displayEditingArea || isMobile) && !this.props.isReordering &&
                    <>
                        <ItemEditOptionsRow
                            item={this.props.item}
                            selectedAction={this.state.selectedAction}
                            setAction={this.setSelectedAction}
                            isLoading={this.props.isLoading}
                            postItemCount={this.props.postItemCount}
                        />

                        <ItemQualitiesSection
                            selectedAction={this.state.selectedAction}
                            isLoading={this.props.isLoading}
                            item={this.props.item}
                        />

                        <ItemStrangePrefixesSection
                            selectedAction={this.state.selectedAction}
                            item={this.props.item}
                            isLoading={this.props.isLoading}
                        />

                        <ItemUnusualEffectsSection
                            selectedAction={this.state.selectedAction}
                            item={this.props.item}
                            isLoading={this.props.isLoading}
                        />
                    </>
                }
            </>
        );
    }
}

export default CreateItem;
