import React, {useState} from 'react'
import {BasicModal, ModalSizes} from "../basicModal";
import './banModal.scss'
import {api, ENDPOINTS} from "../../../../api/api";
import {toast} from "react-toastify";

export const BanModal = (props) => {
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [proof, setProof] = useState('');
    const [banReason, setBanReason] = useState(1);

    const banUser = (event) => {
        event.preventDefault();

        const confirmation = window.confirm('Are you absolutely sure you wish to ban this user?');
        if (!confirmation)
            return;

        const banRequest = {
            banReasonId: parseInt(banReason),
            additionalDetails: additionalDetails,
            proof: proof
        };

        api.post(ENDPOINTS.user.ban.replace('{userId}', props.userId), banRequest)
            .then(resp => {
                toast.success('Successfully banned the User');
                props.onClose();
            })
            .catch(ex => {
                toast.error('Failed to ban the User');
                console.error('Failed to ban the User: ', ex)
            })
    }

    return (
        <BasicModal
            modalIsOpen={props.modalIsOpen}
            onClose={props.onClose}
            modalSize={ModalSizes.large}
            centerContent={false}
        >
            <form className='ban-modal__form' onSubmit={banUser}>
                <label htmlFor='banReasonSelect'>Ban Reason</label>
                <select id={'banReasonSelect'} value={banReason} onChange={(event) => setBanReason(event.target.value)}>
                    {
                        props.banReasons.map(banReasonOption =>
                            <option key={banReasonOption.id} value={banReasonOption.id}>{banReasonOption.banReason}</option>
                        )
                    }
                </select>

                <label htmlFor='additionalDetailsInput' className='ban-modal__label'>Additional Details</label>
                <textarea
                    id='additionalDetailsInput'
                    className='ban-modal__input'
                    minLength={10}
                    maxLength={1000}
                    required
                    value={additionalDetails}
                    onChange={(event) => setAdditionalDetails(event.target.value)}
                />

                <label htmlFor='proofInput' className='ban-modal__label'>Proof (links preferred)</label>
                <textarea
                    id='proofInput'
                    className='ban-modal__input'
                    minLength={10}
                    maxLength={1000}
                    required
                    value={proof}
                    onChange={(event) => setProof(event.target.value)}
                />

                <div className='ban-modal__buttons'>
                    <button type='submit' value='Submit'>Submit</button>
                    <button type='button' onClick={props.onClose}>Cancel</button>
                </div>
            </form>
        </BasicModal>
    )
}

BanModal.defaultProps = {
    modalIsOpen: false,
    onClose: () => {
    },
    banReasons: [],
    userId: null
}
