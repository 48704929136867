import {api, ENDPOINTS} from "../../api/api";
import {toast} from "react-toastify";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

const getNotifications = () => (dispatch, getState) => {
    const state = getState();
    
    if (!state.authentication.me || !state.authentication.me.isLoggedIn)
        return;

    api.get(ENDPOINTS.notifications.getForUser)
        .then(resp => {
            let notifications = resp.data.commentNotifications;
            notifications = notifications.concat(resp.data.postScoreNotifications);

            notifications.sort(x => x.createdDateTime).reverse();

            updateNotifications(notifications)(dispatch);
        }).catch(error => {
        toast.error(`Failed to get notifications`)
        console.error("Error getting notifications: ", error);
    })

    setTimeout(() => getNotifications()(dispatch, getState), 1000 * 60 * 1) // 1 min interval
};

const updateNotifications  = (notifications) => (dispatch) => {
    dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications
    })
}

export const notificationActions = {
    getNotifications,
    updateNotifications
}
