import React, {useEffect, useRef, useState} from "react";
import NavbarButton from "../../atoms/buttons/navbarButton";
import {Notifications} from "../../organisms/notifications/notifications";
import {useSelector, useDispatch} from "react-redux";
import {constants} from "../../../utilities/constants";
import {authenticationService} from "../../../api/services/authenticationService";
import {notificationActions} from "../../../redux/actions/notificationActions";
import {breakpoints} from "../../../utilities/breakpoints";
import Menu from 'react-burger-menu/lib/menus/slide'
import './topBar.scss';
import {Link} from "react-router-dom";
import './burgerMenu.scss'
import {BecomeAPatronButton} from "../../atoms/buttons/becomeAPatronButton/becomeAPatronButton";

function DetectOutsideClicks(notificationsRef, buttonRef, resolveOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)
                && buttonRef.current && !buttonRef.current.contains(event.target)) {
                resolveOutsideClick();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notificationsRef, buttonRef]);
}


export const TopBar = (props) => {
    const me = useSelector(state => state.authentication.me);
    const postInProgress = useSelector(state => state.post);
    const dispatch = useDispatch();

    const [showNotifications, setShowNotifications] = useState(false);

    const notifications = useSelector(state => state.notifications.notifications);
    const [notificationsState, setNotificationsState] = useState([]);

    const notificationsRef = useRef(null);
    const notificationsButtonRef = useRef(null);
    DetectOutsideClicks(notificationsRef, notificationsButtonRef, () => setShowNotifications(false));

    useEffect(() => {
        if (!showNotifications) {
            if (notifications) {
                setNotificationsState(notifications);
            }
        }
    }, [notifications])

    const updateNotifications = (newNotifications) => {
        setNotificationsState(newNotifications);
        dispatch(notificationActions.updateNotifications(newNotifications));
    }

    const renderDesktopMenu = (createPageUrl) => {
        const notificationsCount = notificationsState && notificationsState.length > 0 ? ` (${notificationsState.length})` : '';

        return (
            <>
                <header className='header-bar'>
                    <div className='header-bar__section'>
                        <NavbarButton linkUrl="/">
                            <div className='tf2tags-logo tf2tags-logo__large'/>
                            &nbsp;HOME
                        </NavbarButton>
                        <NavbarButton linkUrl={createPageUrl}><i className="fas fa-wrench"></i>&nbsp;CREATE</NavbarButton>
                        <NavbarButton linkUrl={constants.pagePaths.search}><i className="fas fa-search"></i>&nbsp;SEARCH</NavbarButton>
                    </div>
                    <div className='header-bar__section' style={{marginRight: '50px'}}>
                        {
                            me && me.isLoggedIn ?
                                <>
                                    <NavbarButton
                                        ref={notificationsButtonRef}
                                        buttonClicked={() => setShowNotifications(!showNotifications)}
                                    >
                                        <i className="fas fa-exclamation-triangle"></i>
                                        &nbsp;{`Notifications${notificationsCount}`}
                                    </NavbarButton>
                                    <NavbarButton linkUrl={`${constants.pagePaths.profile}/${me.id}`}>
                                        <img className='header-bar__avatar' src={me.avatarMedium} alt='Profile'/>
                                        &nbsp;{me.username}
                                    </NavbarButton>
                                </>
                                :
                                <div title="Sign in through Steam" className="steam-login steam-login__tall--border" onClick={authenticationService.redirectToLoginWithSteam}/>
                        }

                    </div>
                    {
                        showNotifications &&
                        <Notifications
                            notificationsState={notificationsState}
                            setNotificationsState={updateNotifications}
                            ref={notificationsRef}
                        />
                    }
                </header>

                <Menu right width={'20%'} disableAutoFocus>
                    <NavbarButton linkUrl={constants.pagePaths.leaderboards} inSideMenu={true}><i className="fas fa-list-ol"></i>&nbsp;LEADERBOARDS</NavbarButton>
                    <NavbarButton linkUrl={constants.pagePaths.aboutUs} inSideMenu={true}><i className="fas fa-address-card"></i>&nbsp;ABOUT US</NavbarButton>
                    <NavbarButton linkUrl={constants.pagePaths.policyPage} inSideMenu={true}><i className="fas fa-file-alt"></i>&nbsp;POLICIES</NavbarButton>

                    <BecomeAPatronButton style={{marginTop: 'auto', marginBottom: '20px'}}/>
                </Menu>
            </>
        );
    }

    const renderMobileMenu = (createPageUrl) => {
        const notificationsCount = notificationsState && notificationsState.length > 0 ? `${notificationsState.length}` : '';

        return (
            <>
                <header className='header-bar'>
                    <Link className='top-bar__logo-container' to='/'>
                        <div className='tf2tags-logo tf2tags-logo__large'/>
                        &nbsp;TF2Tags
                    </Link>

                    {
                        me && me.isLoggedIn &&
                        <div
                            className='top-bar__mobile-button'
                            ref={notificationsButtonRef}
                            onClick={() => setShowNotifications(!showNotifications)}
                        >
                            <i className="fas fa-exclamation-triangle"></i>
                            {notificationsCount && <span>&nbsp;{notificationsCount}</span>}
                        </div>
                    }

                </header>
                <Menu right width={'80%'} disableAutoFocus>
                    <NavbarButton linkUrl="/">
                        <div className='tf2tags-logo tf2tags-logo__large'/>
                        &nbsp;HOME
                    </NavbarButton>
                    <NavbarButton linkUrl={createPageUrl}><i className="fas fa-wrench"></i>&nbsp;CREATE</NavbarButton>
                    <NavbarButton linkUrl={constants.pagePaths.search}><i className="fas fa-search"></i>&nbsp;SEARCH</NavbarButton>
                    <NavbarButton linkUrl={constants.pagePaths.leaderboards}><i className="fas fa-list-ol"></i>&nbsp;LEADERBOARDS</NavbarButton>
                    <NavbarButton linkUrl={constants.pagePaths.aboutUs}><i className="fas fa-address-card"></i>&nbsp;ABOUT US</NavbarButton>
                    {
                        me && me.isLoggedIn ?
                            <>
                                <NavbarButton linkUrl={`${constants.pagePaths.profile}/${me.id}`}>
                                    <img className='header-bar__avatar' src={me.avatarMedium} alt='Profile'/>
                                    &nbsp;{me.username}
                                </NavbarButton>
                            </>
                            :
                            <div title="Sign in through Steam" className="steam-login steam-login__long" onClick={authenticationService.redirectToLoginWithSteam}/>
                    }
                    {
                        showNotifications &&
                        <Notifications
                            notificationsState={notificationsState}
                            setNotificationsState={updateNotifications}
                            ref={notificationsRef}
                        />
                    }

                    <BecomeAPatronButton style={{marginTop: 'auto', marginBottom: '20px'}}/>
                </Menu>
            </>
        )
    }

    const render = () => {
        const isMobile = breakpoints.isLessThan900();

        const createPageUrl = postInProgress && postInProgress.items.length > 0 ?
            (postInProgress.id && postInProgress.id > 0 ? `${constants.pagePaths.createPost}/${postInProgress.id}` : constants.pagePaths.createPost)
            : constants.pagePaths.createBrowse;

        if (isMobile)
            return renderMobileMenu(createPageUrl);
        else
            return renderDesktopMenu(createPageUrl);
    }

    return render();
}
