import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {nameDescriptionHelper} from '../../../../utilities/nameDescriptionHelper.js';
import {searchActions} from "../../../../redux/actions/searchActions";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../../atoms/loading-spinner/loadingSpinner";
import {MultiSelectWrapper} from "../../../atoms/select/multiSelectWrapper";
import {SingleSelectWrapper} from "../../../atoms/select/singleSelectWrapper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../datepicker.scss";
import '../searchPage.scss';
import {SingleSelectLargeWrapper} from "../../../atoms/select/singleSelectLargeWrapper";
import {toast} from "react-toastify";

export const PostSearch = (props) => {
    const dispatch = useDispatch();

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [includeSets, setIncludeSets] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);

    const [qualities, setQualities] = useState([]);
    const [tf2Classes, setTf2Classes] = useState([]);
    const [slots, setSlots] = useState([]);
    const [unusualEffects, setUnusualEffects] = useState([]);

    const [authorUsername, setAuthorUsername] = useState("");
    const [authorTagsId, setAuthorTagsId] = useState("");
    const [authorSteamId, setAuthorSteamId] = useState("");

    const [setMaxLength, setSetMaxLength] = useState("");
    const [setMinLength, setSetMinLength] = useState("");
    const [commentMaxCount, setCommentMaxCount] = useState("");
    const [commentMinCount, setCommentMinCount] = useState("");
    const [scoreMax, setScoreMax] = useState("");
    const [scoreMin, setScoreMin] = useState("");

    const [submittedAfter, setSubmittedAfter] = useState(null);
    const [submittedBefore, setSubmittedBefore] = useState(null);

    const [orderBy, setOrderBy] = useState({value: "submittedDateTime", label: "Submitted Time"});
    const [orderDirection, setOrderDirection] = useState({value: "descending", label: "Descending"});

    const [optionsAreSet, setOptionsAreSet] = useState(false);
    const [qualityOptions, setQualityOptions] = useState([]);
    const [unusualEffectOptions, setUnusualEffectOptions] = useState([]);
    const [slotOptions, setSlotOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [yesNoOptions, setYesNoOptions] = useState([]);
    const [orderByOptions, setOrderByOptions] = useState([]);
    const [orderDirectionOptions, setOrderDirectionOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);

    const unusualEffectsAll = useSelector(state => state.search.prerequisites.unusualEffects)
    const itemQualities = useSelector(state => state.search.prerequisites.qualities)
    const classes = useSelector(state => state.search.prerequisites.classes)
    const itemSlots = useSelector(state => state.search.prerequisites.slots)
    const items = useSelector(state => state.search.prerequisites.items)

    const initOptions = () => {
        setQualityOptionsInternal();
        setUnusualEffectOptionsInternal();
        setSlotOptionsInternal();
        setClassOptionsInternal();
        setYesNoOptionsInternal();
        setOrderByOptionsInternal();
        setOrderDirectionOptionsInternal();
        setItemOptionsInternal();

        setOptionsAreSet(true);
    }

    useEffect(() => {
        const searchRequest = {
            includeSets: includeSets.value === "yes",
            item: {
                nameContains: name,
                descriptionContains: description,
                itemId: selectedItem !== null ? parseInt(selectedItem.value) : null
            },
            itemMeta: {
                qualityIds: qualities.length === 1 && qualities[0].value === "*" ? itemQualities.map(x => x.id) : qualities.map(x => parseInt(x.value)),
                classIds: tf2Classes.length === 1 && tf2Classes[0].value === "*" ? classes.map(x => x.id) : tf2Classes.map(x => parseInt(x.value)),
                slotNames: slots.length === 1 && slots[0].value === "*" ? itemSlots : slots.map(x => x.value),
                unusualEffectIds: unusualEffects.length === 1 && unusualEffects[0].value === "*" ?  [] : unusualEffects.map(x => parseInt(x.value))
            },
            author: {
                username: authorUsername !== "" ? authorUsername : null,
                tagsTfId: authorTagsId !== "" ? authorTagsId : null,
                steamId: authorSteamId !== "" ? authorSteamId : null
            },
            setItemThreshold: {
                min: setMinLength === "" ? null : parseInt(setMinLength),
                max: setMaxLength === "" ? null : parseInt(setMaxLength)
            },
            setScoreThreshold: {
                min: scoreMin === "" ? null : parseInt(scoreMin),
                max: scoreMax === "" ? null : parseInt(scoreMax)
            },
            setCommentThreshold: {
                min: commentMinCount === "" ? null : parseInt(commentMinCount),
                max: commentMaxCount === "" ? null : parseInt(commentMaxCount)
            },
            createdThreshold: {
                min: submittedAfter && submittedAfter.toJSON(),
                max: submittedBefore && submittedBefore.toJSON()
            },
            order: {
                orderBy: orderBy.value,
                direction: orderDirection.value
            }
        }

        props.onChange(searchRequest);

    }, [name, description, authorUsername, authorTagsId, authorSteamId,
        qualities, slots, tf2Classes, includeSets, unusualEffects,
        setMinLength, setMaxLength, scoreMin, scoreMax, commentMinCount, commentMaxCount, submittedAfter, submittedBefore,
        orderBy, orderDirection, selectedItem
    ]);

    useEffect(() => {
        initOptions();
        if (!itemQualities || itemQualities.length < 1 || !classes || classes.length < 1 || !itemSlots || itemSlots.length < 1 || !unusualEffectsAll || unusualEffectsAll.length < 1)
            dispatch(searchActions.getSearchPrerequisites());
    }, []);

    useEffect(() => {
        setQualityOptionsInternal();
        setSlotOptionsInternal();
        setClassOptionsInternal();
        setItemOptionsInternal();
        setUnusualEffectOptionsInternal();
    }, [itemQualities, classes, itemSlots, items, unusualEffectsAll])

    const setItemOptionsInternal = () => {
        const result = [];

        if (!items) {
            setItemOptions(result);
            return;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            result.push({value: item.id, label: nameDescriptionHelper.capitalizeFirstLetter(item.name)})
        }

        setItemOptions(result);
    }

    const setQualityOptionsInternal = () => {
        const defaultOption = [{value: "*", label: "Any"}];
        setQualities(defaultOption);
        const result = [defaultOption[0]];

        if (!itemQualities) {
            setQualityOptions(result);
            return;
        }

        for (let i = 0; i < itemQualities.length; i++) {
            const quality = itemQualities[i];
            result.push({value: quality.id, label: nameDescriptionHelper.capitalizeFirstLetter(quality.name)})
        }

        setQualityOptions(result);
    }

    const setUnusualEffectOptionsInternal = () => {
        const defaultOption = [{value: "*", label: "Any"}];
        setUnusualEffects(defaultOption);
        const result = [defaultOption[0]];

        if (!unusualEffectsAll) {
            setUnusualEffectOptions(result);
            return;
        }

        for (let i = 0; i < unusualEffectsAll.length; i++) {
            const effect = unusualEffectsAll[i];
            result.push({value: effect.id, label: effect.name})
        }

        setUnusualEffectOptions(result);
    }

    const setSlotOptionsInternal = () => {
        const defaultOption = [{value: "*", label: "Any"}];
        setSlots(defaultOption);
        const result = [defaultOption[0]];

        if (!itemSlots) {
            setSlotOptions(result);
            return;
        }

        for (let i = 0; i < itemSlots.length; i++) {
            const slot = itemSlots[i];
            result.push({value: slot.toLowerCase(), label: nameDescriptionHelper.capitalizeFirstLetter(slot)})
        }

        setSlotOptions(result);
    }

    const setClassOptionsInternal = () => {
        const defaultOption = [{value: "*", label: "Any"}];
        setTf2Classes(defaultOption);
        const result = [defaultOption[0]];

        if (!itemSlots) {
            setClassOptions(result);
            return;
        }

        for (let i = 0; i < classes.length; i++) {
            const tf2Class = classes[i];
            result.push({value: tf2Class.id, label: nameDescriptionHelper.capitalizeFirstLetter(tf2Class.name)})
        }

        setClassOptions(result);
    }

    const setYesNoOptionsInternal = () => {
        const defaultOption = {value: "no", label: "No"};

        setIncludeSets(defaultOption);
        setYesNoOptions([defaultOption, {value: "yes", label: "Yes"}]);
    }

    const setOrderByOptionsInternal = () => {
        const defaultOption = {value: "submittedDateTime", label: "Submitted Time"};
        const result = [defaultOption];

        result.push({value: "score", label: "Score"});
        result.push({value: "setLength", label: "Set Length"});
        result.push({value: "commentCount", label: "Comment Count"});
        result.push({value: "name", label: "Thumbnail Name"});
        result.push({value: "description", label: "Thumbnail Description"});
        result.push({value: "qualities", label: "Thumbnail Quality"});
        //result.push({value: "class", label: "Class"}); // Removing this option until the order is stored in the db
        result.push({value: "username", label: "Author's Username"});
        result.push({value: "tagsTfId", label: "Author's Tags.Tf ID"});
        result.push({value: "steamId", label: "Author's Steam ID"});

        setOrderByOptions(result);
    }

    const setOrderDirectionOptionsInternal = () => {
        setOrderDirectionOptions(
            [
                {value: "descending", label: "Descending"},
                {value: "ascending", label: "Ascending"}
            ]
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.search();
    }

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const setNumericValue = (value, setMethod) => {
        if (isNumeric(value))
            setMethod(value);
        else
            setMethod("");
    }

    const setLengthInfo = () => {
        if (includeSets.value === "no")
            toast.info("Please 'Include Sets' to specify the 'Set Length'")
    }

    const handleAdvancedOptionsToggle = () => {
        const searchArea = document.getElementsByClassName("search__area")[0];
        if (showAdvancedOptions) {
            searchArea.classList.add("search__area--collapsed");
            searchArea.classList.remove("search__area--expanded");
        } else {
            searchArea.classList.remove("search__area--collapsed");
            searchArea.classList.add("search__area--expanded");
        }

        setShowAdvancedOptions(!showAdvancedOptions);
    }

    const render = () => {
        if (!optionsAreSet) {
            return (
                <LoadingSpinner size={LoadingSpinnerSizes.large} show={true}/>
            );
        }
        
        return (
            <form name="post-search" id="post-search" autoComplete="off" onSubmit={handleSubmit}>
                <div className="search__hero-input-container">
                    <label htmlFor="item">TF2 Item Name</label>
                    <SingleSelectLargeWrapper
                        name="item"
                        id="item"
                        options={itemOptions}
                        onChange={setSelectedItem}
                        className="search__text-input--large"
                        selectedValue={selectedItem}
                    />
                </div>
                <div className='search__advanced-button-container'>
                    <div className='search__advanced-button' onClick={handleAdvancedOptionsToggle}>
                        {showAdvancedOptions ? "Hide" : "Show"} Advanced Options
                    </div>
                </div>
                <div className='search__advanced' hidden={!showAdvancedOptions}>
                    <div className='search'>
                        <div className="search__column">
                            <label htmlFor="tags-name">Name</label> <input id="tags-name" autoComplete="%^&*" className="search__text-input" value={name} onChange={(e) => setName(e.target.value)}/>
                            <label htmlFor="tags-description">Description</label> <input id="tags-description" autoComplete="%^&*" className="search__text-input" value={description} onChange={(e) => setDescription(e.target.value)}/>
                            <label htmlFor="include-sets">Include Sets</label>
                            <SingleSelectWrapper
                                name="include-sets"
                                id="include-sets"
                                options={yesNoOptions}
                                onChange={setIncludeSets}
                                selectedValue={includeSets}
                            />
                        </div>
                        <div className="search__column">
                            <label htmlFor="qualities">Qualities</label>
                            <MultiSelectWrapper
                                name="qualities"
                                id="qualities"
                                options={qualityOptions}
                                onChange={setQualities}
                                selectedValues={qualities}
                            />

                            <label htmlFor="class">Class</label>
                            <MultiSelectWrapper
                                name="class"
                                id="class"
                                options={classOptions}
                                onChange={setTf2Classes}
                                selectedValues={tf2Classes}
                            />
                            
                            <label htmlFor="slot">Slot</label>
                            <MultiSelectWrapper
                                name="slot"
                                id="slot"
                                options={slotOptions}
                                onChange={setSlots}
                                selectedValues={slots}
                            />
                            
                            <label htmlFor="unusual-effects">Unusual Effects</label>
                            <MultiSelectWrapper
                                name="unusual-effects"
                                id="unusual-effects"
                                options={unusualEffectOptions}
                                onChange={setUnusualEffects}
                                selectedValues={unusualEffects}
                            />
                        </div>
                        <div className="search__column">
                            <label htmlFor="tags-username">Author's Username</label> <input id="tags-username" autoComplete="%^&*" className="search__text-input" value={authorUsername} onChange={(e) => setAuthorUsername(e.target.value)}/>
                            <label htmlFor="tags-id">Author's Tags.Tf ID</label> <input id="tags-id" autoComplete="%^&*" className="search__text-input" value={authorTagsId} onChange={(e) => setNumericValue(e.target.value, setAuthorTagsId)}/>
                            <label htmlFor="tags-steam-id">Author's Steam ID</label> <input id="tags-steam-id" autoComplete="%^&*" className="search__text-input" value={authorSteamId} onChange={(e) => setNumericValue(e.target.value, setAuthorSteamId)}/>
                        </div>
                        <div className="search__column">
                            <label htmlFor="set-length-min">Set Length</label>
                            <div onClick={setLengthInfo}>
                                <input id='set-length-min' placeholder="min" maxLength={6} className={`search__text-input--small ${includeSets.value === "no" && 'search__text-input--disabled'}`}
                                       value={setMinLength} onChange={(e) => setNumericValue(e.target.value, setSetMinLength)} disabled={includeSets.value === "no"} onClick={setLengthInfo}/>
                                &nbsp;
                                <input placeholder="max" className={`search__text-input--small ${includeSets.value === "no" && 'search__text-input--disabled'}`} maxLength={6} value={setMaxLength}
                                       onChange={(e) => setNumericValue(e.target.value, setSetMaxLength)} disabled={includeSets.value === "no"} onClick={setLengthInfo}/>
                            </div>

                            <label htmlFor="comment-count-min">Comment Count</label>
                            <div>
                                <input id='comment-count-min' placeholder="min" className="search__text-input--small" maxLength={6} value={commentMinCount} onChange={(e) => setNumericValue(e.target.value, setCommentMinCount)}/>
                                &nbsp;
                                <input placeholder="max" className="search__text-input--small" maxLength={6} value={commentMaxCount} onChange={(e) => setNumericValue(e.target.value, setCommentMaxCount)}/>
                            </div>

                            <label htmlFor="score-min">Score</label>
                            <div>
                                <input id='score-min' placeholder="min" className="search__text-input--small" maxLength={6} value={scoreMin} onChange={(e) => setNumericValue(e.target.value, setScoreMin)}/>
                                &nbsp;
                                <input placeholder="max" className="search__text-input--small" maxLength={6} value={scoreMax} onChange={(e) => setNumericValue(e.target.value, setScoreMax)}/>
                            </div>
                        </div>
                        <div className="search__column">
                            <label htmlFor="submitted-after">Submitted Before</label>
                            <DatePicker
                                id="submitted-after"
                                selected={submittedBefore}
                                onChange={setSubmittedBefore}
                                isClearable={true}
                                closeOnScroll={true}
                                maxDate={new Date()}
                                minDate={submittedAfter || new Date("2020-05-31T00:00:00.00Z")}
                                showMonthDropdown={true}
                            />

                            <label htmlFor="submitted-before">Submitted After</label>
                            <DatePicker
                                id="submitted-before"
                                selected={submittedAfter}
                                onChange={setSubmittedAfter}
                                isClearable={true}
                                closeOnScroll={true}
                                maxDate={submittedBefore || new Date()}
                                minDate={new Date("2020-05-31T00:00:00.00Z")}
                                showMonthDropdown={true}
                            />
                        </div>
                        <div className="search__column">
                            <label htmlFor="order-by">Order By</label>
                            <SingleSelectWrapper
                                name="order-by"
                                id="order-by"
                                options={orderByOptions}
                                onChange={setOrderBy}
                                selectedValue={orderBy}
                            />

                            <label htmlFor="order-direction">Order Direction</label>
                            <SingleSelectWrapper
                                name="order-direction"
                                id="order-direction"
                                options={orderDirectionOptions}
                                onChange={setOrderDirection}
                                selectedValue={orderDirection}
                            />
                        </div>

                        {/*This hidden button allows us to handle Enter to submit*/}
                        <button type="submit" hidden={true}/>
                    </div>
                </div>
            </form>
        )
    }

    return render();
}

PostSearch.defaultPorps = {
    onChange: () => {
    },
    search: () => {
    }
}
