import React, {Component} from "react";
import {connect} from "react-redux";
import CreateItem from "../../organisms/createItem"
import Spacer from "../../atoms/spacer";
import * as postActions from "../../../redux/actions/postAction.js";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import CreatePostPageBottomBar from "./components/createPostPageBottomBar/createPostPageBottomBar";
import {constants} from "../../../utilities/constants";
import {toast} from "react-toastify";
import * as store from 'store'
import {scroller, Element} from "react-scroll";

import './createPostPage.scss'
import LoginModal from "../../molecules/modals/loginModal";
import ConfirmationModal from "../../molecules/modals/confirmationModal";
import {generalActions} from "../../../redux/actions/generalAction";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";
import {breakpoints} from "../../../utilities/breakpoints";

const itemLimit = 999;

class CreatePostPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isReordering: false,
            showLoginModal: false,
            showConfirmationModal: false,
            confirmationModalText: '',
            confirmationModalAction: 0, //0 - Nothing, 1 - Submit, 2 - New Post, 3 - Delete
            isLoading: 5 //0 - Nothing, 1 - Submit, 2 - New Post, 3 - Delete, 4 - Save, 5 - Post
        }
    }

    static defaultProps = {
        post: {
            items: []
        },
        me: null
    }

    scrollToBottom = () => {
        setTimeout(() => {
            scroller.scrollTo('bottom',
                {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: 20,
                });
        }, 200)

    };

    componentDidMount() {
        const localStoragePost = store.get('postInProgress');

        //Don't think we need a load state for this
        this.props.dispatch(generalActions.getAllTf2ItemStrangePrefixes());
        this.props.dispatch(generalActions.getAllTf2ItemUnusualEffects());

        if (localStoragePost) {
            store.remove('postInProgress');
            this.props.dispatch(postActions.setPost(localStoragePost));
            this.setIsLoading(0);
            this.scrollToBottom();
            return;
        }

        const urlPostId = this.props.match.params.postId;
        if (!urlPostId && this.props.post.items.length < 1) {
            toast.error('No valid post found');
            this.props.history.push(constants.pagePaths.createBrowse);
            return;
        }

        if (urlPostId && !isNaN(urlPostId) && (!this.props.post.id || this.props.post.id !== parseInt(urlPostId))) {
            this.setIsLoading(5);
            this.props.dispatch(postActions.getPost(urlPostId, true))
                .then(resp => {
                    this.setIsLoading(0);
                    this.scrollToBottom();

                    if (!resp) {
                        toast.error('No valid post found');
                        this.props.history.push(constants.pagePaths.createBrowse);
                    }
                })
        } else {
            this.setIsLoading(0);
            this.scrollToBottom();
        }
    }

    toggleReorder = () => {
        this.setState((prevState) => {
            return {
                isReordering: !prevState.isReordering
            }
        });
    }

    toggleLoginModal = () => {
        this.setState((prevState) => {
            return {
                showLoginModal: !prevState.showLoginModal
            }
        });
    }

    toggleConfirmationModal = (text, action) => {
        if (this.props.post.items.length > itemLimit) {
            toast.error(`Posts can have up to ${itemLimit} items`);
            return;
        }

        //We're trying to open the modal. Need to check if the user is authenticated
        if (!this.state.showConfirmationModal) {
            if (!this.props.me || !this.props.me.isLoggedIn) {
                this.toggleLoginModal();
                return;
            }
        }

        this.setState((prevState) => {
            return {
                showConfirmationModal: !prevState.showConfirmationModal,
                confirmationModalText: text,
                confirmationModalAction: action
            }
        });
    }

    handleSavePost = () => {
        if (!this.props.me || !this.props.me.isLoggedIn) {
            this.toggleLoginModal();
            return;
        }

        if (this.props.post.items.length > itemLimit) {
            toast.error(`Posts can have up to ${itemLimit} items`);
            return;
        }

        this.setIsLoading(4);
        this.props.dispatch(postActions.createPost(true, true))
            .then(resp => {
                const urlPostId = this.props.match.params.postId;
                if (!urlPostId)
                    this.props.history.push(`${constants.pagePaths.createPost}/${resp.id}`);
            })
            .finally(() => {
                this.setIsLoading(0);
            })
    }

    handleConfirmationModalClose = (result) => {
        this.toggleConfirmationModal();

        if (result !== true)
            return;

        if (this.state.confirmationModalAction === 1) {
            this.setIsLoading(1);
            this.props.dispatch(postActions.createPost(false))
                .then((resp) => {
                    if (!resp) {
                        this.setIsLoading(0);
                        return;
                    }

                    this.props.dispatch(postActions.clearPost());
                    this.setIsLoading(0);
                    this.props.history.push(`${constants.pagePaths.viewPost}/${resp.id}`)
                });
        } else if (this.state.confirmationModalAction === 2) {
            this.props.dispatch(postActions.clearPost());
            this.props.history.push(constants.pagePaths.createBrowse);
        } else if (this.state.confirmationModalAction === 3) {
            this.setIsLoading(3);
            this.props.dispatch(postActions.deletePost(this.props.post.id))
                .then(() => {
                    this.setIsLoading(0);
                    this.props.history.push(constants.pagePaths.createBrowse);
                });
        } else {
            this.setIsLoading(0);
            toast.error('Invalid confirmation action')
        }
    }

    setIsLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.props.post.items,
            result.source.index,
            result.destination.index
        );

        this.props.dispatch(postActions.updateAllPostItems(items));
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        for (let i = 0; i < result.length; i++) {
            result[i].index = i;
        }

        return result;
    };

    handleLoginButtonClick = () => {
        // Save the current post
        store.set('postInProgress', this.props.post);
    }

    render() {
        const canReorder = this.props.post.items.length > 1 && this.state.isReordering;
        const isMobile = breakpoints.isLessThan750();
        return (
            <>
                <WithTopMenuTemplate>
                    {
                        this.state.isLoading === 5 ?
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <PostSkeleton/>
                            </div>
                            :
                            <>
                                <div className="animated fade-in-move-down row" style={{display: "flex", justifyContent: "center"}}>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>

                                                    {this.props.post.items.map((item, index) =>
                                                        <Draggable key={item.tempId} draggableId={item.tempId} index={index} isDragDisabled={!canReorder}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <CreateItem
                                                                        item={item}
                                                                        isReordering={this.state.isReordering}
                                                                        isLoading={this.state.isLoading > 0}
                                                                        postItemCount={this.props.post.items.length}
                                                                    />
                                                                    <Spacer paddingBottom={isMobile ? 10 : 20}/>
                                                                </div>)}
                                                        </Draggable>)}
                                                </div>)}

                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <Element name='bottom' id='bottom'/>
                            </>
                    }
                </WithTopMenuTemplate>
                <CreatePostPageBottomBar
                    postItemCount={this.props.post.items.length}
                    toggleReorder={this.toggleReorder}
                    isReordering={this.state.isReordering}
                    toggleLoginModal={this.toggleLoginModal}
                    toggleConfirmationModal={this.toggleConfirmationModal}
                    me={this.props.me}
                    handleSavePost={this.handleSavePost}
                    postId={this.props.post.id}
                    isLoading={this.state.isLoading}
                />
                <LoginModal
                    modalIsOpen={this.state.showLoginModal}
                    message={'Please sign in to save and submit posts.'}
                    handleLoginButtonClick={this.handleLoginButtonClick}
                    onClose={this.toggleLoginModal}
                />
                <ConfirmationModal
                    onClose={this.handleConfirmationModalClose}
                    modalIsOpen={this.state.showConfirmationModal}
                    question={this.state.confirmationModalText}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        post: state.post,
        me: state.authentication.me
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostPage);
