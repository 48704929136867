import {api, ENDPOINTS} from "../api";
import {toast} from "react-toastify";

const redirectToLoginWithSteam = () => {
    window.location = `${window.location.origin}/api/authentication/login?returnUrl=${window.location.href}`;
};

const logout = () => {
    return api.get(`${ENDPOINTS.authentication.logout}?returnUrl=${window.location.href}`)
        .then(() => {
            window.location.reload();
        }).catch(error => {
            toast.error('Failed to logout');
            console.error("Failed to logout: ", error);
        });
}

export const authenticationService = {
    redirectToLoginWithSteam,
    logout
}
