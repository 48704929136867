import React, { Component } from "react";

class BaseTemplate extends Component {

	static defaultProps = {
		children: []
	}

	render() {
		return (
			<div className="main-body">
				{this.props.children}
			</div>
		)
	}
}

export default BaseTemplate;