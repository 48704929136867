import React, {Component} from "react";

import '../policy.scss';
import WithTopMenuTemplate from "../../../templates/withTopMenuTemplate";

class PolicyCookie extends Component {
    renderSeparator = () => {
        return (
            <div className="policy__separator" />
        );
    }

    render() {
        return (
            <WithTopMenuTemplate>
                <div className="policy__layout">
                    <div className="policy__container">
                        <h1>Cookie Policy</h1>
                        <div>Last Updated: 27/04/2020</div>
                        <p>
                            This Cookie Policy explains how our website uses cookies and similar technologies to recognize you when you visit our websites at https://tags.tf.
                            It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                        </p>

                        {this.renderSeparator()}
                        <h2>What are cookies?</h2>
                        <p>
                            Cookies are small data files that are placed on your computer or mobile device when you visit a website.
                            Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
                        </p>

                        <p>
                            Cookies set by the website owner in this case, tags.tf, are called "first party cookies".
                            Cookies set by parties other than the website owner are called "third party cookies".
                            Third party cookies enable third party features or functionality to be provided on or through the website (e.g. analytics, advertising and interactive content).
                            The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                        </p>

                        {this.renderSeparator()}
                        <h2>Why do we use cookies?</h2>
                        <p>
                            We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate,
                            e.g. authentication, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance their experience.
                            Third parties serve cookies through our website for advertising, analytics and other purposes.
                        </p>

                        {this.renderSeparator()}
                        <h2>How can I control cookies?</h2>
                        <p>
                            You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by consenting or ignoring the banner at the bottom of the screen.
                        </p>

                        <p>
                            If you choose to ignore the banner, you may still use our website though your access to some functionality and areas of our website may be restricted.
                            You may also set or amend your web browser controls to accept or refuse cookies.
                            As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
                        </p>

                        {this.renderSeparator()}
                        <h2>Do you serve targeted advertising?</h2>
                        <p>
                            Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites.
                            These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in.
                            They may also employ technology that is used to measure the effectiveness of advertisements.
                            This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you.
                            The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.
                        </p>

                        {this.renderSeparator()}
                        <h2>How often will you update this Cookie Policy?</h2>
                        <p>
                            We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons.
                            Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                        </p>

                        <p>
                            The date at the top of this Cookie Policy indicates when it was last updated.
                        </p>

                        {this.renderSeparator()}
                        <h2>Where can I get further information?</h2>
                        <p>
                            If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:policies@tags.tf?Subject=Cookie%20Policy" className="policy__link" target="_top">policies@tags.tf</a>.
                        </p>
                    </div>
                </div>
            </WithTopMenuTemplate>
        )
    }
}

export default PolicyCookie;
