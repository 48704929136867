import React, { Component } from "react";

class InfoBox extends Component {

	static defaultProps = {
		children: []
	}

	render() {
		return (
			<div className="info-box">{this.props.children}</div>
		)
	}
}

export default InfoBox;
