import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../../atoms/loading-spinner/loadingSpinner";
import './userInfiniteScroll.scss'
import {Username} from "../../../atoms/username/username";
import {constants} from "../../../../utilities/constants";
import {breakpoints} from "../../../../utilities/breakpoints";

export const UserLeaderboardInfiniteScroll = (props) => {

    const renderLeaderboardSpecificColumnNames = (isMobile) => {
        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostPostsMade)
            return (<>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Made' : 'Posts Made'}</th>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Total' : 'Total Posts'}</th>
                </>
            );

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostScoreEarned)
            return (<>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Made' : 'Score Earned'}</th>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Total' : 'Total Score'}</th>
                </>
            );

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostScoreLost)
            return (<>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Lost' : 'Score Lost'}</th>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Total' : 'Total Score'}</th>
                </>
            );

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.tagsTfSupporters)
            return (<>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Made' : 'Score Earned'}</th>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Total' : 'Total Score'}</th>
                </>
            );

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostCommentsMade)
            return (<>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Made' : 'Comments Made'}</th>
                    <th className='user-infinite-scroll__table-header'>{isMobile ? 'Total' : 'Total Comments'}</th>
                </>
            );
    }

    const renderLeaderboardSpecificTds = (user) => {
        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostPostsMade)
            return (<>
                    <td className='user-infinite-scroll__positive'>{user.postsMade}</td>
                    <td>{user.totalPostCount}</td>
                </>
            );

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostScoreEarned) {
            const className = user.scoreMade >= 0 ? 'user-infinite-scroll__positive' : 'user-infinite-scroll__negative';
            return (<>
                    <td className={className}>{user.scoreMade}</td>
                    <td>{user.totalScore}</td>
                </>
            );
        }

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostScoreLost) {
            const className = user.scoreMade >= 0 ? 'user-infinite-scroll__positive' : 'user-infinite-scroll__negative';
            return (<>
                    <td className={className}>{user.scoreMade}</td>
                    <td>{user.totalScore}</td>
                </>
            );
        }

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.tagsTfSupporters) {
            const className = user.scoreMade >= 0 ? 'user-infinite-scroll__positive' : 'user-infinite-scroll__negative';
            return (<>
                    <td className={className}>{user.scoreMade}</td>
                    <td>{user.totalScore}</td>
                </>
            );
        }

        if (props.leaderboardType === constants.leaderboardActions.userLeaderboards.mostCommentsMade)
            return (<>
                    <td className='user-infinite-scroll__positive'>{user.commentsMade}</td>
                    <td>{user.totalCommentCount}</td>
                </>
            );
    }

    const render = () => {
        const isMobile = breakpoints.isLessThan750();
        return (
            <InfiniteScroll
                next={props.fetchNext}
                hasMore={props.hasMore}
                loader={
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <LoadingSpinner size={LoadingSpinnerSizes.medium} show={true}/>
                    </div>
                }
                dataLength={props.users.length}
                endMessage={<div style={{textAlign: 'center', marginTop: '10px'}}>{props.endMessage}</div>}
                className='user-infinite-scroll__container'
            >
                <table className='user-infinite-scroll__table' cellSpacing={0}>
                    <thead>
                    <tr>
                        <th className='user-infinite-scroll__table-header' style={{minWidth: '30px'}}>{isMobile ? '#' : 'Rank'}</th>
                        <th className='user-infinite-scroll__table-header'>Username</th>
                        {renderLeaderboardSpecificColumnNames(isMobile)}
                    </tr>
                    </thead>

                    <tbody>
                    {props.users.map((user, index) =>
                        <tr key={index} className='user-infinite-scroll__table-row'>
                            <td>{index + 1}</td>
                            <td>
                                <Username width={isMobile ? 125 : 250} username={user.baseUsername || user.username} userId={user.id} avatarUrl={user.avatarUrl}/>
                            </td>
                            {renderLeaderboardSpecificTds(user, isMobile)}
                        </tr>)}
                    </tbody>


                </table>

            </InfiniteScroll>
        )
    }

    return render();
}

UserLeaderboardInfiniteScroll.defaultProps = {
    users: [],
    fetchNext: () => {
    },
    hasMore: true,
    endMessage: '',
    leaderboardType: ''
}
