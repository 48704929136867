import React, {Component} from "react";
import {Link} from "react-router-dom";

class BottomNavButton extends Component {

    static defaultProps = {
        linkUrl: "",
        isDisabled: false
    }

    checkIfDisabled = (event) => {
        if (this.props.isDisabled)
            event.preventDefault();
    }

    render() {
        const className = `menu-button ${this.props.isDisabled ? 'menu-button--disabled' : ''}`
        return (
            <Link to={this.props.linkUrl} className={className} onClick={this.checkIfDisabled}>{this.props.children}</Link>
        )
    }
}

export default BottomNavButton;
