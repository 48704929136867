import React, {useState, useEffect} from 'react';
import './profileAdmin.scss'
import {BanModal} from "../../../../molecules/modals/banModal/banModal";
import {api, ENDPOINTS} from "../../../../../api/api";
import {toast} from "react-toastify";

export const ProfileAdmin = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [banReasons, setBanReasons] = useState([]);

    useEffect(() => {
        if (banReasons.length < 1)
            api.get(ENDPOINTS.user.getBanReasons)
                .then(resp => {
                    setBanReasons(resp.data);
                })
                .catch(ex => {
                    toast.error('Failed to get Ban Reasons');
                    console.error('Failed to get Ban Reasons: ', ex);
                })
    }, []);

    const render = () => {
        return (
            <>
                <div className='admin'>
                    <button className='admin__button' onClick={() => setIsModalOpen(true)}>Open Ban Form</button>
                </div>
                <BanModal
                    banReasons={banReasons}
                    modalIsOpen={isModalOpen}
                    userId={props.userId}
                    onClose={() => setIsModalOpen(false)}
                />
            </>
        )
    }

    return render();
}

ProfileAdmin.defaultProps = {
    userId: null
}
