import React, {Component} from "react";
import ItemCategoryButton from "../../atoms/buttons/itemCategoryButton";
import InfoBox from "../../atoms/infoBox";

class ItemCategoriesRow extends Component {

    static defaultProps = {
        tf2Class: "",
        handleCategoryClick: () => {},
        selectedCategoryName: ''
    }

    render() {
        if (!this.props.tf2Class || !this.props.tf2Class.availableItemSlots)
            return null;

        return (
            <div className="centered subtle-scrollbar">
                <div className="animated fade-in-move-down row row-category row-scroll">
                    {
                        this.props.tf2Class.availableItemSlots.length === 0 ?
                            (<div className="centered">
                                    <InfoBox>
                                        <div className="centered">No items found for this class</div>
                                    </InfoBox>
                                </div>
                            ) :
                            (this.props.tf2Class.availableItemSlots.map((itemCategory) =>
                                    <ItemCategoryButton
                                        itemCategoryName={itemCategory}
                                        key={this.props.tf2Class.name + itemCategory}
                                        handleCategoryClick={this.props.handleCategoryClick}
                                        selectedCategoryName={this.props.selectedCategoryName}
                                    />)
                            )
                    }
                </div>
            </div>
        )
    }
}

export default ItemCategoriesRow;
