import React, {Component} from "react";
import {connect} from "react-redux";
import ItemHoverButton from "./itemHoverButton"
import {postActions} from "../../../../redux/actions/postAction";
import {constants} from "../../../../utilities/constants";
import {toast} from "react-toastify";
import {QualityHelper} from "../../../../utilities/qualityHelper";

class ItemQualitySelectButton extends Component {
    static defaultProps = {
        quality: null,
        item: {},
        isLoading: false,
    }

    getClassName = () => {
        return `item-quality ${QualityHelper.sanitizeQuality(this.props.quality.name)} ${this.props.isLoading ? 'item-quality--disabled' : ''}`;
    }

    setEditedItemTempQuality = (newQuality, isMouseEnter) => {
        if (this.props.isLoading)
            return;

        let tempStrangePrefix = this.props.item.strangePrefix;
        if (newQuality !== constants.qualities.strange)
            tempStrangePrefix = ''

        if (newQuality === constants.qualities.strange && (!this.props.item.strangePrefix || this.props.item.strangePrefix === ''))
            tempStrangePrefix = constants.strangePrefixes.strange;
        
        let tempItem = {
            ...this.props.item,
            tempQuality: newQuality,
            tempStrangePrefix: tempStrangePrefix,
            displayTempStrangePrefix: isMouseEnter,
            tempUnusualEffectUrl: '',
            displayTempUnusualEffect: isMouseEnter
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    setEditedItemQuality = (newQuality) => {
        if (this.props.isLoading)
            return;

        let tempStrangePrefix = this.props.item.strangePrefix;
        if (newQuality !== constants.qualities.strange)
            tempStrangePrefix = ''

        if (newQuality === constants.qualities.strange && (!this.props.item.strangePrefix || this.props.item.strangePrefix === ''))
            tempStrangePrefix = constants.strangePrefixes.strange;

        let tempUnusualEffectUrl = this.props.item.unusualEffectUrl;
        let tempUnusualEffectId = this.props.item.unusualEffectId;

        let tempItem = {
            ...this.props.item,
            quality: newQuality,
            tempStrangePrefix: '',
            strangePrefix: tempStrangePrefix,
            displayTempStrangePrefix: false,
            unusualEffectId: null,
            unusualEffectUrl: '',
            tempUnusualEffectUrl: tempUnusualEffectUrl,
            displayTempUnusualEffect: false
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    render() {
        return (
            <ItemHoverButton
                onMouseEnterParam={this.props.quality.name}
                onMouseEnter={(newQuality) => this.setEditedItemTempQuality(newQuality, true)}
                onMouseLeaveParam=""
                onMouseLeave={(newQuality) => this.setEditedItemTempQuality(newQuality, false)}
                onMouseClickParam={this.props.quality.name}
                onClick={this.setEditedItemQuality}
                getClassName={this.getClassName}
                isLoading={this.props.isLoading}
            >
                {this.props.quality.name}
            </ItemHoverButton>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(ItemQualitySelectButton);
