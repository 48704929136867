import React, {Component} from "react";
import Search from "../../atoms/search";

class SearchRow extends Component {


    static defaultProps = {
        isVisible: false,
        handleItemSearch: () => {
        }
    }

    render() {
        return (
            <>
                {
                    this.props.isVisible &&
                    <div className="centered">
                        <div className="mt-3 animated fade-in-move-down row row-search">
                            <Search maxChars={100} placeholder="Filter Items By Name" handleSearch={this.props.handleItemSearch}/>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default SearchRow;
