import React from "react";

import './loadingSpinner.scss'

export class LoadingSpinner extends React.Component {

    static defaultProps = {
        show: false,
        size: 'md'
    };

    render() {
        return (
            <>
                {
                    this.props.show ? (
                        <div className={`lds-ring lds-ring--${this.props.size}`}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    ) : null
                }
            </>
        );
    }
}

export const LoadingSpinnerSizes = {
    extraSmall: 'xs', //Only use this on specific buttons, such as comment controls
    small: 'sm',
    medium: 'md',
    large: 'lg'
};
