import React, {Component} from "react";
import {nameDescriptionHelper} from "../../../utilities/nameDescriptionHelper";

class ItemCategoryButton extends Component {

    static defaultProps = {
        itemCategoryName: "",
        availableItems: [],
        selectedCategoryName: "",
        handleCategoryClick: () => {
        }
    }

    getIconClass = () => {
        const grey = this.props.selectedCategoryName === this.props.itemCategoryName ? '' : "category-icon--grey";
        return `category-icon ${this.props.itemCategoryName} ${grey}`;
    }

    render() {
        return (
            <div
                className={this.getIconClass()}
                onClick={() => this.props.handleCategoryClick(this.props.itemCategoryName)}
                title={this.props.itemCategoryName === 'misc' ? 'Cosmetics' : nameDescriptionHelper.capitalizeFirstLetter(this.props.itemCategoryName)}
            />
        )
    }
}

export default ItemCategoryButton;
