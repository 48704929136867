import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import './profilePage.scss';
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";

import ItemEditOptionButton from "../../atoms/buttons/itemEdit/itemEditOptionButton";
import {constants} from "../../../utilities/constants";
import {postsService} from "../../../api/services/postsService";
import {scoreHelpers} from "../../../utilities/scoreHelpers";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../atoms/loading-spinner/loadingSpinner";
import CommentContainer from "../post/components/comments/comment/commentContainer";
import {commentService} from "../../../api/services/commentService";
import '../post/components/comments/comment-section/commentSection.scss'
import {ProfilePosts} from "./components/posts/profilePosts";
import {ProfileAdmin} from "./components/admin/profileAdmin";
import {authenticationService} from "../../../api/services/authenticationService";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";
import {VoteLog} from "../post/components/voteLog/voteLog";
import {UserCard} from "./components/users/userCard";

export const ProfilePage = (props) => {
    const me = useSelector(state => state.authentication.me);
    const [userProfile, setUserProfile] = useState({});
    const [selectedAction, setSelectedAction] = useState('');
    const [pageLoadDateTime] = useState(new Date());

    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [isLoadingInitialPosts, setIsLoadingInitialPosts] = useState(false);
    const [isLoadingInitialPostsInProgress, setIsLoadingInitialPostsInProgress] = useState(false);
    const [isLoadingComments, setIsLoadingComments] = useState(false);

    const [posts, setPosts] = useState([]);
    const [postsPageNumber, setPostsPageNumber] = useState(1);
    const [hasMorePosts, setHasMorePosts] = useState(true);

    const [postsInProgress, setPostsInProgress] = useState([]);
    const [postsInProgressPageNumber, setPostsInProgressPageNumber] = useState(1);
    const [hasMorePostsInProgress, setHasMorePostsInProgress] = useState(true);

    const [comments, setComments] = useState([]);

    const urlUserId = props.match.params.userId;

    useEffect(() => {
        if (isNaN(urlUserId)) {
            props.history.push('/');
            toast.error('Failed to load the user profile');
        }

        if (!userProfile || urlUserId !== userProfile.id) {
            setPosts([]);
            setPostsPageNumber(1);
            setPostsInProgress([]);
            setPostsInProgressPageNumber(1);
            setComments([]);
            setSelectedAction('');
            setUserProfile({});
        }

        setIsLoadingPage(true);
        const url = ENDPOINTS.user.profile.replace('{userId}', urlUserId);
        api.get(url)
            .then(resp => {
                setUserProfile(resp.data);
            })
            .catch(ex => {
                props.history.push('/');
                toast.error('Failed to load the user profile');
                console.error('Failed to load the user profile', ex)
            })
            .finally(() => {
                setIsLoadingPage(false);
            })
    }, [urlUserId]); //Passing empty array makes this work like ComponentDidMount

    useEffect(() => {
        if (selectedAction === constants.profileActions.posts && posts.length === 0)
            fetchPosts(postsPageNumber);
        else if (selectedAction === constants.profileActions.postsInProgress && postsInProgress.length === 0)
            fetchPostsInProgress(postsInProgressPageNumber);
        else if (selectedAction === constants.profileActions.comments && comments.length === 0)
            fetchComments();

    }, [selectedAction])

    const fetchComments = () => {
        setIsLoadingComments(true);
        commentService.getCommentsForUser(userProfile.id)
            .then(resp => {
                setComments(resp);
            })
            .catch(ex => {
                toast.error('Failed to load comments')
                console.error('Failed to load comments: ', ex);
            })
            .finally(() => {
                setIsLoadingComments(false);
            })
    }

    const fetchPosts = (pageNumber) => {
        if (pageNumber === 1)
            setIsLoadingInitialPosts(true);

        postsService.fetchPostsForHomepage(pageNumber, pageLoadDateTime, urlUserId)
            .then(resp => {
                if (!resp)
                    return;

                const newPosts = posts.concat(resp.data.posts);
                setPosts(newPosts);
                setPostsPageNumber(pageNumber);
                setHasMorePosts(resp.data.hasMore);
            })
            .finally(() => {
                if (pageNumber === 1)
                    setIsLoadingInitialPosts(false);
            });
    }

    const fetchPostsInProgress = (pageNumber) => {
        if (pageNumber === 1)
            setIsLoadingInitialPostsInProgress(true);

        postsService.fetchPostsForHomepage(pageNumber, pageLoadDateTime, urlUserId, true)
            .then(resp => {
                if (!resp)
                    return;

                const newPosts = postsInProgress.concat(resp.data.posts);
                setPostsInProgress(newPosts);
                setPostsInProgressPageNumber(pageNumber);
                setHasMorePostsInProgress(resp.data.hasMore);
            })
            .finally(() => {
                if (pageNumber === 1)
                    setIsLoadingInitialPostsInProgress(false);
            });
    }


    const updateLocalPostScore = (newScore, postId, postsToUpdate) => {
        const existingPosts = [...postsToUpdate];
        const newPosts = scoreHelpers.updateLocalPostScore(newScore, postId, existingPosts);

        setPosts(newPosts);
    }

    const renderProfileCard = () => {
        return (
            <UserCard userProfile={userProfile}/>
        );
    }

    const logout = () => {
        authenticationService.logout();
    }

    const renderOptionsRow = () => {
        return (
            <div className='profile-page__options-row'>
                <ItemEditOptionButton
                    title='Posts'
                    selectedAction={selectedAction}
                    actionName={constants.profileActions.posts}
                    setAction={setSelectedAction}
                    isProfileIcon={true}
                />
                <ItemEditOptionButton
                    title='Comments'
                    selectedAction={selectedAction}
                    actionName={constants.profileActions.comments}
                    setAction={setSelectedAction}
                    isProfileIcon={true}
                />
                {
                    me && userProfile && me.id === userProfile.id &&
                    <ItemEditOptionButton
                        title='Posts in Progress'
                        selectedAction={selectedAction}
                        actionName={constants.profileActions.postsInProgress}
                        setAction={setSelectedAction}
                        isProfileIcon={true}
                    />
                }
                {
                    me && ((userProfile && me.id === userProfile.id) || me.isAdmin) &&
                    <ItemEditOptionButton
                        title='Votes'
                        selectedAction={selectedAction}
                        actionName={constants.profileActions.votes}
                        setAction={setSelectedAction}
                        isProfileIcon={true}
                    />
                }

                {
                    me && userProfile && me.id === userProfile.id &&
                    <ItemEditOptionButton
                        title='Logout'
                        actionName={constants.profileActions.logout}
                        onClick={logout}
                        isProfileIcon={true}
                    />
                }

                {
                    me && me.isAdmin &&
                    <ItemEditOptionButton
                        title='Admin'
                        selectedAction={selectedAction}
                        actionName={constants.profileActions.admin}
                        setAction={setSelectedAction}
                        isProfileIcon={true}
                    />
                }
            </div>
        )
    }

    const renderPostsInProgress = () => {
        return (
            <ProfilePosts
                infoText='You do not have any posts in progress yet. Why not head over to the Create page?'
                isInitialLoading={isLoadingInitialPostsInProgress}
                posts={postsInProgress}
                updateLocalPostScore={(newScore, postId) => updateLocalPostScore(newScore, postId, postsInProgress)}
                fetchMore={() => fetchPostsInProgress(postsInProgressPageNumber + 1)}
                hasMore={hasMorePostsInProgress}
                me={me}
                username={userProfile.username}
                goToCreatePage={true}
                isDraftPosts={true}
            />
        )
    }

    const renderPosts = () => {
        return (
            <ProfilePosts
                infoText='This user has not submitted any posts yet.'
                isInitialLoading={isLoadingInitialPosts}
                posts={posts}
                updateLocalPostScore={(newScore, postId) => updateLocalPostScore(newScore, postId, posts)}
                fetchMore={() => fetchPosts(postsPageNumber + 1)}
                hasMore={hasMorePosts}
                me={me}
                username={userProfile.username}
            />
        )
    }

    const renderInfo = (text) => {
        return (<div className='profile-page__info'>{text}</div>)
    }

    const renderComments = () => {
        if (isLoadingComments) {
            return (
                <LoadingSpinner
                    size={LoadingSpinnerSizes.large}
                    show={true}
                />
            )
        }

        if (comments.length < 1)
            return renderInfo('This user has not made any comments yet. Why not encourage them by mentioning them in a post using @');

        return (
            <div className='comment-section__container animated fade-in-move-down'>
                {
                    comments.map((comment) =>
                        <CommentContainer
                            key={comment.id}
                            comment={comment}
                            topLevelCommentId={comment.id}
                            me={me}
                            showGoToPostButton={true}
                        />
                    )}
            </div>
        )
    }

    const renderAdmin = () => {
        return (
            <ProfileAdmin
                userId={userProfile.id}
            />
        )
    }

    const renderVotes = () => {
        return (
            <VoteLog
                show={selectedAction === constants.profileActions.votes}
                isPostPage={false}
                userId={userProfile.id}
            />
        )
    }

    const renderTabs = () => {
        if (!selectedAction)
            return null;

        return (
            <>
                {
                    selectedAction === constants.profileActions.posts &&
                    renderPosts()
                }

                {
                    selectedAction === constants.profileActions.comments &&
                    renderComments()
                }

                {
                    selectedAction === constants.profileActions.postsInProgress &&
                    renderPostsInProgress()
                }

                {
                    selectedAction === constants.profileActions.votes &&
                    renderVotes()
                }

                {
                    selectedAction === constants.profileActions.admin &&
                    renderAdmin()
                }
            </>

        )
    }

    const render = () => {
        return (
            <WithTopMenuTemplate>
                <div className='profile-page'>
                    {
                        isLoadingPage ? <PostSkeleton/>
                            :
                            <>
                                {renderProfileCard()}
                                {renderOptionsRow()}
                                {renderTabs()}
                            </>
                    }
                </div>
            </WithTopMenuTemplate>
        )
    }

    return render();
}
