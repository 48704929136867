import * as authenticationActions from "../actions/authenticationActions";


export default function (
    state = {
        me: null
    },
    action) {
    switch (action.type) {
        case authenticationActions.SET_ME: {
            return {
                ...state,
                me: action.payload
            }
        }
        default: {
        }

    }
    return state;
}
