import React from 'react'
import {PostsInfiniteScroll} from "../../../../organisms/postsInfiniteScroll/postsInfiniteScroll";
import {PostSkeleton} from "../../../../atoms/postSkeleton/postSkeleton";

export const ProfilePosts = (props) => {
    const render = () => {
        if (props.isInitialLoading) {
            return (
                <>
                    <PostSkeleton/>
                    <PostSkeleton marginTop={20}/>
                    <PostSkeleton marginTop={20}/>
                </>
            )
        }

        if (props.posts.length < 1)
            return (<div className='profile-page__info'>{props.infoText}</div>);

        let classNames = ''
        if (!props.isDraftPosts)
            classNames = 'profile-page__posts'

        return (
            <div className={`${classNames} animated fade-in-move-down`}>
                {
                    props.posts.length > 0 &&
                    <PostsInfiniteScroll
                        posts={props.posts}
                        updateLocalPostScore={props.updateLocalPostScore}
                        fetchNextPosts={props.fetchMore}
                        hasMore={props.hasMore}
                        me={props.me}
                        goToCreatePage={props.goToCreatePage}
                        endOfPostsMessage={`Congratulations, you've seen every single post ${props.username} has created!`}
                    />
                }
            </div>
        )
    }

    return render();
}

ProfilePosts.defaultProps = {
    posts: [],
    updateLocalPostScore: () => {
    },
    fetchPosts: () => {
    },
    hasMore: true,
    me: {},
    username: '',
    isInitialLoading: true,
    fetchMore: () => {
    },
    goToCreatePage: false,
    isDraftPosts: false,
    infoText: ''
}
