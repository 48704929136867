import React from 'react'

import './notifications.scss';
import {Link} from "react-router-dom";
import {constants} from "../../../utilities/constants";
import {TimeAgoWrapper} from "../../atoms/timeAgoWrapper/timeAgoWrapper";
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";

export const Notifications = React.forwardRef((props, ref) => {
    /// <summary>
    /// Someone upvoted or downvoted your post
    /// </summary>
    //PostScore = 1,

    /// <summary>
    /// Someone mentioned you in a comment. This supersedes Comment
    /// </summary>
    //CommentMention = 2,

    /// <summary>
    /// Someone replied to your parent comment. This supersedes CommentPost
    /// </summary>
    //CommentReply = 3,

    /// <summary>
    /// Someone commented on your post
    /// </summary>
    // CommentPost = 4,

    const renderNotification = (notification) => {
        switch (notification.notificationType) {
            case 1: {
                return (
                    <Link to={`${constants.pagePaths.viewPost}/${notification.postId}`} className='notifications__text'>
                        <span>
                            <span className='notifications__text--dark'>Your</span>
                            &nbsp;{getNotificationPostTitle(notification)}&nbsp;
                            <span className='notifications__text--dark'>has changed in score by</span>
                            &nbsp;{notification.postScoreChangeSum}
                            <div className='notifications__text--dark'><TimeAgoWrapper date={notification.createdDateTime}/></div>
                        </span>
                    </Link>
                )
            }
            case 2: {
                return renderCommentNotification(notification, 'mentioned', 'you in a comment in')
            }
            case 3: {
                return renderCommentNotification(notification, 'replied', 'to your comment in')
            }
            case 4: {
                return renderCommentNotification(notification, 'commented', 'on your');
            }
            default: {
                return null;
            }
        }
    }

    const getNotificationPostTitle = (notification) => {
        return notification.postTitle || notification.defaultPostTitle;
    }

    const renderCommentNotification = (notification, verb, text) => {
        return (
            <Link to={`${constants.pagePaths.viewPost}/${notification.postId}#${notification.sourceId}`} className='notifications__text'>
                <span>{notification.sourceUserUsername}&nbsp;
                    <span className='notifications__text--dark'>has</span>
                    &nbsp;{verb}&nbsp;
                    <span className='notifications__text--dark'>{text}</span>
                    &nbsp;{getNotificationPostTitle(notification)}
                    <div className='notifications__text--dark'><TimeAgoWrapper date={notification.createdDateTime}/></div>
                </span>
            </Link>
        )
    }

    const getAllNotificationIds = () => {
        let notificationIds = [];
        for (let i = 0; i < props.notificationsState.length; i++) {
            if (props.notificationsState[i].id)
                notificationIds.push(props.notificationsState[i].id);
            else
                notificationIds = notificationIds.concat(props.notificationsState[i].ids)
        }

        return notificationIds;
    }

    const markAllNotificationsAsRead = () => {
        const notificationIds = getAllNotificationIds();

        markNotificationAsRead(notificationIds);
    }

    const markNotificationAsRead = (notificationIds) => {
        if (!Array.isArray(notificationIds))
            notificationIds = [notificationIds];

        api.post(`${ENDPOINTS.notifications.markAsRead}`, notificationIds)
            .then((resp) => {
                toast.success('Marked notification(s) as Read');
            })
            .catch((ex) => {
                toast.error('Failed to mark notification(s) as Read');
                console.error('Failed to mark notification(s) as Read: ', ex);
            })

        const newArray = [...props.notificationsState];
        for (let i = 0; i < notificationIds.length; i++) {
            const indexToDelete = newArray.findIndex(function (o) {
                return o.id === notificationIds[i] || (o.ids && o.ids.includes(notificationIds[i]));
            });

            if (indexToDelete !== -1)
                newArray.splice(indexToDelete, 1);
        }

        props.setNotificationsState(newArray);
    }

    const render = () => {
        if (!props.notificationsState)
            return null;

        return (
            <>
                <div className='notifications__triangle'/>
                <div
                    ref={ref}
                    className='notifications__container'
                >
                    {props.notificationsState.length > 0 ?
                        <>
                            <div className='notifications__header'>
                                <button className='notifications__button' onClick={markAllNotificationsAsRead}>Mark All as Read</button>
                            </div>

                            <div className='notifications__container-scrollable-area'>
                                {
                                    props.notificationsState.map((notification, index) =>
                                        <div key={index} className='notifications__entry'>
                                            <div className='notifications__entry-text'>{renderNotification(notification)}</div>
                                            <button title='Mark as Read' className='notifications__button-x' onClick={() => markNotificationAsRead(notification.id || notification.ids)}>&times;</button>
                                        </div>
                                    )
                                }
                            </div>
                        </> :
                        <div className='notifications__no-new'>You have no new notifications.</div>}
                </div>
            </>
        );
    }

    return render();
})
