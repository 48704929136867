import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {createStore} from "redux";
import {Router, Switch, Route, Redirect} from "react-router-dom";
import history from "./app/utilities/history";
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './serviceWorker';
import appReducers from "./app/redux/appReducers";
import appServices from "./app/redux/appServices";

import PostPage from "./app/components/pages/post/postPage";
import CreatePage from "./app/components/pages/createPage/createPage";
import CreatePostPage from "./app/components/pages/createPostPage/createPostPage";
import HomePage from "./app/components/pages/homepage/homePage";
import {ProfilePage} from "./app/components/pages/profile/profilePage";
import PolicyCookie from "./app/components/pages/policy/cookie/policyCookie";

import "./app/style/allStyles.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ToastContainerWrapper from "./app/components/atoms/toast/toastContainerWrapper";
import Startup from "./app/components/pages/startup/startup";
import CookieConsentWrapper from "./app/components/atoms/cookie-consent-wrapper/cookie-consent-wrapper";
import {constants} from "./app/utilities/constants";
import {BannedPage} from "./app/components/pages/bannedPage/bannedPage";
import {PolicyPage} from "./app/components/pages/policy/policyPage";
import {PolicyTermsAndConditions} from "./app/components/pages/policy/termsAndConditions/policyTermsAndConditions";
import {PolicyPrivacy} from "./app/components/pages/policy/privacy/policyPrivacy";
import {AboutUsPage} from "./app/components/pages/aboutUs/aboutUsPage";
import {Leaderboards} from "./app/components/pages/leaderboards/leaderboards";
import {SearchPage} from "./app/components/pages/search/searchPage";

const store = createStore(appReducers, appServices);

// Sentry should be initialized before anything else
// https://sentry.io/onboarding/tf2tags/get-started/
Sentry.init({dsn: "https://01b047e3dc834d378cc6d378c4d5fa11@o403249.ingest.sentry.io/5265619"});

ReactDOM.render(
    <Provider store={store} className="full-height-and-width">
        <Startup>
            <ToastContainerWrapper/>
            <Router history={history}>
                <Switch>
                    <Route path={constants.pagePaths.bannedPage} exact component={BannedPage}/>
                    <Route path={constants.pagePaths.homePage} exact component={HomePage}/>
                    <Route path={`${constants.pagePaths.viewPost}/:postId`} exact component={PostPage}/>
                    <Route path={constants.pagePaths.createBrowse} component={CreatePage}/>
                    <Route exact path={constants.pagePaths.createPost} component={CreatePostPage}/>
                    <Route exact path={`${constants.pagePaths.createPost}/:postId`} component={CreatePostPage}/>
                    <Route exact path={`${constants.pagePaths.search}`} component={SearchPage}/>

                    <Route exact path={constants.pagePaths.policyPage} component={PolicyPage}/>
                    <Route exact path={constants.pagePaths.policyCookie} component={PolicyCookie}/>
                    <Route exact path={constants.pagePaths.policyTermsAndConditions} component={PolicyTermsAndConditions}/>
                    <Route exact path={constants.pagePaths.policyPrivacy} component={PolicyPrivacy}/>

                    <Route exact path={`${constants.pagePaths.profile}/:userId`} component={ProfilePage}/>

                    <Route exact path={`${constants.pagePaths.aboutUs}`} component={AboutUsPage}/>


                    <Route exact path={`${constants.pagePaths.leaderboards}`} component={Leaderboards}/>

                    <Redirect to={constants.pagePaths.homePage}/>
                </Switch>
            </Router>
            <CookieConsentWrapper/>
        </Startup>
    </Provider>,
    document.getElementById('app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;
