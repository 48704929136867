const toLocalTime = (dateTime) => {
    if (!dateTime)
        return null;

    // Ensure the time has ISO-8601 UTC offset
    if (dateTime[dateTime.length - 1] !== 'Z')
        dateTime = dateTime + 'Z';

    return new Date(dateTime);
};

export const timeUtils = {
    toLocalTime
};
