import React from 'react';

import './mentionHighlight.scss';
import {Link} from "react-router-dom";
import {constants} from "../../../../../../../../utilities/constants";

const MentionHighlight = (props) => {
    const {
        mention
    } = props;

    return (
        <Link title={mention.name} className='mention__highlight' to={`${constants.pagePaths.profile}/${mention.id}`}>
            {mention.name}
        </Link>
    );
};

export default MentionHighlight;
