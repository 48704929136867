import config from "../../config.json";
import axios from "axios";

import {steamApi} from "./steam.js";
import {tf2Api} from "./tf2.js";
import {postsApi} from "./posts";
import {commentsApi} from "./comments";
import {userApi} from "./users";
import {authenticationApi} from "./authentication";
import {itemsApi} from "./items";
import {notificationsApi} from "./notifications";
import {leaderboardsApi} from "./leaderbaords";
import {searchApi} from "./search";


export const ENDPOINTS = {
    steam: steamApi,
    tf2: tf2Api,
    posts: postsApi,
    authentication: authenticationApi,
    comments: commentsApi,
    user: userApi,
    items: itemsApi,
    notifications: notificationsApi,
    leaderboards: leaderboardsApi,
    search: searchApi
};

export const api = axios.create({
    baseURL: `${window.location.origin}/${config.apiPath}`,
    withCredentials: true,
    headers: {
        common: {
            "X-Requested-With": "XMLHttpRequest"
        }
    }
});
