import React from 'react';
import {Truncate} from "../truncate/truncate";
import {Link} from "react-router-dom";
import './username.scss';

export const Username = (props) => {
    return (
        <Link to={`/profile/${props.userId}`} className='username'>
            {
                props.avatarUrl &&
                <img alt='avatar' src={props.avatarUrl} style={{width: `${props.avatarDimensions}px`, height: `${props.avatarDimensions}px`, marginRight: '5px'}}/>
            }
            {props.displayFull ?
                <div className='username--full'>{props.username}</div> :
                <div className='username--truncated'><Truncate width={props.width} text={props.username}/></div>}
        </Link>
    )
}

Username.defaultProps = {
    userId: 0,
    username: '',
    width: 200,
    displayFull: false,
    avatarUrl: '',
    avatarDimensions: 22
}
