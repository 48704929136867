const prefix = "user"

export const userApi = {
    me: `${prefix}/me`,
    getMentions: `${prefix}/mentions`,
    profile: `${prefix}/{userId}/profile`,
    getBanReasons: `${prefix}/banreasons`,
    ban: `${prefix}/{userId}/ban`,
    getVoteLogs: `${prefix}/{userId}/scores`
};
