import React, {Component} from "react";
import {DebounceInput} from 'react-debounce-input';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: ''
        }
    }

    static defaultProps = {
        maxChars: 40,
        itemSearch: "",
        placeholder: "Search",
        handleSearch: () => {
        }
    }

    handleOnChange = (newValue) => {
        this.setState({
            searchValue: newValue
        });

        this.props.handleSearch(newValue);
    }

    render() {
        return (
            <div className="search-input-container">
                <DebounceInput
                    debounceTimeout={200}
                    maxLength={this.props.maxChars}
                    value={this.state.searchValue}
                    onChange={(event) => this.handleOnChange(event.target.value)}
                    className="search-input"
                    placeholder={this.props.placeholder}
                />
            </div>
        )
    }
}

export default Search;
