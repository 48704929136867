import React, { Component } from "react";
class ItemCounter extends Component {
    static defaultProps = {
        itemCount: 0
    }

    render() {
        return (
            <div className="item-counter centered">Items: {this.props.itemCount}</div>
        )
    }
}

export default ItemCounter;
