import React, { Component } from "react";

class Spacer extends Component {

	static defaultProps = {
		paddingBottom: 0
	}

	render() {
		return (
			<div style={{paddingBottom: this.props.paddingBottom}}></div>
		)
	}
}

export default Spacer;