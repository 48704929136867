import React from "react";

import './postInfo.scss'
import TimeAgo from "react-timeago/lib";
import {timeUtils} from "../../../../../utilities/time";
import {Username} from "../../../../atoms/username/username";
import {breakpoints} from "../../../../../utilities/breakpoints";

export default function PostInfoComponent(props) {

    const renderDetails = () => {
        return (
            <>
                <div>
                    <i className="fas fa-calendar-day" title='Created'></i>
                    <TimeAgo
                        className='post-info__text'
                        minPeriod={60}
                        date={timeUtils.toLocalTime(props.post.submittedDateTime)}
                        title={`${new Date(props.post.submittedDateTime).toLocaleDateString()} ${new Date(props.post.submittedDateTime).toLocaleTimeString()}`}
                    />
                </div>

                <div>
                    <i className="fas fa-comments" title='Comments'></i>
                    <span className='post-info__text'>{props.post.commentCount}</span>
                </div>

                <div>
                    <i className="fas fa-hard-hat" title='Items'></i>
                    <span className='post-info__text'>{props.post.totalItemCount}</span>
                </div>
            </>
        )
    }

    const isMobile = breakpoints.isLessThan750();

    return (
        <div className="post-info__container">
            <div className='post-info__created-by'>
                <i className="fas fa-pencil-alt" title='Created By'></i>
                <span className='post-info__text'>
                    <Username width={200} username={props.post.createdBy} userId={props.post.createdById} displayFull={true} avatarUrl={props.post.createdByAvatarUrl}/>
                </span>
            </div>

            {
                isMobile ?
                    <div className='post-info__details--mobile'>
                        {renderDetails()}
                    </div>
                    :
                    renderDetails()
            }

            {
                props.showDeleteButton &&
                <div className='post-info__button' title='Delete Post' onClick={props.onDeleteClick}>Delete Post</div>
            }
        </div>
    )
}

PostInfoComponent.defaultProps = {
    onDeleteClick: () => {
    },
    showDeleteButton: false
}
