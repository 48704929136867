import {components} from "react-select";
import React from "react";
import {FixedSizeList} from 'react-window'

export const MultiValue = props => {
    return (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );
};

export const Option = props => {
    return (
        <components.Option {...props}>
            <input type="checkbox" checked={props.isSelected} onChange={() => {
            }}/>&nbsp;<label>{props.label}</label>
        </components.Option>
    );
};

export const ValueContainer = ({children, ...props}) => {
    const selectedCount = props.getValue().length
    const showCounter = (selectedCount < 2)

    let firstChild = []

    if (!showCounter)
        firstChild = [children[0].shift(), children[1]]

    return (
        <components.ValueContainer {...props}>
            {showCounter ? children : firstChild}
            {!showCounter && <span className="react-select__label-additional">+{selectedCount - 1}</span>}
        </components.ValueContainer>
    )
}

export const MenuList = ({options, children, maxHeight, getValue}) => {
    if (!children || !Array.isArray(children)) return null

    const height = 26;
    const selectedValues = getValue();
    const initialOffset = selectedValues[0] ? options.indexOf(selectedValues[0]) * height : 0

    return (
        <FixedSizeList
            width={''}
            itemSize={height}
            height={maxHeight}
            itemCount={children.length}
            initialScrollOffset={initialOffset}
        >
            {({index, style}) => (
                <div className="option-wrapper" style={style}>
                    {children[index]}
                </div>
            )}
        </FixedSizeList>
    )
}
