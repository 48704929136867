import React from 'react'
import TimeAgo from "react-timeago/lib";
import {timeUtils} from "../../../utilities/time";

export const
    TimeAgoWrapper = (props) => {
    const date = timeUtils.toLocalTime(props.date);

    return (
        <TimeAgo
            minPeriod={60}
            date={date}
            title={`${props.titlePrefix}${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`}
        />
    )
}

TimeAgoWrapper.defaultProps = {
    titlePrefix: '',
    date: new Date()
}
