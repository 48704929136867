import React from 'react'

import {CardInfo} from "../../../../atoms/cardInfo/cardInfo";
import {TimeAgoWrapper} from "../../../../atoms/timeAgoWrapper/timeAgoWrapper";

import './userCard.scss';
import {Link} from "react-router-dom";
import {constants} from "../../../../../utilities/constants";

export const UserCard = (props) => {
    const renderContent = () => {
        return (
            <>
                <div className={"item-details-image-container centered"}>
                    <div className="full-width">
                        <img
                            className="full-width"
                            src={props.userProfile.avatarUrl}
                            alt={props.userProfile.username}
                        />
                    </div>
                </div>
                <div className={"item-details-edit-text profile-page__card-text"}>
                    {
                        <div
                            className={`item-details-inline-textarea item-details-edit-name mb-1 full-height centered`}
                        >
                            {props.userProfile.username}
                        </div>
                    }
                </div>
            </>
        )
    }

    const render = () => {
        return (
            <div className='item-details-edit-container profile-page__card'>
                {
                    props.linkToProfile ?
                        <Link className='item-details' to={`${constants.pagePaths.profile}/${props.userProfile.id}`}>
                            {renderContent()}
                        </Link>
                        :
                        <div className='item-details'>
                            {renderContent()}
                        </div>
                }
                <CardInfo className='profile-page__card-info'>
                    <span className='profile-page__card-info-entry'>
                        <i className="fas fa-calendar-day"></i>&nbsp;
                        <TimeAgoWrapper
                            date={props.userProfile.createdDateTime}
                            titlePrefix='Member Since '
                        />
                    </span>
                    <span className='profile-page__card-info-entry' title={"Total Post Score"}><i className="fas fa-arrows-alt-v"></i>&nbsp;{props.userProfile.totalScore}</span>
                    <span className='profile-page__card-info-entry' title={"Comments Made"}><i className="fas fa-comments"></i>&nbsp;{props.userProfile.commentCount}</span>
                    <span className='profile-page__card-info-entry' title={"Posts Made"}><i className="fas fa-folder"></i>&nbsp;{props.userProfile.postCount}</span>
                    <span className='profile-page__card-info-entry' title={"Steam Profile"}><i className="fab fa-steam"></i>&nbsp;<a href={props.userProfile.steamProfileUrl} target='_blank' className='profile-page__card-link' rel='noopener noreferrer'>Steam Profile</a></span>
                </CardInfo>
            </div>
        )
    }

    return render();
}

UserCard.defaultProps = {
    userProfile: {},
    linkToProfile: false
}
