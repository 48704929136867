import React, {Component} from "react";
import CharacterButton from "../../atoms/buttons/characterButton";

class CharacterRow extends Component {

    static defaultProps = {
        tf2Classes: [],
        handleCharacterSelect: () => {
        },
        selectedClassName: null
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.tf2Classes.length > 0 &&
                    <div className="subtle-scrollbar centered">
                        <div className="animated fade-in-move-down row row-character">
                            {
                                this.props.tf2Classes.map((tf2Class) =>
                                    <CharacterButton
                                        key={tf2Class.id}
                                        tf2ClassName={tf2Class.name}
                                        handleCharacterSelect={this.props.handleCharacterSelect}
                                        selectedClassName={this.props.selectedClassName}
                                    />
                                )
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default CharacterRow;
