const prefix = "tf2/"

export const tf2Api = {
    getAllClasses: prefix + "classes",
    getSteamItemsByClassAndCategory: prefix + "GetSteamItemsForClassAndCategory",
    getFilteredItemCategories: prefix + "categories",
    getItem: prefix + "GetSteamItem",
    getQualities: prefix + "qualities",
    getStrangePrefixes: "strangePrefixes",
    getUnusualEffects: "unusualEffects"
}
