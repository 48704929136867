import {api, ENDPOINTS} from "../api";
import {toast} from "react-toastify";

const createComment = (newComment) => {
    return api.post(`${ENDPOINTS.comments.create}`, newComment)
        .then(resp => {
            toast.success("Successfully created the comment");
            return true;
        })
        .catch(error => {
            toast.error("Failed to create the comment");
            console.error("Error creating the comment: ", error);
            return false;
        });
};

const getCommentsForPost = (postId) => {
    return api.get(`${ENDPOINTS.comments.getForPost}/${postId}`)
        .then(resp => {
            resp.data = processCommentsResponse(resp);
            return resp.data;
        })
};

const getCommentsForUser = (userId) => {
    const url = ENDPOINTS.comments.getForUser.replace("{userId}", userId);
    return api.get(url)
        .then(resp => {
            resp.data = processCommentsResponse(resp);
            return resp.data;
        })
};

const processCommentsResponse = (resp) => {
    for (let i = 0; i < resp.data.length; i++) {
        if (!resp.data[i].isDeleted && resp.data[i].userId)
            resp.data[i].content = JSON.parse(resp.data[i].content);

        for (let j = 0; j < resp.data[i].childComments.length; j++) {
            if (!resp.data[i].childComments[j].isDeleted && resp.data[i].childComments[j].userId)
                resp.data[i].childComments[j].content = JSON.parse(resp.data[i].childComments[j].content);
        }
    }

    return resp.data;
}

const deleteComment = (postId) => {
    return api.delete(`${ENDPOINTS.comments.delete}/${postId}`)
        .then(resp => {
            toast.success("Successfully delete the comment");
            return resp.data;
        })
        .catch(error => {
            toast.error("Failed to delete the comment");
            console.error("Error deleting the comment: ", error);
            return false;
        });
};

const updateComment = (updatedComment) => {
    return api.put(`${ENDPOINTS.comments.update}`, updatedComment)
        .then(resp => {
            toast.success("Successfully updated the comment");
            return true;
        })
        .catch(error => {
            toast.error("Failed to updating the comment");
            console.error("Error updating the comment: ", error);
            return false;
        });
};


export const commentService = {
    createComment,
    getCommentsForPost,
    getCommentsForUser,
    deleteComment,
    updateComment
};
