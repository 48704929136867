import {api, ENDPOINTS} from "../../api/api";
import {toast} from "react-toastify";

export const SET_SEARCH_PREREQUISITES = "SET_SEARCH_PREREQUISITES";

const getSearchPrerequisites = () => (dispatch) => {
    api.get(ENDPOINTS.search.getPrerequisites)
        .then(resp => {
            const prerequisites = resp.data;

            setSearchPrerequisites(prerequisites)(dispatch);
        }).catch(error => {
        toast.error(`Failed to get search prerequisites`)
        console.error("Error getting prerequisites: ", error);
    })
};

const setSearchPrerequisites  = (prerequisites) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_PREREQUISITES,
        payload: prerequisites
    })
}

export const searchActions = {
    getSearchPrerequisites
}
