import React from "react";

import '../policy.scss';
import WithTopMenuTemplate from "../../../templates/withTopMenuTemplate";

export const PolicyPrivacy = (props) => {
    const renderSeparator = () => {
        return (
            <div className="policy__separator"/>
        );
    }

    const render = () => {
        return (
            <WithTopMenuTemplate>
                <div className="policy__layout">
                    <div className="policy__container">
                        <h1>Privacy Policy</h1>
                        <div>Last Updated: 21/05/2020</div>
                        {renderSeparator()}

                        <p>
                            It is TF2Tags's policy to respect your privacy regarding any information we may collect while operating our website.
                            This Privacy Policy applies to https://www.tags.tf (hereinafter, "us", "we", or "https://www.tags.tf").
                            We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website.
                            We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties.
                            This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
                        </p>
                        <p>
                            This Privacy Policy, together with the Terms and Conditions posted on our Website, set forth the general rules and policies governing your use of our Website.
                            Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
                        </p>

                        {renderSeparator()}
                        <h2>Website Visitors</h2>
                        <p>
                            Like most website operators, TF2Tags collects non-personally-identifying information of the sort that web browsers and servers typically make available,
                            such as the browser type, language preference, referring site, and the date and time of each visitor request.
                            TF2Tags's purpose in collecting non-personally identifying information is to better understand how TF2Tags's visitors use its website.
                            From time to time, TF2Tags may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
                        </p>

                        <p>
                            TF2Tags also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://www.tags.tf posts.
                            TF2Tags only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
                        </p>

                        {renderSeparator()}
                        <h2>Gathering of Personally-Identifying Information</h2>
                        <p>
                            Certain visitors to TF2Tags's websites choose to interact with TF2Tags in ways that require TF2Tags to gather personally-identifying information.
                            The amount and type of information that TF2Tags gathers depends on the nature of the interaction.
                        </p>

                        {renderSeparator()}
                        <h2>Security</h2>
                        <p>
                            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
                            While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                        </p>

                        {renderSeparator()}
                        <h2>Advertisements</h2>
                        <p>
                            Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.
                            These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer.
                            This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you.
                            This Privacy Policy covers the use of cookies by TF2Tags and does not cover the use of cookies by any advertisers.
                        </p>

                        {renderSeparator()}
                        <h2>Links To External Sites</h2>
                        <p>
                            Our Service may contain links to external sites that are not operated by us.
                            If you click on a third party link, you will be directed to that third party's site.
                            We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.
                        </p>
                        <p>
                            We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
                        </p>

                        {renderSeparator()}
                        <h2>Protection of Certain Personally-Identifying Information</h2>
                        <p>
                            TF2Tags discloses potentially personally-identifying and personally-identifying information only to those of its employees,
                            contractors and affiliated organizations that need to know that information in order to process it on TF2Tags's behalf or to provide services available at TF2Tags's website,
                            and that have agreed not to disclose it to others.
                            Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using TF2Tags's website, you consent to the transfer of such information to them.
                            TF2Tags will not rent or sell potentially personally-identifying and personally-identifying information to anyone.
                            Other than to its employees, contractors and affiliated organizations, as described above,
                            TF2Tags discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request,
                            or when TF2Tags believes in good faith that disclosure is reasonably necessary to protect the property or rights of TF2Tags, third parties or the public at large.
                        </p>

                        {renderSeparator()}
                        <h2>Aggregated Statistics</h2>
                        <p>
                            TF2Tags may collect statistics about the behavior of visitors to its website.
                            TF2Tags may display this information publicly or provide it to others.
                            However, TF2Tags does not disclose your personally-identifying information.
                        </p>

                        {renderSeparator()}
                        <h2>Cookies</h2>
                        <p>
                            To enrich and perfect your online experience, TF2Tags uses "Cookies", similar technologies and services provided by others to display personalized content,
                            appropriate advertising and store your preferences on your computer.
                        </p>
                        <p>
                            A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns.
                            TF2Tags uses cookies to help TF2Tags identify and track visitors, their usage of https://www.tags.tf, and their website access preferences.
                            TF2Tags visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using TF2Tags's websites,
                            with the drawback that certain features of TF2Tags's websites may not function properly without the aid of cookies.
                        </p>
                        <p>Please see our Cookie Policy.</p>

                        {renderSeparator()}
                        <h2>Privacy Policy Changes</h2>
                        <p>
                            Although most changes are likely to be minor, TF2Tags may change its Privacy Policy from time to time, and in TF2Tags's sole discretion.
                            TF2Tags encourages visitors to frequently check this page for any changes to its Privacy Policy.
                            Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
                        </p>
                    </div>
                </div>
            </WithTopMenuTemplate>
        )
    }

    return render();
}
