import React, {Component} from "react";
import {BasicModal, ModalSizes} from "./basicModal";

class ConfirmationModal extends Component {

    static defaultProps = {
        modalIsOpen: false,
        onClose: () => {
        },
        question: ""
    };

    localOnClose = (result) => {
        let sanitizedResult = false;

        if (typeof result === "boolean")
            sanitizedResult = result;

        this.props.onClose(sanitizedResult);
    };

    render() {
        return (
            <BasicModal
                modalIsOpen={this.props.modalIsOpen}
                onClose={this.localOnClose}
                modalSize={ModalSizes.small}
                className=''
                centerContent={true}
            >
                <>
                    {this.props.question}

                    <div className="modal__button-container">
                        <button type="button" className="modal__button" onClick={() => this.localOnClose(true)}>Yes</button>
                        <button type="button" className="modal__button" onClick={() => this.localOnClose(false)}>No</button>
                    </div>
                </>
            </BasicModal>
        )
    }
}

export default ConfirmationModal;
