import {constants} from "./constants";

const getPlaceholder = (quality, strangePrefix, translatedName) => {
    strangePrefix = quality === constants.qualities.strange && (!strangePrefix || strangePrefix === '') ? constants.strangePrefixes.strange : strangePrefix;

    quality = quality === constants.qualities.normal || quality === constants.qualities.unique || quality === constants.qualities.strange ? "" : `${quality} `;
    strangePrefix = !strangePrefix || strangePrefix === "" ? "" : `${strangePrefix} `

    return `${strangePrefix}${quality}${translatedName}`.toUpperCase();
};

const capitalizeFirstLetter = (text) => {
    const splits = text.split("-");
    let splitsParsed = [];
    for (let i = 0; i < splits.length; i++) {
        splitsParsed.push(splits[i][0].toUpperCase() + splits[i].slice(1));
    }

    if (splits.length > 1)
        return splitsParsed.join("-")

    return splitsParsed[0];
}

const getName = (name) => {
    return `"${name}"`;
}

export const nameDescriptionHelper = {
    getPlaceholder,
    getName,
    capitalizeFirstLetter
}
