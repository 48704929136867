import React, {Component} from "react";
import {connect} from "react-redux";
import ItemHoverButton from "./itemHoverButton"
import {postActions} from "../../../../redux/actions/postAction";
import {constants} from "../../../../utilities/constants";
import {toast} from "react-toastify";

class itemUnusualEffectSelectButton extends Component {

    static defaultProps = {
        unusualEffect: null,
        item: {}
    }

    mapQualityIdToQuality = (qualityId) => {
        if (qualityId === 6)
            return constants.qualities.unusual;

        if (qualityId === 10)
            return constants.qualities.community;

        if (qualityId === 11)
            return constants.qualities.selfMade;

        if (qualityId === 12)
            return constants.qualities.valve;
    }

    getClassName = () => {
        const quality = this.mapQualityIdToQuality(this.props.unusualEffect.qualityId);
        return `item-quality ${quality} ${this.props.isLoading ? 'item-quality--disabled' : ''}`
    }

    setTempItemState = (unusualEffect, isMouseEnter) => {
        if (this.props.isLoading)
            return;

        const {quality} = this.props.item;
        let tempQuality = quality;
        if (isMouseEnter
            && (quality !== constants.qualities.unusual
                || quality !== constants.qualities.community
                || quality !== constants.qualities.valve)) {
            tempQuality = this.mapQualityIdToQuality(unusualEffect.qualityId);
        }

        let tempItem = {
            ...this.props.item,
            tempQuality: tempQuality,
            tempStrangePrefix: '',
            displayTempUnusualEffect: isMouseEnter,
            tempUnusualEffectUrl: unusualEffect.url
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    setItemState = (unusualEffect) => {
        if (this.props.isLoading)
            return;

        const {quality} = this.props.item;
        const tempQuality = this.mapQualityIdToQuality(unusualEffect.qualityId); //Always do this as we might go from Unusual to Community and bacck

        if (quality !== constants.qualities.unusual
            && quality !== constants.qualities.community
            && quality !== constants.qualities.valve) {
            toast.info('Only Strange, Community, Self-Made and Valve quality items can have a Unusual Effects');
        }

        let tempItem = {
            ...this.props.item,
            strangePrefix: '',
            tempQuality: '',
            quality: tempQuality,
            displayTempUnusualEffect: false,
            tempUnusualEffectUrl: '',
            unusualEffectUrl: unusualEffect.url,
            unusualEffectId: unusualEffect.id
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    render() {
        return (
            <ItemHoverButton
                onMouseEnterParam={this.props.unusualEffect}
                onMouseEnter={(unusualEffect) => this.setTempItemState(unusualEffect, true)}
                onMouseLeaveParam=""
                onMouseLeave={(unusualEffect) => this.setTempItemState(unusualEffect, false)}
                onMouseClickParam={this.props.unusualEffect}
                onClick={this.setItemState}
                getClassName={this.getClassName}
            >
                <img
                    src={this.props.unusualEffect.url}
                    alt={this.props.unusualEffect.name}
                    style={{height: '90%'}}
                />
                <div>{this.props.unusualEffect.name}</div>
            </ItemHoverButton>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(itemUnusualEffectSelectButton);
