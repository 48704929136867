import React, {Component} from "react";
import Score from "./score/score";
import {Link} from "react-router-dom";
import ItemDetails from "./itemDetails";
import {CardInfo} from "./cardInfo/cardInfo";
import {TimeAgoWrapper} from "./timeAgoWrapper/timeAgoWrapper";
import {Username} from "./username/username";
import {constants} from "../../utilities/constants";
import {breakpoints} from "../../utilities/breakpoints";
import {QualityHelper} from "../../utilities/qualityHelper";

class PostHomePageView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    static defaultProps = {
        post: null,
        postScore: {},
        me: null,
        updateScore: () => {
        },
        toggleLoginModal: () => {
        },
        goToCreatePage: false,
        index: null,
        displayCount: false
    };

    renderTime = () => {
        return (
            <span>
                <i className="fas fa-calendar-day"></i>&nbsp;
                <TimeAgoWrapper
                    date={this.props.post.submittedDateTime || this.props.post.createdDateTime}
                />
            </span>
        );
    }

    renderScore = () => {
        if (this.props.post.isDraft)
            return null;

        return (
            <Score
                score={this.props.postScore.score}
                ratio={this.props.postScore.ratio}
                userScore={this.props.postScore.userScore}
                upvotes={this.props.postScore.upvotes}
                downvotes={this.props.postScore.downvotes}
                me={this.props.me}
                postId={this.props.post.id}
                updateScore={this.props.updateScore}
                toggleLoginModal={this.props.toggleLoginModal}
            />
        )
    }

    render() {
        if (!this.props.post)
            return null;

        const isMobile = breakpoints.isLessThan750();

        const url = this.props.goToCreatePage ? `${constants.pagePaths.createPost}/${this.props.post.id}` : `${constants.pagePaths.viewPost}/${this.props.post.id}`;
        return (
            <div className="row centered pb-4">
                {
                    this.props.index && !isMobile && this.props.displayCount &&
                    <div className='home-page-post__index'>{this.props.index}</div>
                }
                <div className={'home-page-post item-details-edit-container ' + QualityHelper.sanitizeQuality(this.props.post.thumbnail.quality)}>
                    <Link to={url} className='item-details'>
                        <ItemDetails item={this.props.post.thumbnail} index={this.props.index} displayCount={this.props.displayCount} isSet={this.props.post.totalItemCount > 1}/>
                    </Link>
                    <CardInfo quality={QualityHelper.sanitizeQuality(this.props.post.thumbnail.quality)}>
                        <span style={{display: 'flex', alignItems: 'center'}} title={`Created by ${this.props.post.createdBy}`}>
                            <i className="fas fa-pencil-alt"></i>&nbsp;
                            <Username width={isMobile ? 150 : 250} username={this.props.post.createdByBaseUsername} userId={this.props.post.createdById} avatarUrl={this.props.post.createdByAvatarUrl}/>
                            </span>
                        <span title={"Items"}><i className="fas fa-hard-hat"></i> {this.props.post.totalItemCount}</span>
                        <span title={"Comments"}><i className="fas fa-comments"></i> {this.props.post.commentCount}</span>
                        {!isMobile && this.renderTime()}
                    </CardInfo>
                </div>
                {this.renderScore()}
            </div>

        )
    }
}


export default PostHomePageView;
