import React, {useState, useEffect} from 'react';
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import './leaderboards.scss';
import ItemEditOptionButton from "../../atoms/buttons/itemEdit/itemEditOptionButton";
import {constants} from "../../../utilities/constants";
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";
import {PostsInfiniteScroll} from "../../organisms/postsInfiniteScroll/postsInfiniteScroll";
import {useSelector} from "react-redux";
import {UserLeaderboardInfiniteScroll} from "./userInfiniteScroll/userLeaderboardInfiniteScroll";
import {ItemLeaderboardInfiniteScroll} from "./itemInfiniteScroll/itemLeaderboardInfiniteScroll";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../atoms/loading-spinner/loadingSpinner";
import {scoreHelpers} from "../../../utilities/scoreHelpers";

export const Leaderboards = (props) => {
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedLeaderboardCategory, setSelectedLeaderboardCategory] = useState(constants.leaderboardActions.posts);
    const [selectedLeaderboardType, setSelectedLeaderboardType] = useState({name: 'Most Critted', id: 4});
    const [selectedTimeFrame, setSelectedTimeFrame] = useState({name: 'the Last 24h', id: 1});
    const [lastGoRecord, setLastGoRecord] = useState({});

    const [isLoadingInitialLeaderboard, setIsLoadingInitialLeaderboard] = useState(true);

    const [posts, setPosts] = useState([]);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const postsTakeCount = 25;

    const [users, setUsers] = useState([]);
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const usersTakeCount = 50;

    const [items, setItems] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const itemsTakeCount = 50;

    const me = useSelector(state => state.authentication.me);

    const [goTime, setGoTime] = useState(new Date().toJSON());

    useEffect(() => {
        setLastGoRecordInternal();

        fetchMorePosts();
    }, []);

    const setSelectedActionIntermediate = (newAction) => {
        if (newAction === selectedAction)
            setSelectedAction(null);
        else {
            setSelectedAction(newAction);

            const {time, go} = constants.leaderboardActions;
            if (newAction !== time && newAction !== go) {
                setSelectedLeaderboardCategory(newAction);
                setSelectedLeaderboardType(null);
            }
        }
    }

    const fetchMorePosts = () => {
        const skip = posts.length;
        const take = postsTakeCount;
        fetchPosts(skip, take, posts, goTime);
    }


    const getFullUrl = (baseUrl, skip, take, endTime) => {
        const skipQuery = `skip=${skip}`;
        const takeQuery = `take=${take}`;
        const endTimeQuery = `endTime=${endTime}`
        const typeQuery = `leaderboardType=${selectedLeaderboardType.id}`
        const timeFrameQuery = `timeFrame=${selectedTimeFrame.id}`;

        const url = `${baseUrl}?${skipQuery}&${takeQuery}&${endTimeQuery}&${typeQuery}&${timeFrameQuery}`;
        return url;
    }

    const fetchPosts = (skip, take, existingPosts, endTime) => {
        const url = getFullUrl(ENDPOINTS.leaderboards.posts, skip, take, endTime);
        api.get(url)
            .then(resp => {
                let newPosts = [...existingPosts];
                newPosts = newPosts.concat(resp.data.posts);
                setPosts(newPosts);
                setHasMorePosts(resp.data.hasMore);
            })
            .catch(ex => {
                toast.error('Failed to load the leaderboard');
                console.error('Failed to load the leaderboard: ', ex);
            })
            .finally(() => {
                setIsLoadingInitialLeaderboard(false);
            });
    }

    const fetchMoreUsers = () => {
        const skip = users.length;
        const take = usersTakeCount;
        fetchUsers(skip, take, users, goTime);
    }

    const fetchUsers = (skip, take, existingUsers, endTime) => {
        const url = getFullUrl(ENDPOINTS.leaderboards.users, skip, take, endTime);
        api.get(url)
            .then(resp => {
                let newUsers = [...existingUsers];
                newUsers = newUsers.concat(resp.data.users);
                setUsers(newUsers);
                setHasMoreUsers(resp.data.hasMore);
            })
            .catch(ex => {
                toast.error('Failed to load the leaderboard');
                console.error('Failed to load the leaderboard: ', ex);
            })
            .finally(() => {
                setIsLoadingInitialLeaderboard(false);
            });
    }


    const fetchMoreItems = () => {
        const skip = items.length;
        const take = itemsTakeCount;
        fetchItems(skip, take, items, goTime);
    }

    const fetchItems = (skip, take, existingItems, endTime) => {
        const url = getFullUrl(ENDPOINTS.leaderboards.items, skip, take, endTime);
        api.get(url)
            .then(resp => {
                let newUsers = [...existingItems];
                newUsers = newUsers.concat(resp.data.items);
                setItems(newUsers);
                setHasMoreItems(resp.data.hasMore);
            })
            .catch(ex => {
                toast.error('Failed to load the leaderboard');
                console.error('Failed to load the leaderboard: ', ex);
            })
            .finally(() => {
                setIsLoadingInitialLeaderboard(false);
            });
    }

    const handleGoClick = () => {
        if (!selectedLeaderboardType) {
            toast.info('Please select a leaderboard');
            return;
        }

        const searchHasChanged = getHasSearchChanged();

        if (!searchHasChanged)
            return;

        setSelectedActionIntermediate(constants.leaderboardActions.go);
        setIsLoadingInitialLeaderboard(true);

        const newGoTime = (new Date).toJSON();
        setGoTime(newGoTime);

        if (searchHasChanged)
            setPosts([]);

        if (selectedLeaderboardCategory === constants.leaderboardActions.posts)
            fetchPosts(0, postsTakeCount, [], newGoTime);

        if (selectedLeaderboardCategory === constants.leaderboardActions.users)
            fetchUsers(0, usersTakeCount, [], newGoTime);

        if (selectedLeaderboardCategory === constants.leaderboardActions.items)
            fetchItems(0, usersTakeCount, [], newGoTime);

        setLastGoRecordInternal();
    }

    const setLastGoRecordInternal = () => {
        setLastGoRecord({
            leaderboardTypeName: selectedLeaderboardType.name,
            timeFrame: selectedTimeFrame.name,
            category: selectedLeaderboardCategory
        })
    }

    const renderOptionsRow = () => {
        return (
            <div className='leaderboards__options-row'>
                <ItemEditOptionButton
                    selectedAction={selectedAction}
                    actionName={constants.leaderboardActions.posts}
                    setAction={setSelectedActionIntermediate}
                    title='Posts'
                    isLoading={false}
                    isProfileIcon={true}
                />
                <ItemEditOptionButton
                    selectedAction={selectedAction}
                    actionName={constants.leaderboardActions.users}
                    setAction={setSelectedActionIntermediate}
                    title='Users'
                    isLoading={false}
                    isProfileIcon={true}
                />
                <ItemEditOptionButton
                    selectedAction={selectedAction}
                    actionName={constants.leaderboardActions.items}
                    setAction={setSelectedActionIntermediate}
                    title='Items'
                    isLoading={false}
                    isProfileIcon={true}
                />
                <div className='leaderboards__options-row-section'>
                    <ItemEditOptionButton
                        selectedAction={selectedAction}
                        actionName={constants.leaderboardActions.time}
                        setAction={setSelectedActionIntermediate}
                        title='Time'
                        isLoading={false}
                        isProfileIcon={true}
                    />
                </div>
                <div className='leaderboards__options-row-section'>
                    <ItemEditOptionButton
                        selectedAction={selectedAction}
                        actionName={constants.leaderboardActions.go}
                        onClick={handleGoClick}
                        title='Get Leaderboard'
                        isLoading={false}
                        isProfileIcon={true}
                    />
                </div>
            </div>
        )
    }

    const postLeaderboards = [
        // {name: 'Most Critted', id: 1},
        // {name: 'Most Missed', id: 2},
        {name: 'Most Commented', id: 3},
        {name: 'Most Critted', id: 4},
        {name: 'Most Missed', id: 5},
        {name: 'Most Controversial', id: 6},
    ];

    const renderPosts = () => {
        return (
            <div className='leaderboards__options animated fade-in-move-down'>
                {
                    postLeaderboards.map((postLeaderboard, index) =>
                        <div className='leaderboards__option' onClick={() => setSelectedLeaderboardType(postLeaderboard)} key={index}>
                            {postLeaderboard.name}
                        </div>
                    )
                }
            </div>
        )
    }

    const steamItems = [
        {name: 'Most Used', id: 1},
        {name: 'Least Used', id: 2},
    ]

    const renderSteamItems = () => {
        return (
            <div className='animated fade-in-move-down leaderboards__options'>
                {steamItems.map((steamItem, index) =>
                    <div className='leaderboards__option' onClick={() => setSelectedLeaderboardType(steamItem)} key={index}>
                        {steamItem.name}
                    </div>
                )}
            </div>
        )
    }

    const times = [
        {name: 'the Last 24h', id: 1},
        {name: 'the Last Week', id: 2},
        {name: 'the Last Month', id: 3},
        {name: 'the Last Year', id: 4},
        {name: 'All Time', id: 5},
    ]

    const renderTimes = () => {
        return (
            <div className='animated fade-in-move-down leaderboards__options'>
                {times.map((time, index) =>
                    <div className='leaderboards__option' onClick={() => setSelectedTimeFrame(time)} key={index}>
                        {time.name}
                    </div>
                )}
            </div>
        )
    }

    const userLeaderboards = [
        {name: constants.leaderboardActions.userLeaderboards.mostCommentsMade, id: 1},
        {name: constants.leaderboardActions.userLeaderboards.mostPostsMade, id: 2},
        {name: constants.leaderboardActions.userLeaderboards.mostScoreEarned, id: 3},
        {name: constants.leaderboardActions.userLeaderboards.mostScoreLost, id: 4},
        {name: constants.leaderboardActions.userLeaderboards.tagsTfSupporters, id: 5},
    ];

    const renderUsers = () => {
        return (
            <div className='animated fade-in-move-down leaderboards__options'>
                {userLeaderboards.map((userLeaderboard, index) =>
                    <div className='leaderboards__option' onClick={() => setSelectedLeaderboardType(userLeaderboard)} key={index}>
                        {userLeaderboard.name}
                    </div>
                )}
            </div>
        )
    }

    const renderOptions = () => {
        if (!selectedAction)
            return null;

        if (selectedAction === constants.leaderboardActions.posts)
            return renderPosts();

        if (selectedAction === constants.leaderboardActions.users)
            return renderUsers();

        if (selectedAction === constants.leaderboardActions.items)
            return renderSteamItems();

        if (selectedAction === constants.leaderboardActions.time)
            return renderTimes();
    }

    const getHasSearchChanged = () => {
        if (!selectedLeaderboardType || lastGoRecord.leaderboardTypeName !== selectedLeaderboardType.name)
            return true;
        else if (lastGoRecord.timeFrame !== selectedTimeFrame.name)
            return true;
        else if (lastGoRecord.category !== selectedLeaderboardCategory)
            return true;

        return false;
    }

    const getTitle = () => {
        const category = selectedLeaderboardCategory;
        const type = selectedLeaderboardType ? selectedLeaderboardType.name : '___';
        const timeFrame = selectedTimeFrame.name;
        let hasChangedSinceLastSearch = getHasSearchChanged();

        if (selectedLeaderboardType && selectedLeaderboardType.name === constants.leaderboardActions.userLeaderboards.tagsTfSupporters)
            return `${type} in ${timeFrame}${hasChangedSinceLastSearch ? '*' : ''}`;

        return `${type} ${category} in ${timeFrame}${hasChangedSinceLastSearch ? '*' : ''}`;

    }

    const renderPostsLeaderboard = () => {
        return (
            <PostsInfiniteScroll
                posts={posts}
                updateLocalPostScore={updateLocalPostScore}
                fetchNextPosts={fetchMorePosts}
                hasMore={hasMorePosts}
                me={me}
                endOfPostsMessage="Congratulations, you've seen every single post on this leaderboard! Help us grow our collection by creating more posts."
                displayCount={true}
            />
        )
    }

    const renderUsersLeaderboard = () => {
        return (
            <UserLeaderboardInfiniteScroll
                users={users}
                leaderboardType={lastGoRecord.leaderboardTypeName}
                hasMore={hasMoreUsers}
                fetchNext={fetchMoreUsers}
                endMessage="Congratulations, you've seen every single user on this leaderboard!"
            />
        )
    }

    const updateLocalPostScore = (newScore, postId) => {
        const existingPosts = [...posts];
        const newPosts = scoreHelpers.updateLocalPostScore(newScore, postId, existingPosts);

        setPosts(newPosts);
    }

    const renderItemLeaderboard = () => {
        return (
            <ItemLeaderboardInfiniteScroll
                items={items}
                hasMore={hasMoreItems}
                fetchNext={fetchMoreItems}
                endMessage="Congratulations, you've seen every single item in our collection!"
            />
        )
    }

    const renderLoading = () => {
        return (
            <LoadingSpinner size={LoadingSpinnerSizes.medium} show={true}/>
        )
    }

    const renderLeaderboard = () => {
        if (isLoadingInitialLeaderboard)
            return renderLoading();

        if (lastGoRecord.category === constants.leaderboardActions.posts)
            return renderPostsLeaderboard();
        if (lastGoRecord.category === constants.leaderboardActions.users)
            return renderUsersLeaderboard();
        if (lastGoRecord.category === constants.leaderboardActions.items)
            return renderItemLeaderboard();
    }

    const render = () => {
        const title = getTitle();
        return (
            <WithTopMenuTemplate>
                <div className='leaderboards__layout'>
                    <div className='leaderboards__header'>
                        <div>{title}</div>
                        {renderOptionsRow()}
                        {renderOptions()}
                    </div>

                    {renderLeaderboard()}
                </div>
            </WithTopMenuTemplate>
        )
    }

    return render();
}
