import React from "react";

import "./votePanel.scss";
import Score from "../../../../atoms/score/score";
import {breakpoints} from "../../../../../utilities/breakpoints";

const VotePanel = (props) => {
    return (
        <div className="vote-panel__container">
            {
                props.post ?
                    <Score
                        score={props.post.score.score}
                        ratio={props.post.score.ratio}
                        userScore={props.post.score.userScore}
                        upvotes={props.post.score.upvotes}
                        downvotes={props.post.score.downvotes}
                        handleArrowClick={props.handlePostScoreClick}
                        center={true}
                        updateScore={props.updatePostScore}
                        me={props.me}
                        postId={props.post.id}
                        toggleLoginModal={props.toggleLoginModal}
                        displayHorizontal={breakpoints.isLessThan750()}
                    /> :
                    null
            }

        </div>
    )
}

VotePanel.defaultProps = {
    post: null,
    updatePostScore: () => {},
    me: {},
    toggleLoginModal: () => {}
}

export default VotePanel;
