import React, {Component} from "react";
import {nameDescriptionHelper} from "../../../utilities/nameDescriptionHelper";

class CharacterButton extends Component {

    static defaultProps = {
        tf2ClassName: null,
        availableItems: [],
        selectedClassName: null,
        handleCharacterSelect: () => {
        }
    }

    getClassIcon = () => {
        let grey = (this.props.selectedClassName === this.props.tf2ClassName ? "" : "character--grey");
        return `character ${this.props.tf2ClassName} ${grey}`;
    }

    render() {
        return (
            <div
                title={nameDescriptionHelper.capitalizeFirstLetter(this.props.tf2ClassName)}
                className={this.getClassIcon()}
                onClick={() => this.props.handleCharacterSelect(this.props.tf2ClassName)}/>
        )
    }
}

export default CharacterButton;
