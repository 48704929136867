import React, {Component} from "react";
import ModalNpm from 'react-modal';
import './modal.scss'

ModalNpm.setAppElement('#app-root');

export class BasicModal extends Component {

    static defaultProps = {
        modalIsOpen: false,
        onClose: () => {
        },
        modalSize: 'sm',
        centerContent: false,
        className: ''
    };

    render() {
        let modalSize = `modal__body--${this.props.modalSize}`;
        let centeredContent = this.props.centerContent ? 'modal__body--centered' : '';
        return (
            <ModalNpm
                className={`modal__body ${modalSize} ${centeredContent} ${this.props.className}`}
                overlayClassName="modal__overlay"
                isOpen={this.props.modalIsOpen}
                onRequestClose={this.props.onClose}
                shouldCloseOnOverlayClick={true}
            >
                {this.props.children}
            </ModalNpm>
        )
    }
}

export const ModalSizes = {
    small: 'sm',
    medium: 'md',
    large: 'lg'
};

