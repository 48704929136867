import Select from 'react-select'
import React from 'react';

import {MultiValue, ValueContainer, Option} from "./selectCustom";
import './selectWrapper.scss'

export const MultiSelectWrapper = (props) => {
    const render = () => {

        const onChangeInternal = (newValue) => {
            // Prevent unchecking Any
            if (newValue.length === 0)
                return props.onChange(props.options.filter(x => x.value === "*"));

            // Check if Any is currently selected. If it is, and if we're adding a new value,
            // uncheck Any and return the remaining values
            if (props.selectedValues.length === 1 && props.selectedValues[0].value === "*")
                return props.onChange([newValue[1]]);

            // Check if Any is among the selected values. If it is, return only Any.
            for (let i = 0; i < newValue.length; i++) {
                const value = newValue[i];

                if (value.value === "*") {
                    return props.onChange([value]);
                }
            }

            // If all values are selected, return only any
            if (newValue.length === props.options.length - 1)
                return props.onChange(props.options.filter(x => x.value === "*"));

            return props.onChange(newValue);
        }

        const selectedValueIsAny = () => {
            return props.selectedValues.length === 1 && props.selectedValues[0].value === "*";
        }

        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                components={{Option, MultiValue, ValueContainer}}
                isMulti={true}
                value={props.selectedValues}
                name={props.name}
                id={props.id}
                isSearchable={true}
                options={props.options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={!selectedValueIsAny()}
                onChange={onChangeInternal}
            />);
    }

    return render();
}

MultiSelectWrapper.defaultProps = {
    options: [],
    name: "",
    id: "",
    onChange: () => {
    },
    selectedValues: []
}
