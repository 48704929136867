import React, {useState} from 'react';
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import './searchPage.scss'
import {UserSearch} from "./user/userSearch";
import {PostSearch} from "./post/postSearch";
import {constants} from "../../../utilities/constants";
import ItemEditOptionButton from "../../atoms/buttons/itemEdit/itemEditOptionButton";
import {api, ENDPOINTS} from "../../../api/api";
import {PostsInfiniteScroll} from "../../organisms/postsInfiniteScroll/postsInfiniteScroll";
import {scoreHelpers} from "../../../utilities/scoreHelpers";
import {toast} from "react-toastify";
import {UsersInfiniteScroll} from "../../organisms/usersInfiniteScroll/usersInfiniteScroll";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";
import {useSelector} from "react-redux";

export const SearchPage = (props) => {
    const [selectedAction, setSelectedAction] = useState(constants.searchActions.posts);

    const [haveSearched, setHaveSearched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingReset, setIsLoadingReset] = useState(false);

    const [postForm, setPostForm] = useState({});
    const [posts, setPosts] = useState([]);
    const [postPage, setPostPage] = useState(0);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const [postLoadTime, setPostLoadTime] = useState(new Date());

    const [userForm, setUserForm] = useState({});
    const [users, setUsers] = useState([]);
    const [userPage, setUserPage] = useState(0);
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [userLoadTime, setUserLoadTime] = useState(new Date());

    const [lastSearch, setLastSearch] = useState(null);

    const me = useSelector(state => state.authentication.me);

    const setSelectedActionIntermediate = (newAction) => {
        if (newAction === selectedAction)
            setSelectedAction(null);
        else
            setSelectedAction(newAction);
    }

    const searchUser = () => {
        if (isLoading)
            return;

        setSelectedAction(null);

        const currentTime = new Date();
        setUserLoadTime(currentTime);

        userForm.startTime = currentTime.toJSON();
        setUserPage(0);

        !haveSearched && setHaveSearched(true);
        setUsers([]);
        getUsers(10, 0, userForm, true)
    }

    const getUsers = (take, skip, userFormData, resetUsers) => {
        if (isLoading)
            return;

        setIsLoading(true);
        setLastSearch(constants.searchActions.users);
        userFormData.take = take;
        userFormData.skip = skip;
        userFormData.startTime = userLoadTime.toJSON();

        api.post(`${ENDPOINTS.search.user}`, userFormData)
            .then(resp => {
                const data = resp.data;
                debugger;
                if (resetUsers) {
                    setUsers(resp.data.users);
                    setUserPage(1);
                    setIsLoadingReset(false);
                } else {
                    const tempUsers = [...users];
                    const newUsers = tempUsers.concat(resp.data.users);
                    setUsers(newUsers);
                    setUserPage(userPage + 1);
                }

                setHasMoreUsers(data.hasMore);
            })
            .catch(ex => {
                toast.error('Failed to load search results');
                console.error('Failed to load search results', ex)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getPosts = (take, skip, postFormData, resetPosts) => {
        if (isLoading)
            return;

        setIsLoading(true);
        setLastSearch(constants.searchActions.posts);
        postFormData.take = take;
        postFormData.skip = skip;
        postForm.startTime = postLoadTime.toJSON();

        api.post(`${ENDPOINTS.search.post}`, postForm)
            .then(resp => {
                const data = resp.data;
                if (resetPosts) {
                    setPosts(resp.data.posts);
                    setPostPage(1);
                    setIsLoadingReset(false);
                } else {
                    const tempPosts = [...posts];
                    const newPosts = tempPosts.concat(resp.data.posts);
                    setPosts(newPosts);
                    setPostPage(postPage + 1);
                }

                setHasMorePosts(data.hasMore);
            })
            .catch(ex => {
                toast.error('Failed to load search results');
                console.error('Failed to load search results', ex)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const searchPost = () => {
        if (isLoading)
            return;

        setSelectedAction(null);
        setIsLoadingReset(true);

        const currentTime = new Date();
        setPostLoadTime(currentTime);
        
        console.log(postForm);

        postForm.startTime = currentTime.toJSON();
        setPostPage(0);

        !haveSearched && setHaveSearched(true);
        setPosts([]);
        getPosts(10, 0, postForm, true)
    }

    const updateLocalPostScore = (newScore, postId) => {
        const existingPosts = [...posts];
        const newPosts = scoreHelpers.updateLocalPostScore(newScore, postId, existingPosts);

        setPosts(newPosts);
    }

    const handleGoClick = () => {
        if (selectedAction === constants.searchActions.users)
            searchUser()
        else if (selectedAction === constants.searchActions.posts)
            searchPost()
        else {
            if (lastSearch === constants.searchActions.users)
                searchUser();
            else if (lastSearch === constants.searchActions.posts)
                searchPost()
        }
    }

    const render = () => {
        return (
            <WithTopMenuTemplate>
                <div className="search__header">
                    <div className="search__options">
                        <ItemEditOptionButton
                            selectedAction={selectedAction}
                            actionName={constants.searchActions.posts}
                            setAction={setSelectedActionIntermediate}
                            title='Search Posts'
                            isLoading={isLoading}
                            isProfileIcon={true}
                        />

                        <ItemEditOptionButton
                            selectedAction={selectedAction}
                            actionName={constants.searchActions.users}
                            setAction={setSelectedActionIntermediate}
                            title='Search Users'
                            isLoading={isLoading}
                            isProfileIcon={true}
                        />

                        <div style={{paddingLeft: "20px"}}>
                            <ItemEditOptionButton
                                selectedAction={selectedAction}
                                actionName={constants.searchActions.go}
                                onClick={handleGoClick}
                                title='Search'
                                isLoading={isLoading}
                                isProfileIcon={true}
                            />
                        </div>
                    </div>
                    <div className='search__area search__area--collapsed' hidden={selectedAction !== constants.searchActions.posts}>
                        <PostSearch onChange={setPostForm} search={searchPost}/>
                    </div>
                    <div className='search__area-container' hidden={selectedAction !== constants.searchActions.users}>
                        <div className='search__area search__area--user search__area--collapsed' hidden={selectedAction !== constants.searchActions.users}>
                            <UserSearch onChange={setUserForm} search={searchUser}/>
                        </div>
                    </div>
                </div>
                <div hidden={!isLoadingReset}>
                    <div className="search__results">
                        <PostSkeleton/>
                    </div>
                </div>
                <div hidden={!haveSearched || isLoadingReset}>
                    <div className='search__results'>
                        {
                            lastSearch === constants.searchActions.posts &&
                            <PostsInfiniteScroll
                                posts={posts}
                                updateLocalPostScore={(newScore, postId) => updateLocalPostScore(newScore, postId)}
                                fetchNextPosts={() => getPosts(10, postPage * 10, postForm)}
                                hasMore={hasMorePosts}
                                me={me}
                                goToCreatePage={false}
                                endOfPostsMessage={posts && posts.length > 0 ? `Congratulations, you've seen every single result for your search!` : `There are no posts matching your search criteria.`}
                            />
                        }
                        {
                            lastSearch === constants.searchActions.users &&
                            <UsersInfiniteScroll
                                profiles={users}
                                fetchNext={() => getUsers(10, userPage * 10, userForm)}
                                hasMore={hasMoreUsers}
                                endMessage={users && users.length > 0 ? `Congratulations, you've seen every single result for your search!` : `There are no users matching your search criteria.`}
                            />
                        }
                    </div>
                </div>
            </WithTopMenuTemplate>
        )
    }

    return render();
}
