import React, {Component} from "react";
import {connect} from "react-redux";
import * as Constants from "../../../utilities/constants";
import ItemEditOptionButton from "../../atoms/buttons/itemEdit/itemEditOptionButton";
import ItemRemoveButton from "../../atoms/buttons/itemEdit/itemRemoveButton";

class ItemEditOptionsRow extends Component {

    static defaultProps = {
        selectedAction: "",
        setAction: () => {
        },
        item: {},
        postItemCount: 0
    }

    render() {
        const itemSlotsWithUnusuals = [3, 4, 5, 8];
        const hasUnusuals = itemSlotsWithUnusuals.some(x => x === this.props.item.itemSlot);
        console.log(this.props.item);

        return (
            <div className="centered subtle-scrollbar">
                <div className="centered row row row-category row-scroll animated fade-in-move-down">
                    <ItemEditOptionButton
                        title='Strange Prefixes'
                        selectedAction={this.props.selectedAction}
                        actionName={Constants.itemEditActions.strangePrefixes}
                        setAction={this.props.setAction}
                        isLoading={this.props.isLoading}
                    />
                    <ItemEditOptionButton
                        title='Qualities'
                        selectedAction={this.props.selectedAction}
                        actionName={Constants.itemEditActions.qualities}
                        setAction={this.props.setAction}
                        isLoading={this.props.isLoading}
                    />
                    {
                        hasUnusuals &&
                        <ItemEditOptionButton
                            title='Unusual Effects'
                            selectedAction={this.props.selectedAction}
                            actionName={Constants.itemEditActions.unusualEffects}
                            setAction={this.props.setAction}
                            isLoading={this.props.isLoading}
                        />
                    }
                    <ItemRemoveButton
                        title='Delete'
                        item={this.props.item}
                        isLoading={this.props.isLoading}
                        postItemCount={this.props.postItemCount}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(ItemEditOptionsRow);
