import React, {Component} from "react";
import {connect} from "react-redux";
import * as Constants from "../../../utilities/constants";
import ItemUnusualEffectSelectButton from "../../atoms/buttons/itemEdit/itemUnusualEffectSelectButton"
import {breakpoints} from "../../../utilities/breakpoints";

class ItemUnusualEffectsSection extends Component {

    static defaultProps = {
        selectedAction: "",
        unusualEffects: [],
        item: {},
        isLoading: false
    }

    render() {
        if (this.props.selectedAction !== Constants.itemEditActions.unusualEffects)
            return null;

        const isMobile = breakpoints.isLessThan750();

        return (
            <div className={`${isMobile ? '' : 'mt-3'} animated fade-in-move-down row row-item row-section`}
                 style={{marginBottom: `75px`}}>
                {
                    this.props.unusualEffects.map((unusualEffect) =>
                        <ItemUnusualEffectSelectButton
                            key={unusualEffect.id}
                            unusualEffect={unusualEffect}
                            item={this.props.item}
                            isLoading={this.props.isLoading}
                        />
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let unusualEffects = state.general.unusualEffects;

    if (ownProps.item.itemSlot === 4 || ownProps.item.itemSlot === 5) // Primary + Secondary
        unusualEffects = unusualEffects.filter(x => x.unusualCategory === 0 || x.unusualCategory === 3);
    else if (ownProps.item.itemSlot === 8) // Taunt
        unusualEffects = unusualEffects.filter(x => x.unusualCategory === 1 || x.unusualCategory === 3);
    else if (ownProps.item.itemSlot === 3) // Cosmetics
        unusualEffects = unusualEffects.filter(x => x.unusualCategory === 2 || x.unusualCategory === 3);
    else
        unusualEffects = [];

    return {
        unusualEffects: unusualEffects
    };
};

export default connect(mapStateToProps, null)(ItemUnusualEffectsSection);
