import React, {Component} from "react";
import {nameDescriptionHelper} from "../../utilities/nameDescriptionHelper";
import {breakpoints} from "../../utilities/breakpoints";
import {QualityHelper} from "../../utilities/qualityHelper";

class ItemDetails extends Component {
    static defaultProps = {
        item: null,
        displayCount: false
    };

    getNameOrPlaceholder = () => {
        if (!this.props.item.name) {
            return nameDescriptionHelper.getPlaceholder(this.props.item.quality, this.props.item.strangePrefix, this.props.item.translatedName);
        }

        return this.setBrackets(this.props.item.name);
    }

    setBrackets = (text) => {
        return nameDescriptionHelper.getName(text);
    }

    render() {
        if (!this.props.item)
            return null;

        const isMobile = breakpoints.isLessThan750();

        return (
            <>
                <div className={"item-details-image-container centered " + QualityHelper.sanitizeQuality(this.props.item.quality)}>
                    {
                        isMobile && this.props.index && this.props.displayCount &&
                        <div className={`${this.props.item.quality} item-details__index`}>{this.props.index}</div>
                    }
                    {this.props.isSet && <div className='item-details--is-set'><i className="fas fa-clone"></i></div>}
                    <div className="full-width">
                        <img
                            className="full-width"
                            style={{backgroundImage: this.props.item.unusualEffectUrl ? `url(${this.props.item.unusualEffectUrl})` : 'none', backgroundRepeat: 'no-repeat', backgroundSize: '100%'}}
                            src={this.props.item.imageUrlLarge}
                            alt={this.props.item.translatedName}
                        />
                    </div>
                </div>
                <div className={"item-details-edit-text"}>
                    {
                        <div
                            className={`item-details-inline-textarea item-details-edit-name mb-1 ${ QualityHelper.sanitizeQuality(this.props.item.quality)} ${(!this.props.item.description ? "full-height centered" : "")}`}
                        >
                            {this.getNameOrPlaceholder()}
                        </div>
                    }
                    {(this.props.item.description ?
                        <div className={"item-details-inline-textarea item-details-edit-description mb-1"}>
                            {!this.props.item.description ? "" : this.setBrackets(this.props.item.description)}
                        </div>
                        : null)}
                </div>
            </>

        )
    }
}

export default ItemDetails;
