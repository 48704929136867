import React, {Component} from "react";
import {connect} from "react-redux";
import {postActions} from "../../redux/actions/postAction";
import {nameDescriptionHelper} from "../../utilities/nameDescriptionHelper";
import {breakpoints} from "../../utilities/breakpoints";
import {constants} from "../../utilities/constants";
import {QualityHelper} from "../../utilities/qualityHelper";

class ItemDetailsEdit extends Component {
    static defaultProps = {
        item: null,
        toggleDisplayState: () => {
        },
        displayState: false,
        isReordering: false,
        isLoading: false
    }

    handleTextChange = (value, type) => {
        let tempItem = {
            ...this.props.item
        };

        if (type === "description") {
            tempItem.description = value;
        } else {
            tempItem.name = value;
        }

        this.props.dispatch(postActions.updatePostItem(tempItem));
    }

    currentVisibleQuality = () => {
        return this.currentVisible("normal", this.props.item.tempQuality, this.props.item.quality);
    }

    currentVisible = (defaultResult, temp, perm) => {
        let result = defaultResult;

        if (temp && temp !== "") {
            result = temp;
        } else if (perm && perm !== "") {
            result = perm;
        }

        return result;
    };

    getPlaceholder = () => {
        let quality = this.currentVisible("normal", this.props.item.tempQuality, this.props.item.quality);
        let strangePrefix = (this.props.item.displayTempStrangePrefix ? this.props.item.tempStrangePrefix : this.props.item.strangePrefix) || '';

        return nameDescriptionHelper.getPlaceholder(quality, strangePrefix, this.props.item.translatedName);
    };

    getDescriptionPlaceholderClass = () => {
        let cssClass = this.props.item.description === "" ? "placeholder" : "";
        return `color-grey item-details-inline-textarea item-details-edit-description mb-1 ${cssClass}`
    };

    getDisabledClass = () => {
        return this.props.isLoading ? 'item-details__textarea--disabled' : '';
    }

    addQuote = (text, type) => {
        if (text)
            this.handleTextChange(`"${text}"`, type);
    }

    removeQuote = (text, type) => {
        if (text && text[0] === '"' && text[text.length - 1] === '"') {
            const newValue = text.substring(1, text.length - 1);
            this.handleTextChange(newValue, type);
        }
    }

    render() {
        if (!this.props.item)
            return null;

        let unusualEffectUrl = this.props.item.displayTempUnusualEffect ? this.props.item.tempUnusualEffectUrl : this.props.item.unusualEffectUrl;
        const isMobile = breakpoints.isLessThan750();
        const width = this.props.isReordering ? {
            width: 'calc(100vw - 60px)'
        } : {};

        return (
            <div className="row"
                 style={{display: "flex", alignItems: "center", justifyContent: "center", flexWrap: 'nowrap'}}>
                {
                    this.props.isReordering &&
                    (<div className="post__drag-n-drop-icon">
                        <i className="fas fa-grip-vertical"></i>
                    </div>)
                }

                <div
                    className={"item-details-edit-container " + QualityHelper.sanitizeQuality(this.currentVisibleQuality())}
                    style={width}
                >
                    <div
                        className={"item-details-image-container centered " + QualityHelper.sanitizeQuality(this.currentVisibleQuality())}>
                        <div className="full-width">
                            <img
                                className="full-width"
                                style={{
                                    backgroundImage: unusualEffectUrl ? `url(${unusualEffectUrl})` : 'none',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100%'
                                }}
                                src={this.props.item.imageUrlLarge}
                                alt={this.props.item.translatedName}
                            />
                        </div>
                    </div>
                    <div className="item-details-edit-text">
                        {!this.props.isReordering ?
                            (<>
                                <textarea
                                    disabled={this.props.isLoading}
                                    className={`item-details-inline-textarea item-details-edit-name ${QualityHelper.sanitizeQuality(this.currentVisibleQuality())} ${this.getDisabledClass()}`}
                                    maxLength={40}
                                    value={this.props.item.name}
                                    onBlur={() => this.addQuote(this.props.item.name, 'name')}
                                    onFocus={() => this.removeQuote(this.props.item.name, 'name')}
                                    onChange={(event) => this.handleTextChange(event.target.value, "name")}
                                    placeholder={this.getPlaceholder()}
                                />
                            </>) :
                            (<div
                                className={"item-details-inline-textarea item-details-edit-name mb-1 " + QualityHelper.sanitizeQuality(this.currentVisibleQuality())}>
                                {!this.props.item.name || this.props.item.name === "" ? this.getPlaceholder() : this.props.item.name}
                            </div>)
                        }
                        {
                            !this.props.isReordering ?
                                (
                                    <textarea
                                        disabled={this.props.isLoading}
                                        className={`item-details-inline-textarea item-details-edit-description ${this.getDisabledClass()}`}
                                        maxLength={80} value={this.props.item.description}
                                        onBlur={() => this.addQuote(this.props.item.description, 'description')}
                                        onFocus={() => this.removeQuote(this.props.item.description, 'description')}
                                        onChange={(event) => this.handleTextChange(event.target.value, "description")}
                                        placeholder="Description"
                                    />) :
                                (<div
                                    className={this.getDescriptionPlaceholderClass()}>{!this.props.item.description || this.props.item.description === "" ? "Description" : this.props.item.description}</div>)
                        }
                    </div>
                </div>

                {
                    !this.props.isReordering && !isMobile &&
                    <div
                        className={`item-edit-icon ${constants.itemEditActions.expand} ${this.props.displayState ? 'item-edit-icon--rotated-left' : ''}`}
                        onClick={this.props.toggleDisplayState}
                    />
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(null, mapDispatchToProps)(ItemDetailsEdit);
