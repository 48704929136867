import React, {useState} from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import PostHomePageView from "../../atoms/postHomePageView";
import LoginModal from "../../molecules/modals/loginModal";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";

export const PostsInfiniteScroll = (props) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModalState = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <InfiniteScroll
                next={props.fetchNextPosts}
                hasMore={props.hasMore}
                loader={
                    <div className='homepage__loading-container'>
                        <PostSkeleton />
                    </div>
                }
                endMessage={
                    <div className='homepage__bottom-text'>
                        <div>{props.endOfPostsMessage}</div>
                    </div>
                }
                dataLength={props.posts.length}
            >
                {
                    props.posts.map((post, index) =>
                        <PostHomePageView
                            key={post.id}
                            post={post}
                            postScore={post.score}
                            postScoreScore={post.score.score} //This is strictly to force the child to update
                            updateScore={props.updateLocalPostScore}
                            toggleLoginModal={toggleModalState}
                            me={props.me}
                            goToCreatePage={props.goToCreatePage}
                            index={index + 1}
                            displayCount={props.displayCount}
                        />)
                }
            </InfiniteScroll>
            <LoginModal
                modalIsOpen={showModal}
                message='You can vote on posts and leave comments to help everyone find the best content by logging in.'
                onClose={toggleModalState}
            />
        </>
    )
}

PostsInfiniteScroll.defaultProps = {
    posts: [],
    endOfPostsMessage: '',
    updateLocalPostScore: () => {},
    fetchNextPosts: () => {},
    hasMore: true,
    me: {},
    goToCreatePage: false,
    displayCount: false
}
