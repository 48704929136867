import React, {Component} from "react";
import {ToastContainer} from "react-toastify";
import {Slide} from 'react-toastify';

import "react-toastify/dist/ReactToastify.css";
import './toastContainer.scss'

class ToastContainerWrapper extends Component {
    render() {
        return (
            <ToastContainer
                toastClassName='toast'
                progressClassName='toast__progress'
                transition={Slide}
                position="bottom-right"
                autoClose={3500}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnVisibilityChange={false}
                pauseOn
                draggable={false}
                pauseOnHove={true}
                pauseOnFocusLoss={false}

            />
        )
    }
}

export default ToastContainerWrapper;
