import React from 'react';
import WithTopMenuTemplate from "../../templates/withTopMenuTemplate";
import './policy.scss'
import {Link} from "react-router-dom";
import {constants} from "../../../utilities/constants";

export const PolicyPage = (props) => {

    const renderPolicyLink = (text, to) => {
        return (
            <Link className='policy__button' to={to}>
                {text}
            </Link>
        )
    }

    const render = () => {
        return (
            <WithTopMenuTemplate>
                <div className='policy__layout'>
                    {renderPolicyLink('Cookie Policy', constants.pagePaths.policyCookie)}
                    {renderPolicyLink('Terms and Conditions', constants.pagePaths.policyTermsAndConditions)}
                    {renderPolicyLink('Privacy Policy', constants.pagePaths.policyPrivacy)}
                </div>
            </WithTopMenuTemplate>)
    }
    return render();
}
