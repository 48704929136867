import React, {Component} from "react";
import {connect} from "react-redux";
import * as Constants from "../../../utilities/constants";
import ItemQualitySelectButton from "../../atoms/buttons/itemEdit/itemQualitySelectButton";
import {breakpoints} from "../../../utilities/breakpoints";

class ItemQualitiesSection extends Component {

    static defaultProps = {
        selectedAction: "",
        allQualities: [],
        item: {},
        isLoading: false
    }

    render() {
        if (this.props.selectedAction !== Constants.itemEditActions.qualities)
            return null;

        const isMobile = breakpoints.isLessThan750();

        return (
            <div className={`${isMobile ? '' : 'mt-3'} animated fade-in-move-down row row-item row-section`}>
                {
                    this.props.allQualities.map((quality) =>
                        <ItemQualitySelectButton
                            key={quality.id}
                            quality={quality}
                            item={this.props.item}
                            isLoading={this.props.isLoading}
                        />
                    )
                }
            </div>
        )
    }
}

const
    mapStateToProps = (state) => {
        return {
            allQualities: state.general.itemQualities
        };
    };

export default connect(mapStateToProps,

    null
)(
    ItemQualitiesSection
)
;
