import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../../atoms/loading-spinner/loadingSpinner";
import './itemInfiniteScroll.scss'
import {breakpoints} from "../../../../utilities/breakpoints";

export const ItemLeaderboardInfiniteScroll = (props) => {

    const render = () => {
        const isMobile = breakpoints.isLessThan750();
        return (
            <InfiniteScroll
                next={props.fetchNext}
                hasMore={props.hasMore}
                loader={
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <LoadingSpinner size={LoadingSpinnerSizes.medium} show={true}/>
                    </div>
                }
                dataLength={props.items.length}
                endMessage={<div style={{textAlign: 'center', marginTop: '10px'}}>{props.endMessage}</div>}
                className='item-infinite-scroll__container'
            >
                <table className='item-infinite-scroll__table' cellSpacing={0}>
                    <thead>
                    <tr>
                        <th className='item-infinite-scroll__table-header' style={{minWidth: '30px'}}>{isMobile ? '#' : 'Rank'}</th>
                        <th className='item-infinite-scroll__table-header'>{isMobile ? 'Name' : 'Item Name'}</th>
                        <th className='item-infinite-scroll__table-header' style={{minWidth: '50px'}}>{isMobile ? 'Used' : 'Times Used'}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {props.items.map((item, index) =>
                        <tr key={index} className='item-infinite-scroll__table-row'>
                            <td>{index + 1}</td>
                            <td className='item-infinite-scroll__td--centered'>
                                <img src={item.imageUrl} alt={item.name} className='item-infinite-scroll__image'/>
                                {item.name}
                            </td>
                            <td>
                                {item.timesUsed}
                            </td>
                        </tr>)}
                    </tbody>


                </table>

            </InfiniteScroll>
        )
    }

    return render();
}

ItemLeaderboardInfiniteScroll.defaultProps = {
    items: [],
    fetchNext: () => {
    },
    hasMore: true,
    endMessage: '',
    leaderboardType: ''
}
