import React, { Component } from "react";
import {LoadingSpinner, LoadingSpinnerSizes} from "../../loading-spinner/loadingSpinner";

class PostButton extends Component {

	static defaultProps = {
		onClick: () => {},
		isLoading: false,
		isDisabled: false
	}

	render() {
		const classNames = `menu-button ${(this.props.isLoading || this.props.isDisabled) && 'menu-button--disabled'}`
		return (
			<div className={classNames} onClick={!this.props.isLoading && !this.props.isDisabled ? this.props.onClick : undefined}>
				{
					this.props.isLoading ?
						<LoadingSpinner size={LoadingSpinnerSizes.medium} show={true} /> :
						this.props.children
				}
			</div>
		)
	}
}


export default PostButton;
