import React from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import {PostSkeleton} from "../../atoms/postSkeleton/postSkeleton";
import {UserCard} from "../../pages/profile/components/users/userCard";

export const UsersInfiniteScroll = (props) => {
    return (
        <>
            <InfiniteScroll
                next={props.fetchNext}
                hasMore={props.hasMore}
                loader={
                    <PostSkeleton/>
                }
                endMessage={
                    <div className='homepage__bottom-text'>
                        <div>{props.endMessage}</div>
                    </div>
                }
                dataLength={props.profiles.length}
            >
                {
                    props.profiles.map((profile) =>
                        <div style={{marginBottom: "24px"}} key={profile.id}>
                            <UserCard
                                userProfile={profile}
                                linkToProfile={true}
                            />
                        </div>)
                }
            </InfiniteScroll>
        </>
    )
}

UsersInfiniteScroll.defaultProps = {
    profiles: [],
    endMessage: '',
    updateLocalPostScore: () => {
    },
    fetchNext: () => {
    },
    hasMore: true
}
