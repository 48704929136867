import React from "react";
import {Link} from "react-router-dom";

const NavbarButton = React.forwardRef((props, ref) => {
    const inSideMenuClass = props.inSideMenu ? 'menu-button--side-menu' : '';

    if (!props.linkUrl) {
        return (
            <div ref={ref} className={`menu-button ${inSideMenuClass}`} onClick={props.buttonClicked}>{props.children}</div>
        )
    }

    return (
        <Link ref={ref} to={props.linkUrl} className={`menu-button ${inSideMenuClass}`} onClick={props.buttonClicked}>{props.children}</Link>
    )
})

NavbarButton.defaultProps = {
    children: [],
    linkUrl: "",
    buttonClicked: () => {
    },
    inSideMenu: false
}

export default NavbarButton;
