import React from 'react'

import './cardInfo.scss'
import {QualityHelper} from "../../../utilities/qualityHelper";

export const CardInfo = (props) => {
    return (
        <div className={`card-info ${QualityHelper.sanitizeQuality(props.quality)} ${props.className}`}>
            <div className={"card-info__content color-grey"}>
                {props.children}
            </div>
        </div>
    )
}

CardInfo.defaultProps = {
    quality: ''
}
