import React from "react";

import './score.scss';
import {api, ENDPOINTS} from "../../../api/api";
import {toast} from "react-toastify";
import {maths} from "../../../utilities/maths";

class Score extends React.PureComponent {
    static defaultProps = {
        score: 0,
        ratio: "",
        userScore: 0,
        downvotes: 0,
        upvotes: 0,
        center: false,
        toggleLoginModal: () => {
        },
        me: null,
        updateScore: () => {
        },
        postId: 0,
        displayHorizontal: false
    };

    getScoreColor = () => {
        if (this.props.score > 0)
            return "color-green";
        else if (this.props.score < 0)
            return "color-red";
        else
            return "color-grey";
    };

    getArrowHighlighted = (arrowThreshold, arrow) => {
        if (arrowThreshold === 1)
            return this.props.userScore > 0 ? `${arrow}--highlighted` : "";

        if (arrowThreshold === -1)
            return this.props.userScore < 0 ? `${arrow}--highlighted` : "";
    };

    handleArrowClick = (newScore) => {
        if (!this.props.me || !this.props.me.isLoggedIn) {
            this.props.toggleLoginModal();
            return;
        }

        if ((this.props.userScore === 1 && newScore === 1) ||
            (this.props.userScore === -1 && newScore === -1))
            newScore = 0;

        const newScoreObject = this.updateLocalScore(newScore);
        this.upsertPostScore(newScore);

        this.props.updateScore(newScoreObject, this.props.postId);
    }

    updateLocalScore = (newScore) => {
        let score = {
            userScore: this.props.userScore,
            ratio: this.props.ratio,
            downvotes: this.props.downvotes,
            upvotes: this.props.upvotes,
            score: this.props.score
        }

        if (score.userScore === -1 && newScore === 0) { //Downvoted | Neutral
            score.downvotes -= 1;
            score.userScore = 0;
            newScore = 1;
        } else if (score.userScore === 1 && newScore === 0) { //Upvoted | Neutral
            score.upvotes -= 1;
            score.userScore = 0;
            newScore = -1;
        } else if (score.userScore === -1 && newScore === 1) { //Downvoted | Upvoted
            score.downvotes -= 1;
            score.upvotes += 1;
            score.userScore = 1;
            newScore = 2;
        } else if (score.userScore === 1 && newScore === -1) { //Upvoted | Downvoted
            score.downvotes += 1;
            score.upvotes -= 1;
            score.userScore = -1;
            newScore = -2;
        } else if (score.userScore === 0 && newScore === -1) { //Neutral | Downvoted
            score.userScore = newScore;
            score.downvotes += 1;
        } else if (score.userScore === 0 && newScore === 1) { //Neutral | Upvoted
            score.userScore = newScore;
            score.upvotes += 1;
        }

        score.ratio = this.calculateRatio(score.upvotes, score.downvotes);
        score.score += newScore;

        return score;
    }

    calculateRatio = (a, b) => {
        a = Math.abs(a);
        b = Math.abs(b);

        if (a === 0)
            return `0:${b}`;

        if (b === 0)
            return `${a}:0`;

        let gcd = maths.getGreatestCommonDenominator(a, b);
        return `${a / gcd}:${b / gcd}`;
    }

    upsertPostScore = (newScore) => {
        const url = ENDPOINTS.posts.scores.put.replace("{postId}", this.props.postId).replace("{score}", newScore);

        api.put(url)
            .then(resp => {
                // Removing this as it's really annoying. Users will be informed if it fails, so that should be good enough
                // if (newScore === 0)
                //     toast.success(`Successfully removed the vote`)
                // else {
                //     let verb = newScore > 0 ? 'critted' : 'missed';
                //     toast.success(`Successfully ${verb} the post`)
                // }
            })
            .catch(error => {
                if (error.response.status === 429)
                    toast.error(`You're voting on posts too fast. Please try again later.`)
                else {
                    let verb = newScore > 0 ? 'crit' : 'miss';
                    toast.error(`Failed to ${verb} the post`)
                }

                console.error("Error getting items: ", error);
            });
    }

    render() {
        const className = `score-area ${this.props.center ? 'score-area--centered' : ''} ${this.props.displayHorizontal ? 'score-area--horizontal' : ''}`
        return (
            <div className={className}>
                <div title="Crit" className={`arrow arrow__up ${this.getArrowHighlighted(1, 'arrow__up')}`} onClick={() => this.handleArrowClick(1)}/>
                <div className={this.getScoreColor()}>{this.props.score}</div>
                <div className={this.getScoreColor()}>{this.props.ratio}</div>
                <div title="Miss" className={`arrow arrow__down ${this.getArrowHighlighted(-1, 'arrow__down')}`} onClick={() => this.handleArrowClick(-1)}/>
            </div>
        )
    }
}

export default Score;
